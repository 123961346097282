import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';

declare var swal: any;
@Component({
  selector: 'app-corporate-userlist',
  templateUrl: './corporate-userlist.component.html',
  styleUrls: ['./corporate-userlist.component.scss']
})
export class CorporateUserlistComponent implements OnInit {

  corporateUserList:any;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.getlist_corporateListApi();
  }

  async getlist_corporateListApi() {
    var getapi = "/corporate_user_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.corporateUserList = res.result;
      }
    });

  }


}
