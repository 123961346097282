import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-corporate-portal-list',
  templateUrl: './corporate-portal-list.component.html',
  styleUrls: ['./corporate-portal-list.component.scss']
})
export class CorporatePortalListComponent implements OnInit {
  corporateEdit_Url = "/corporate_edit";
  corporateAdd_Url = "/corporate_add";
  corporateDeleteId:any;
  corporateAllList:any;
  showLoaderDiv = false;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.getlist_corporateListApi();
  }
  corporatePortalEdit(items:any){
    this.ApiService.corporate_EditId= items.id;
    localStorage.setItem("corporateEditIds",items.id)
    this.router.navigate([this.corporateEdit_Url]);
  }

  corporateDelete(item:any){
    this.corporateDeleteId = item.id
  } 

  corporatePortalAdd(){
    this.router.navigate([this.corporateAdd_Url]);
  }

  async getlist_corporateListApi() {

    this.showLoaderDiv = true;

    var getapi = "/list_corporate_admin";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.corporateAllList = res.result;
        this.showLoaderDiv = false;
      }
    });

  }

 async corporateDeleteApi() {
  var deleteApi = "/delete_corporate_admin/"+ this.corporateDeleteId;
  (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
    if (res.status === 200) {

      swal({
        title: "Success!",
        text: res.message,
        type: "success"
      }, function () {
        alert('yo');
      });

this.getlist_corporateListApi();

    }
  });
}







}
