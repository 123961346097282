<div class="padd-0-25 marg-t-70">
  <nav aria-label="breadcrumb ">
    <ol class="breadcrumb fsize27 padd_0 ">
      <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
          [routerLink]="['/camp-Edit-Expedition']">Back</a>
      </li>
      <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Expedition Edit Camp </li>
    </ol>
  </nav>
  <div class="marg-t-40">
    <div class="row marg-0">
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <div style="float: right;">
          <span style="margin-bottom: 30px;"> <button data-toggle="modal" data-target="#addExerciseModel"
              class="submit_btn_clr">Add Question</button></span>
        </div>
        <div class="marg-t-40">
          <form [formGroup]="editRegForm">
            <div class="form-group marg-t-20">
              <label class="fsize16 clr_black font_bold_gilroy">Module *</label>
              <select disabled [(ngModel)]="module" [ngModelOptions]="{standalone: true}"
                class=" form-control input_bdr_clr " (ngModelChange)="moduleItem($event)">
                <option [value]="[item.title,item.id ]" *ngFor="let item of moduleTitleList ">
                  {{item.title}}</option>
              </select>
              <!--
                <div *ngIf="editsubmitted && editRegForm.get('modules').errors" class="alert alert-danger">
                    <div *ngIf="editRegForm.get('modules').errors.required">
                        Module is
                        required</div>
                </div> -->
            </div>
            <div class="marg-t-20">
              <span class="fsize16 clr_black font_bold_gilroy">Camp * : </span>
              <select disabled [(ngModel)]="campName" [ngModelOptions]="{standalone: true}"
                class=" form-control input_bdr_clr " (ngModelChange)="camplistTitleFun($event)">
                <option [value]="[item.camp_title,item.id] " *ngFor="let item of campSiteList">
                  {{item.camp_name}} </option>
              </select>
              <!-- <div *ngIf="editsubmitted && editRegForm.get('camps').errors" class="alert alert-danger">
                <div *ngIf="editRegForm.get('camps').errors.required">
                    Module is
                    required</div>
                </div> -->
            </div>
            <div class="form-group marg-t-20">
              <label class="fsize16 clr_black font_bold_gilroy">Camp Title *</label>
              <input type="text" id="questions" class="form-control input_bdr_clr " placeholder="Camp Title"
                [(ngModel)]="campAllTitle " formControlName="camptitle" />
              <div *ngIf="editsubmitted && editRegForm.get('camptitle').errors" class="alert alert-danger">
                <div *ngIf="editRegForm.get('camptitle').errors.required">
                  Camp Title is
                  required
                </div>
              </div>
            </div>
            <div class="form-group marg-t-20">
              <label class="fsize16 clr_black font_bold_gilroy">Description </label>
              <!-- <textarea class="form-control input_bdr_clr "  style="min-height: 100px;"
                [(ngModel)]="descriptions " [ngModelOptions]="{standalone: true}"
                 >
                </textarea> -->
              <ckeditor name="myckeditor" [(ngModel)]="descriptions" formControlName="descriptions" debounce="500">
              </ckeditor>
              <div *ngIf="editsubmitted && editRegForm.get('descriptions').errors" class="alert alert-danger">
                <div *ngIf="editRegForm.get('descriptions').errors.required">
                  Description is
                  required
                </div>
              </div>
            </div>
            <div class="form-group marg-t-20">
              <label class="fsize16 clr_black font_bold_gilroy">No.of questions *</label>
              <input type="text" id="questions" class="form-control input_bdr_clr " placeholder="No.of question"
                maxlength="150" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                [(ngModel)]="noOfquestions" formControlName="noOfquestion" />
              <div *ngIf="editsubmitted && editRegForm.get('noOfquestion').errors" class="alert alert-danger">
                <div *ngIf="editRegForm.get('noOfquestion').errors.required">
                  No.of questions is
                  required
                </div>
              </div>
            </div>
            <div class="form-group marg-t-40">
              <button type="submit" class="btn  submit_btn_clr" (click)="updateCampData()">Submit</button>
              <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                (click)="cancelBtn_expedtion()">Cancel</button>
            </div>
          </form>
        </div>
        <div>
          <!-- Button trigger modal -->
          <!-- Modal -->
          <div class="modal fade" data-toggle="modal" id="addExerciseModel" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="" style="padding: 10px;">
                  <span class="modal-title font_size22 clr_black font_bold_gilroy">Add
                    Question</span>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form [formGroup]="registerForm" name="form2" id="form-id">
                    <div>
                      <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Question *
                        </label>
                        <input type="text" id="examples1" [(ngModel)]="questions" formControlName="question"
                          class="form-control input_bdr_clr " />
                        <div *ngIf="submitted && registerForm.get('question').errors" class="alert alert-danger">
                          <div *ngIf="registerForm.get('question').errors.required">
                            Question is
                            required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Question Order
                          *</label>
                        <input type="text" id="questions1" [(ngModel)]="questionOrder"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          formControlName="questionorder" class="form-control input_bdr_clr " />
                        <div *ngIf="submitted && registerForm.get('questionorder').errors" class="alert alert-danger">
                          <div *ngIf="registerForm.get('questionorder').errors.required">
                            Question Order is
                            required
                          </div>
                        </div>
                      </div>
                      <div class="form-group marg-t-20" *ngIf="options_question == 0">
                        <label class="fsize16 clr_black font_bold_gilroy">Answer Placeholder
                        </label>
                        <!-- <input type="text" id="" class="form-control input_bdr_clr "
                          [(ngModel)]="answerPlaceholder" [ngModelOptions]="{standalone: true}" /> -->
                        <ckeditor name="myckeditor" [(ngModel)]="answerPlaceholder" formControlName="answerplaceholders"
                          debounce="500" (change)="onChangeProp($event)">
                        </ckeditor>
                        <div *ngIf="submitted && registerForm.get('answerplaceholders').errors"
                          class="alert alert-danger">
                          <div *ngIf="registerForm.get('answerplaceholders').errors.required">
                            Answer Placeholder is
                            required
                          </div>
                        </div>
                      </div>
                      <!-- <div class="form-group marg-t-20" *ngIf="options_question == 1">
                        <label class="fsize16 clr_black font_bold_gilroy">Answer Placeholders
                        </label>

                        <div class="">
                          <div  *ngFor="let item of multiPlaceholderCamp13">

                            <input type="text" name="" id="" class="multi-placeholder-camp13 input_bdr_clr w-100 mb-2"
                              [value]="[item.name]"
                              (change)="changeMultiPlaceholderCamp13($event,item)" />
                              <button (click)="removemultiPlaceholderCamp13(item)">X</button>
                          </div>
                        </div>
                        <div>
                          <button style="margin-right:20px;" class="w-100 border-0 inpt-outline model_SaveBtn"
                            (click)="addmultiPlaceholderCamp13()">Ad</button>
                        </div>
                      </div> -->
                      <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Show Beliefs Button * :
                        </span>
                        <span>
                          <label for="vehicle1"> Yes</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" value="1"
                            [(ngModel)]="view_Beliefs" [ngModelOptions]="{standalone: true}">
                        </span>
                        <span>
                          <label class="marg-l-8" for="vehicle1">No</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" value="0"
                            [(ngModel)]="view_Beliefs" [ngModelOptions]="{standalone: true}">
                        </span>
                      </div>
                      <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Show feeling wheels
                          Button * :
                        </span>
                        <span>
                          <label for="vehicle1"> Yes</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="viewBelief1" value="1"
                            [(ngModel)]="feeling_wheel" [ngModelOptions]="{standalone: true}">
                        </span>
                        <span>
                          <label class="marg-l-8" for="vehicle1">No</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="viewBelief1" value="0"
                            [(ngModel)]="feeling_wheel" [ngModelOptions]="{standalone: true}">
                        </span>
                      </div>
                      <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Show cognitivebias
                          Button * :
                        </span>
                        <span>
                          <label for="vehicle1"> Yes</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="viewBelief2" value="1"
                            [(ngModel)]="cognitive_bias" [ngModelOptions]="{standalone: true}">
                        </span>
                        <span>
                          <label class="marg-l-8" for="vehicle1">No</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="viewBelief2" value="0"
                            [(ngModel)]="cognitive_bias" [ngModelOptions]="{standalone: true}">
                        </span>
                      </div>
                      <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Collect Response * :
                        </span>
                        <span>
                          <label for="vehicle1"> Yes</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="collectResponses" value="1"
                            [(ngModel)]="collectResponse" [ngModelOptions]="{standalone: true}">
                        </span>
                        <span>
                          <label class="marg-l-8" for="vehicle1">No</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="collectResponses" value="0"
                            [(ngModel)]="collectResponse" [ngModelOptions]="{standalone: true}">
                        </span>
                      </div>
                      <!-- 1111 -->
                      <div class="marg-t-20" *ngIf="expeditionEditAllId == 6 && expeditionsCampid == 13">
                        <span class="fsize16 clr_black font_bold_gilroy">Option * :
                        </span>
                        <span>
                          <label for="vehicle1"> Yes</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="options_question" value="1"
                            [(ngModel)]="options_question" [ngModelOptions]="{standalone: true}">
                        </span>
                        <span>
                          <label class="marg-l-8" for="vehicle1">No</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="options_question" value="0"
                            [(ngModel)]="options_question" [ngModelOptions]="{standalone: true}">
                        </span>
                      </div>



<!-- new work -->
<div class="form-group marg-t-20" *ngIf="options_question == 1">
  <label class="fsize16 clr_black font_bold_gilroy">Add Options * :
  </label>
 <span class="fsize16 clr_black font_bold_gilroy">Add Options * :</span>
 <span> <img src="assets/add_circle_FILL1_wght300_GRAD0_opsz24.svg" alt="" class="h-24 marg-l-8" (click)="addmultiPlaceholderCamp13()"></span>

  <div class="">
    <div  *ngFor="let item of multiPlaceholderCamp13">

<div class="row">
  <div class="col-8">
    <div>
      <input type="text" name="" id="" class="multi-placeholder-camp13 input_bdr_clr  mb-2 w-100"
  [value]="[item.name]"
  (change)="changeMultiPlaceholderCamp13($event,item)" />
    </div>
  </div>
  <div class="col-4 p-0">
<div class="mt-2">
  <!-- <button  class=" border-0 inpt-outline plus-icon mx-2 "
    (click)="addmultiPlaceholderCamp13()">+</button> -->
    <img src="assets/close_FILL1_wght300_GRAD0_opsz24.svg" alt="" (click)="removemultiPlaceholderCamp13(item)">
    <!-- <button (click)="removemultiPlaceholderCamp13(item)" class="minus-icon">X</button> -->

</div>
  </div>

</div>

    </div>
  </div>
  <!-- <div>
    <button  class=" border-0 inpt-outline plus-icon "
      (click)="addmultiPlaceholderCamp13()">+</button>
  </div> -->
  <!-- <div>
    <button style="margin-right:20px;" class="w-100 border-0 inpt-outline model_SaveBtn"
      (click)="addmultiPlaceholderCamp13()">Add</button>
  </div> -->
</div>

<!-- new work -->

                      <!-- 1111 -->
                      <div class="marg-t-20" *ngIf="expeditionsCampid >1">
                        <span class="fsize16 clr_black font_bold_gilroy">Display Response From
                          Previous Camp site * : </span>
                        <span>
                          <label for="vehicle1"> Yes</label>
                          <input class="marg-l-8" type="radio" id="vehicle1" name="vehicle1"
                            (change)="responsePreviousCampSiteYes($event)" value="1" [(ngModel)]="previousCampsite"
                            [ngModelOptions]="{standalone: true}">
                        </span>
                        <span>
                          <label class="marg-l-8" for="vehicle1">No</label>
                          <input class="marg-l-8" type="radio" id="vehicle1"
                            (change)="responsePreviousCampSiteYes($event)" name="vehicle1" value="0"
                            [(ngModel)]="previousCampsite" [ngModelOptions]="{standalone: true}">
                        </span>
                      </div>
                      <div *ngIf="previousCampSite_Yes_Div">
                        <div class="marg-t-20">
                          <span class="fsize16 clr_black font_bold_gilroy">Camp * : </span>
                          <select [(ngModel)]="campName" [ngModelOptions]="{standalone: true}"
                            class=" form-control input_bdr_clr " (change)="camplistTitleFun($event)">
                            <option [value]="[item.id]" *ngFor="let item of campFilterId">
                              {{item.camp_name}} </option>
                          </select>
                        </div>
                        <div class="form-group marg-t-20">
                          <label class="fsize16 clr_black font_bold_gilroy">Camp site Question
                            *</label>
                          <select [(ngModel)]="previouscampSiteQuestion" [ngModelOptions]="{standalone: true}"
                            class=" form-control input_bdr_clr" (ngModelChange)="camplistQuestion($event)">
                            <option [value]="[item.id,item.expedition_id,item.question ] "
                              *ngFor="let item of previousCampsiteList">
                              {{item.question}} </option>
                          </select>
                        </div>
                      </div>
                      <!-- -----------------Previous Camp Two------- -->
                      <!-- *ngIf = "expeditionsCampid >1" -->
                      <div *ngIf="previousCampSite_Yes_Div">
                        <div *ngIf="expeditionsCampid ==7 || expeditionsCampid ==8 || expeditionsCampid ==9">
                          <div class="marg-t-20">
                            <span class="fsize16 clr_black font_bold_gilroy">Camp * :
                            </span>
                            <select [(ngModel)]="campNameOne" [ngModelOptions]="{standalone: true}"
                              class=" form-control input_bdr_clr" (change)="camplistTitleFunTwo($event)">
                              <option [value]="[item.id]" *ngFor="let item of campFilterId">
                                {{item.camp_name}} </option>
                            </select>
                          </div>
                          <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">Camp site
                              Question
                              *</label>
                            <select [(ngModel)]="previouscampSiteQuestionTwo" [ngModelOptions]="{standalone: true}"
                              class=" form-control input_bdr_clr" (ngModelChange)="camplistQuestionTwo($event)">
                              <option [value]="[item.id,item.expedition_id,item.question] "
                                *ngFor="let item of previousCampsiteList2">
                                {{item.question}} </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- ---------------------------------------------------------------------------------------------- -->
                      <!-- <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Status * : </span>
                        <span>
                            <label for="vehicle1"> Active</label>
                            <input class="marg-l-8" type="radio" id="vehicle1" name="status"
                                value="1" [(ngModel)]="status"
                                [ngModelOptions]="{standalone: true}">
                        </span>
                        <span>
                            <label class="marg-l-8" for="vehicle1">In active</label>
                            <input class="marg-l-8" type="radio" id="vehicle1" name="status"
                                value="0" [(ngModel)]="status"
                                [ngModelOptions]="{standalone: true}">
                        </span>
                        </div> -->
                    </div>
                  </form>
                  <div class="marg-t-20">
                    <button type="button" class="btn  model_SaveBtn" id="addExerciseModels1"
                      (click)="expeditionQuestionSave()">Save</button>
                    <button type="button" class="btn marg-l-16 model_CancelBtn">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
      </div>
    </div>



    <!-- expediation question issues -->
    <div *ngIf="showExpeditionQuestionTable">
      <div class="marg-t-70 fsize27 font_bold_gilroy" *ngIf="getExpeditionQuestion_List.length > 0">
        Expedition Question
      </div>
      <div class="marg-t-30">
        <div class="" style=" background-color: #fff; border-radius: 8px;">
          <table class="row-border hover table w-100" datatable *ngIf="getExpeditionQuestion_List.length > 0">
            <thead>
              <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                <th>S.No </th>
                <th>Question </th>
                <th>QuestionOrder</th>
                <th>Answer Placeholder</th>
                <th style="width: 150px; text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr class=" font_medium_gilroy" *ngFor="let item of getExpeditionQuestion_List ; let i=index">
                <td>{{i+1}}</td>
                <td>{{item.question}}</td>
                <td>{{item.question_order}}</td>
                <td [innerHTML]="item.answer_placeholder"></td>
                <td style="text-align:center;">
                  <!-- <span class="cursor_pointer"><img height="22px" width="22px" class=""
                    src="assets/View_icon.png" alt=""
                    (click)="questionExpeditionView(item)"></span> -->
                  <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px" class=""
                      src="assets/Edit_icon.png" alt="" (click)="questionExpeditionEdit(item)"></span>
                  <span class="marg-l-10 ">
                    <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                      data-target="#exercise_delete"> <img height="22px" width="22px" class=""
                        src="assets/Delete_icon.png" alt="" (click)="questionDeleteItem(item)">
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <table class="w-100">
            <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                <th>S.No</th>

                <th>Camp </th>
                <th>No.Of Questions</th>


                <th style="width: 150px;">Action</th>
            </tr>
            <tr class=" font_medium_gilroy" *ngFor="let item of getExpeditionQuestion_List ; let i=index">
                <td>{{i+1}}</td>

                <td>{{item.question}}</td>
                <td>{{item.question_order}}</td>


                <td> <span class="cursor_pointer"><img height="22px" width="22px" class=""
                            src="assets/View_icon.png" alt="" (click)="questionExpeditionView(item)"></span>
                    <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px" class=""
                            src="assets/Edit_icon.png" alt="" (click)="questionExpeditionEdit(item)"></span>

                    <span class="marg-l-10 ">

                        <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                            data-target="#exercise_delete"> <img height="22px" width="22px" class=""
                                src="assets/Delete_icon.png" alt="" (click)="questionDeleteItem(item)">
                        </button>
                    </span>
                </td>
            </tr>
            </table> -->
          <div class="modal fade" id="exercise_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                  <div class="fsize18 marg-t-16"> Are you sure you want to delete this Question?
                  </div>
                </div>
                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                  <button type="button" class="btn_submit_cancel bgr_clr_red cursor_pointer"
                    (click)="expeditionQuestionDeleteApi()" data-dismiss="modal">Delete</button>
                  <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue cursor_pointer"
                    data-dismiss="modal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- expedition question 3333333 -->
  </div>
</div>
