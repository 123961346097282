import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
// import { FooterComponent } from './footer/footer.component';
// import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { SidebarModule } from 'ng-sidebar';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { CoachComponent } from './coach/coach.component';
import { WorkoutTableComponent } from './workout-table/workout-table.component';
import { WorkoutEditTableComponent } from './workout-edit-table/workout-edit-table.component';
import { CoachEditPageComponent } from './coach-edit-page/coach-edit-page.component';
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';
import { UserTableComponent } from './user-table/user-table.component';
import { UserEditTableComponent } from './user-edit-table/user-edit-table.component';
import { UserViewPageComponent } from './user-view-page/user-view-page.component';
import { AppointmentViewPageComponent } from './appointment-view-page/appointment-view-page.component';
import { AppointmentEditPageComponent } from './appointment-edit-page/appointment-edit-page.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { HappyWorkoutEditComponent } from './happy-workout-edit/happy-workout-edit.component';
import { ContentSosEditComponent } from './content-sos-edit/content-sos-edit.component';
// import { LoginpageComponent } from './loginpage/loginpage.component';
import { TokenInterceptorInterceptor } from './token-interceptor.interceptor';
import { WorkoutAddComponent } from './workout-add/workout-add.component';
import { WorkoutViewpageComponent } from './workout-viewpage/workout-viewpage.component';
import { ContentSosAddComponent } from './content-sos-add/content-sos-add.component';
import { HappinessSosViewComponent } from './happiness-sos-view/happiness-sos-view.component';
import { HappinessExpeditionAddComponent } from './happiness-expedition-add/happiness-expedition-add.component';
import { HappinessExpeditionViewComponent } from './happiness-expedition-view/happiness-expedition-view.component';
import { HappinessExpeditionEditComponent } from './happiness-expedition-edit/happiness-expedition-edit.component';
// import { ExpeditionInnerTableAddComponent } from './expedition-inner-table-add/expedition-inner-table-add.component';
// import { ExpeditionInnerTableViewComponent } from './expedition-inner-table-view/expedition-inner-table-view.component';
// import { ExpeditionInnerTableEditComponent } from './expedition-inner-table-edit/expedition-inner-table-edit.component';
import { ForumManagementComponent } from './forum-management/forum-management.component';
import { ForumUserlistPageComponent } from './forum-userlist-page/forum-userlist-page.component';
import { ForumFeedlistComponent } from './forum-feedlist/forum-feedlist.component';
import { ForumAddFeedlistComponent } from './forum-add-feedlist/forum-add-feedlist.component';
import { CoachViewPageComponent } from './coach-view-page/coach-view-page.component';
import { SosFeelContentTableComponent } from './sos-feel-content-table/sos-feel-content-table.component';
import { HappinessExpenditionTableComponent } from './happiness-expendition-table/happiness-expendition-table.component';
import { HappinessWorkoutTableComponent } from './happiness-workout-table/happiness-workout-table.component';
import { QuestionWorkoutViewComponent } from './question-workout-view/question-workout-view.component';
import { QuestionWorkoutEditComponent } from './question-workout-edit/question-workout-edit.component';
import { ExerciseWorkoutEditComponent } from './exercise-workout-edit/exercise-workout-edit.component';
import { ExerciseWorkoutViewComponent } from './exercise-workout-view/exercise-workout-view.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { QuestionSosEditComponent } from './question-sos-edit/question-sos-edit.component';
import { ExerciseSosEditComponent } from './exercise-sos-edit/exercise-sos-edit.component';
import { QuestionSosViewComponent } from './question-sos-view/question-sos-view.component';
import { ExerciseSosViewComponent } from './exercise-sos-view/exercise-sos-view.component';
import { QuestionExpeditionEditComponent } from './question-expedition-edit/question-expedition-edit.component';
import { QuestionExpeditionViewComponent } from './question-expedition-view/question-expedition-view.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { CommunityTopicTableComponent } from './community-topic-table/community-topic-table.component';
import { CommunityTopicAddComponent } from './community-topic-add/community-topic-add.component';
import { CommunityTopicEditComponent } from './community-topic-edit/community-topic-edit.component';
import { CommunityTopicViewComponent } from './community-topic-view/community-topic-view.component';
import { CommunityFeedlistTableComponent } from './community-feedlist-table/community-feedlist-table.component';
import { CommunityUserlistTableComponent } from './community-userlist-table/community-userlist-table.component';
import { CampEditExpeditionComponent } from './camp-edit-expedition/camp-edit-expedition.component';
import { CommunityModerationTableComponent } from './community-moderation-table/community-moderation-table.component';
import { ExpeditionModuleAddComponent } from './expedition-module-add/expedition-module-add.component';
import { PaymentManagementComponent } from './payment-management/payment-management.component';
import { DatePipe } from '@angular/common';
import { CouponManagementComponent } from './coupon-management/coupon-management.component';
import { CouponAddComponent } from './coupon-add/coupon-add.component';
import { CouponEditComponent } from './coupon-edit/coupon-edit.component';
import { CouponViewComponent } from './coupon-view/coupon-view.component';
import { AdminApproveComponent } from './admin-approve/admin-approve.component';

import { DataTablesModule } from "angular-datatables";
import { PostModerationTableComponent } from './post-moderation-table/post-moderation-table.component';
import { StudentPortalComponent } from './student-portal/student-portal.component';
import { StudentPortalEditComponent } from './student-portal-edit/student-portal-edit.component';
import { StudentPortalViewComponent } from './student-portal-view/student-portal-view.component';
import { StudentPortalAddComponent } from './student-portal-add/student-portal-add.component';
import { CollegeListTableComponent } from './college-list-table/college-list-table.component';
import { ApproveCollegeComponent } from './approve-college/approve-college.component';
import { UpcommingAppointmentComponent } from './upcomming-appointment/upcomming-appointment.component';
// import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule  } from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
//import {MatSelectModule} from '@angular/material/select';
import {MatNativeDateModule} from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { MatInputModule,    } from '@angular/material/input';
import { BlogMasterComponent } from './blog-master/blog-master.component';
import { BlogMasterAddComponent } from './blog-master-add/blog-master-add.component';
import { BlogMasterEditComponent } from './blog-master-edit/blog-master-edit.component';
import { BlogMasterQuestioneditComponent } from './blog-master-questionedit/blog-master-questionedit.component';
import { ExercisesDatabankAddComponent } from './exercises-databank-add/exercises-databank-add.component';
import { ExercisesDatabankEditComponent } from './exercises-databank-edit/exercises-databank-edit.component';
import { ExercisesDatabankComponent } from './exercises-databank/exercises-databank.component';
import { ExercisesDatabankViewComponent } from './exercises-databank-view/exercises-databank-view.component';
import { NutritionDatabankAddComponent } from './nutrition-databank-add/nutrition-databank-add.component';
import { NutritionDatabankComponent } from './nutrition-databank/nutrition-databank.component';
import { NutritionDatabankEditComponent } from './nutrition-databank-edit/nutrition-databank-edit.component';
import { NutritionDatabankViewComponent } from './nutrition-databank-view/nutrition-databank-view.component';
import { BlogMasterViewComponent } from './blog-master-view/blog-master-view.component';
import { TidbitMasterComponent } from './tidbit-master/tidbit-master.component';
import { TidbitMasterAddComponent } from './tidbit-master-add/tidbit-master-add.component';
import { TidbitMasterEditComponent } from './tidbit-master-edit/tidbit-master-edit.component';
import { TidbitMasterViewComponent } from './tidbit-master-view/tidbit-master-view.component';
// import { AssestmentResultComponent } from './assestment-result/assestment-result.component';
// import { AssestmentAddresultComponent } from './assestment-addresult/assestment-addresult.component';
// import { AssessmentEditresultComponent } from './assessment-editresult/assessment-editresult.component';
// import { AssestmentViewresultComponent } from './assestment-viewresult/assestment-viewresult.component';
// import { ResultAssesmentComponent } from './result-assesment/result-assesment.component';
import { AssestmentresultComponent } from './assestmentresult/assestmentresult.component';
import { AssestmentaddresultComponent } from './assestmentaddresult/assestmentaddresult.component';
import { AssestmenteditresultComponent } from './assestmenteditresult/assestmenteditresult.component';
import { AssestmentviewresultComponent } from './assestmentviewresult/assestmentviewresult.component';
import { AssestmentQuestionlistComponent } from './assestment-questionlist/assestment-questionlist.component';
import { AssestmentQuestionaddComponent } from './assestment-questionadd/assestment-questionadd.component';
import { AssestmentQuestioneditComponent } from './assestment-questionedit/assestment-questionedit.component';
import { CorporatePortalAddComponent } from './corporate-portal-add/corporate-portal-add.component';
import { CorporatePortalListComponent } from './corporate-portal-list/corporate-portal-list.component';
import { CorporatePortalEditComponent } from './corporate-portal-edit/corporate-portal-edit.component';
import { CorporateUserlistComponent } from './corporate-userlist/corporate-userlist.component';
import { CorporateApproveListComponent } from './corporate-approve-list/corporate-approve-list.component';
import { PackageManagerComponent } from './package-manager/package-manager.component';
import {MatSelectModule} from '@angular/material/select';
import { PackageManagerAddComponent } from './package-manager-add/package-manager-add.component';
import { PackageManagerEditComponent } from './package-manager-edit/package-manager-edit.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddapointmentmanagementComponent } from './addapointmentmanagement/addapointmentmanagement.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AddsubscriptionComponent } from './addsubscription/addsubscription.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EditsubscriptionComponent } from './editsubscription/editsubscription.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ReviewManagementComponent } from './review-management/review-management.component';
import { HappinessTestManagementComponent } from './happiness-test-management/happiness-test-management.component';
import { HappinessTestAddmanagementComponent } from './happiness-test-addmanagement/happiness-test-addmanagement.component';
import { HappinessTestEditmanagementComponent } from './happiness-test-editmanagement/happiness-test-editmanagement.component';
import { HappinessTestResultComponent } from './happiness-test-result/happiness-test-result.component';
import { HappinessAddTestResultComponent } from './happiness-add-test-result/happiness-add-test-result.component';
import { HappinessEditTestResultComponent } from './happiness-edit-test-result/happiness-edit-test-result.component';
import { HappinessTestCategoryResultComponent } from './happiness-test-category-result/happiness-test-category-result.component';
import { HappinessTestAddCategoryResultComponent } from './happiness-test-add-category-result/happiness-test-add-category-result.component';
import { HappinessTestEditCategoryResultComponent } from './happiness-test-edit-category-result/happiness-test-edit-category-result.component';
import { RewardslistComponent } from './rewardslist/rewardslist.component';
import { AddrewardsComponent } from './addrewards/addrewards.component';
import { EditRewardsComponent } from './edit-rewards/edit-rewards.component';
import { PreAssessmentListComponent } from './pre-assessment-list/pre-assessment-list.component';
import { AddPreAssessmentComponent } from './add-pre-assessment/add-pre-assessment.component';
import { EditPreAssessmentComponent } from './edit-pre-assessment/edit-pre-assessment.component';
import { CommunityEventsListComponent } from './community-events-list/community-events-list.component';
import { CommunityAddEventsListComponent } from './community-add-events-list/community-add-events-list.component';
import { CommunityEditEventsListComponent } from './community-edit-events-list/community-edit-events-list.component';
import { SlotManagementComponent } from './slot-management/slot-management.component';
// import { SlotManagementListComponent } from './slot-management-list/slot-management-list.component';
import { CoachSlotManagementListComponent } from './coach-slot-management-list/coach-slot-management-list.component';

import { MatCalendarCellCssClasses } from '@angular/material/datepicker';


import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';



// import {MatDatepickerModule} from '@angular/material/datepicker';
// import { NgSelectModule } from '@ng-select/ng-select';

// import {MatSidenavModule} from '@angular/material/sidenav';
// import { MatListModule } from '@angular/material/list';
// import {MatDividerModule} from '@angular/material/divider';
// import {MatDialog} from '@angular/material/dialog';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// ---------------------------------
// import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    // FooterComponent,
    // AdminLayoutComponent,
    HomeComponent,
    LoginComponent,
    AppointmentComponent,
    CoachComponent,
    WorkoutTableComponent,
    WorkoutEditTableComponent,
    CoachEditPageComponent,
    UserTableComponent,
    UserEditTableComponent,
    UserViewPageComponent,
    AppointmentViewPageComponent,
    AppointmentEditPageComponent,
    ContentManagementComponent,
    HappyWorkoutEditComponent,
    ContentSosEditComponent,
    // LoginpageComponent,
    WorkoutAddComponent,
    WorkoutViewpageComponent,
    ContentSosAddComponent,
    HappinessSosViewComponent,
    HappinessExpeditionAddComponent,
    HappinessExpeditionViewComponent,
    HappinessExpeditionEditComponent,
    // ExpeditionInnerTableAddComponent,
    // ExpeditionInnerTableViewComponent,
    // ExpeditionInnerTableEditComponent,
    ForumManagementComponent,
    ForumUserlistPageComponent,
    ForumFeedlistComponent,
    ForumAddFeedlistComponent,
    CoachViewPageComponent,
    SosFeelContentTableComponent,
    HappinessExpenditionTableComponent,
    HappinessWorkoutTableComponent,
    QuestionWorkoutViewComponent,
    QuestionWorkoutEditComponent,
    ExerciseWorkoutEditComponent,
    ExerciseWorkoutViewComponent,
    QuestionSosEditComponent,
    ExerciseSosEditComponent,
    QuestionSosViewComponent,
    ExerciseSosViewComponent,
    QuestionExpeditionEditComponent,
    QuestionExpeditionViewComponent,
    CommunityTopicTableComponent,
    CommunityTopicAddComponent,
    CommunityTopicEditComponent,
    CommunityTopicViewComponent,
    CommunityFeedlistTableComponent,
    CommunityUserlistTableComponent,
    CampEditExpeditionComponent,
    CommunityModerationTableComponent,
    ExpeditionModuleAddComponent,
    PaymentManagementComponent,
    CouponManagementComponent,
    CouponAddComponent,
    CouponEditComponent,
    CouponViewComponent,
    AdminApproveComponent,
    PostModerationTableComponent,
    StudentPortalComponent,
    StudentPortalEditComponent,
    StudentPortalViewComponent,
    StudentPortalAddComponent,
    CollegeListTableComponent,
    ApproveCollegeComponent,
    UpcommingAppointmentComponent,
    BlogMasterComponent,
    BlogMasterAddComponent,
    BlogMasterEditComponent,
    BlogMasterQuestioneditComponent,
    ExercisesDatabankAddComponent,
    ExercisesDatabankEditComponent,
    ExercisesDatabankComponent,
    ExercisesDatabankViewComponent,
    NutritionDatabankAddComponent,
    NutritionDatabankComponent,
    NutritionDatabankEditComponent,
    NutritionDatabankViewComponent,
    BlogMasterViewComponent,
    TidbitMasterComponent,
    TidbitMasterAddComponent,
    TidbitMasterEditComponent,
    TidbitMasterViewComponent,
    // AssestmentResultComponent,
    // AssestmentAddresultComponent,
    // AssessmentEditresultComponent,
    // AssestmentViewresultComponent,
    // ResultAssesmentComponent,
    AssestmentresultComponent,
    AssestmentaddresultComponent,
    AssestmenteditresultComponent,
    AssestmentviewresultComponent,
    AssestmentQuestionlistComponent,
    AssestmentQuestionaddComponent,
    AssestmentQuestioneditComponent,
    CorporatePortalAddComponent,
    CorporatePortalListComponent,
    CorporatePortalEditComponent,
    CorporateUserlistComponent,
    CorporateApproveListComponent,
    PackageManagerComponent,
    PackageManagerAddComponent,
    PackageManagerEditComponent,
    AddapointmentmanagementComponent,
    SettingsComponent,
    SubscriptionComponent,
    AddsubscriptionComponent,
    EditsubscriptionComponent,
    ReviewManagementComponent,
    HappinessTestManagementComponent,
    HappinessTestAddmanagementComponent,
    HappinessTestEditmanagementComponent,
    HappinessTestResultComponent,
    HappinessAddTestResultComponent,
    HappinessEditTestResultComponent,
    HappinessTestCategoryResultComponent,
    HappinessTestAddCategoryResultComponent,
    HappinessTestEditCategoryResultComponent,
    RewardslistComponent,
    AddrewardsComponent,
    EditRewardsComponent,
    PreAssessmentListComponent,
    AddPreAssessmentComponent,
    EditPreAssessmentComponent,
    CommunityEventsListComponent,
    CommunityAddEventsListComponent,
    CommunityEditEventsListComponent,
    SlotManagementComponent,
    // SlotManagementListComponent,
    CoachSlotManagementListComponent,
    AppointmentDetailsComponent,
    // ReviewManagementComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,


    // MatDatepickerModule,
    // NgSelectModule,,
    // -----------------------
    // NgSelectModule,
    // MatSidenavModule,
    // MatListModule,
    // MatDividerModule,
    // MatDialog,
    CKEditorModule,
   AppRoutingModule,
    SidebarModule .forRoot(),
    DataTablesModule,
    MatSelectModule,

    // MatSlideToggleModule
    // BrowserAnimationsModule
  ],
  providers: [DatePipe,{ provide:HTTP_INTERCEPTORS,
    useClass:TokenInterceptorInterceptor,
    multi:true} ,{provide: LocationStrategy, useClass: HashLocationStrategy}  ],
  bootstrap: [AppComponent],

})
export class AppModule {
  async ngOnInit() {
    console.log('rrrrrrrrrrrrrrrrrrrrrrrrrr')
  }
}
