<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        User View Page
    </div>

    <div class="marg-t-30">

        <div class="content_bgr_clr padd-20">
            <div class="row marg-0">

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

                    <div class="font_bold_gilroy">
                        <span>Patient ID : {{user_Id}}</span>
                    </div>
                    <div class="marg-t-30 font_bold_gilroy">
                        <span>Email ID : {{user_Email}}</span>
                    </div>

                </div>

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

                    <div class="font_bold_gilroy"> <span>Name :{{userName}}</span></div>

                    <div class="marg-t-30 font_bold_gilroy">
                        <span>Modules Active : Emd 123</span>
                    </div>


                </div>

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

                    <div class="font_bold_gilroy">
                        <span>Mobile : {{user_MobileNo}}</span>
                    </div>


                    <div class="marg-t-30 font_bold_gilroy">

                        <span> Happiness Points : firstLevel</span>


                    </div>

                </div>


            </div>

        </div>

        <div class="marg-t-30">

            <div class="row justify-content-center">

                <button class="btn_sizes bgr_clr_red font_bold_gilroy">Appointment History </button>

                <button class="btn_sizes bgr_clr_blue marg-l-10 font_bold_gilroy">Forum Participation</button>

            </div>

        </div>



    </div>

</div>