<div class="padd-0-25 marg-t-70" >

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/expendition-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Expedition Camp </li>
        </ol>
    </nav>

    <!-- <div class="marg-t-70 fsize27 font_bold_gilroy">
        Expedition Question
    </div> -->
    <!-- ----------------------------------------- -->
    <!-- <div class="f-right  ">
        <button class="add_btn" (click)="expeditionAdd()">Add</button>

    </div> -->

    <div class="marg-t-70" >


        <div class="" style="background-color: #fff; border-radius: 8px;">

             <!-- <table class="w-100">
                <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                    <th>S.No</th>

                    <th>Question </th>
                    <th>QuestionOrder</th>
                    <th>Answer Placeholder</th>

                    <th style="width: 150px;">Action</th>
                </tr>
                <tr class=" font_medium_gilroy" *ngFor="let item of getExpeditionQuestion_List ; let i=index">
                    <td>{{i+1}}</td>

                    <td>{{item.question}}</td>
                    <td>{{item.question_order}}</td>
                    <td>{{item.answer_placeholder}}</td>

                    <td> <span class="cursor_pointer"><img height="22px" width="22px" class=""
                                src="assets/View_icon.png" alt="" (click)="questionExpeditionView(item)"></span>
                        <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px" class=""
                                src="assets/Edit_icon.png" alt="" (click)="questionExpeditionEdit(item)"></span>

                        <span class="marg-l-10 ">

                            <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                data-target="#exercise_delete"> <img height="22px" width="22px" class=""
                                    src="assets/Delete_icon.png" alt="" (click)="questionDeleteItem(item)">
                            </button>
                        </span>
                    </td>
                </tr>
            </table>  -->

             <table class="w-100">
                <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                    <th>S.No</th>

                    <th>Camp Title </th>
                    <!-- <th>No.Of Questions</th> -->


                    <th style="width: 150px;">Action</th>
                </tr>
                <tr class=" font_medium_gilroy"  *ngFor = "let item of campSiteList ; let i = index" >
                    <td>{{i+1}}</td>

                    <td>{{item.camp_title}}</td>
                    <!-- <td>question_order</td> -->


                    <td>
                        <!-- <span class="cursor_pointer"><img height="22px" width="22px" class=""
                                src="assets/View_icon.png" alt="" ></span> -->

                        <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px" class=""
                                src="assets/Edit_icon.png" alt="" (click) = "expedition_EditPage(item)"  ></span>

                        <!-- <span class="marg-l-10 ">

                            <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                data-target="#exercise_delete"> <img height="22px" width="22px" class=""
                                    src="assets/Delete_icon.png" alt="" >
                            </button>
                        </span> -->
                    </td>
                </tr>
            </table>

      <div class="modal fade" id="exercise_delete" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                            <div class="fsize18 marg-t-16"> Are you sure you want to delete this Question?
                            </div>
                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red"
                                data-dismiss="modal">Delete</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
