<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/nutrition_Databank']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Nutrition Recommendation Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <form>



                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Emotion *</label>
                        <select (change)="emotionItem($event)" [(ngModel)]="emotionsId"
                            [ngModelOptions]="{standalone: true}"
                            class=" form-control input_bdr_clr">
                            <!-- <option [disabled]="true" [selected]="true">Select an Emotion</option> -->
                            <option [value]="[item.themotion_id]" [selected]="emotionsId == item.themotion_id"
                                *ngFor="let item of getAllEmotion">
                                {{item.themotion_name}} </option>
                        </select>

                    </div>

                    <!-- <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Range *</label>
                        <select (change)="resultItem($event)"
                            class=" form-control input_bdr_clr">
                            <option [value]="[item.id]" *ngFor="let item of getResultAll">{{item.result}}
                            </option>

                        </select>

                    </div> -->

                    <div class="form-group ">
                        <label class="fsize16 clr_black font_bold_gilroy">Result *</label>
                        <select (change)="resultItem($event)" [(ngModel)]="resultId"
                            [ngModelOptions]="{standalone: true}"
                            class=" form-control input_bdr_clr">
                            <option [value]="[item.id]" [selected]="resultId == item.id"
                                *ngFor="let item of getResultAll">{{item.result}}
                            </option>
                        </select>

                    </div>



                    <div class="form-group ">
                        <label class="fsize16 clr_black font_bold_gilroy">Category *</label>
                        <select (change)="categoryItem($event)"
                            class=" form-control input_bdr_clr">
                            <option [disabled]="true" [selected]="true">Select an Category</option>
                            <option [value]="[item.id]" [selected]="category_ids == item.id"
                                *ngFor="let item of categoryAllList">
                                {{item.category}} </option>
                        </select>
                    </div>
                    <!-- 
                    <div class="form-group ">
                        <label class="fsize16 clr_black font_bold_gilroy">Diet Type *</label>
                      <div class="row m-0">
                            <div>
                                <input [(ngModel)]="dietTypes"
                                [ngModelOptions]="{standalone: true}" type="radio" id="html" name="dietType" value="veg">
                                <label class="marg-l-8">Veg</label>
                            </div>

                            <div class="marg-l-8">
                                <input [(ngModel)]="dietTypes"
                                [ngModelOptions]="{standalone: true}" type="radio" id="css" name="dietType" value="nonveg">
                                <label class="marg-l-8">Non-Veg</label>
                            </div>
                        </div> 

                    </div> -->



                    <!-- <div>

                        <div *ngFor="let ele of container; let i = index" style="margin-top:50px;">

                            <div class="form-group marg-t-20">
                                <label class="fsize16 clr_black font_bold_gilroy">Title*</label>
                                <input type="text" placeholder="Please Enter Title" id="questions"
                                    class="form-control input_bdr_clr " [(ngModel)]="newArray[i].title"
                                    [ngModelOptions]="{standalone: true}" />

                            </div>

                            <div>
                                <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                            </div>
                            <ckeditor name="myckeditor" [(ngModel)]="newArray[i].description"
                                [ngModelOptions]="{standalone: true}" debounce="500">
                            </ckeditor>
                        </div>

                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>

                                <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                    class="btn btn-warning">remove</button>
                            </div>
                        </div>
                    </div> -->

                    <!-- ------------------------------------------------------------------- -->



                    <table class="row-border hover table w-100" datatable *ngIf="nutritionList.description.length > 0">
                        <thead>
                            <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                <th>S.No</th>
                                <th>Display Order</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Diet Type </th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="font_medium_gilroy" *ngFor="let item of nutritionList.description;let i = index">
                                <td *ngIf="!item.delete">{{i+1}}</td>

                                <td *ngIf="!item.delete" [innerHTML]="item.no">
                                </td>

                                <td *ngIf="!item.delete" [innerHTML]="item.title">
                                </td>

                                <td *ngIf="!item.delete" class="para_line" [innerHTML]="item.description">
                                </td>
                                <td *ngIf="!item.delete" class="" [innerHTML]="item.diet_type">
                                </td>

                                <!-- data-toggle="modal" data-target="#exampleModal" -->

                                <td *ngIf="!item.delete" style="width:90px;">
                                    <span class="marg-l-10 cursor_pointer"><img height="18px" width="18px"
                                            class="bgr_black" src="assets/Edit_icon.png" (click)="edit_update(item)"
                                           alt=""></span>

                                    <span class="marg-l-10 cursor_pointer" data-toggle="modal"
                                        data-target="#exampleModalDelete" *ngIf="nutritionList.description.length > 1"
                                        (click)="nutritionEditDelete(item)"> <button type="button"
                                            class="border_clr_none cursor_pointer"> <img height="18px" width="18px"
                                                class="bgr_black" src="assets/Delete_icon.png" alt=""> </button>

                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- data-toggle="modal" data-target="#exampleModalAdd" -->
                    <div style=" float:right; margin-top: 12px;">
                        <button (click)="addPopupShow()"  class="btn btn-primary">Add </button>

                    </div>

                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content" style="padding:0px 20px;">
                                <div style="float:right; font-size: 24px; display: flex; justify-content:end; margin-top: 10px;"
                                    data-dismiss="modal">X</div>
                                <div *ngFor="let ele of container; let i = index" style="margin-top:20px;">
                                    <form [formGroup]="editRegForm">
                                    <div>
                                        <div>
                                            <label class="fsize16 clr_black font_bold_gilroy">Title *</label>
                                        </div>
                                        <input type="text" [(ngModel)]="nutritionTitle"
                                        formControlName="edittitle"
                                            class="form-control input_bdr_clr font_text" maxlength="150" />

                                            <div *ngIf="editSubmitted && editRegForm.get('edittitle').errors" class="alert alert-danger">
                                                <div *ngIf="editRegForm.get('edittitle').errors.required">Title is required</div>
                                            </div>
                                    </div>

                                    <div>
                                        <div>
                                            <label class="fsize16 clr_black font_bold_gilroy">Display Order *</label>
                                        </div>
                                        <input type="text" [(ngModel)]="order_no"   formControlName="editorderno"
                                            class="form-control input_bdr_clr font_text" maxlength="10" />

                                            <div *ngIf="editSubmitted && editRegForm.get('editorderno').errors" class="alert alert-danger">
                                                <div *ngIf="editRegForm.get('editorderno').errors.required">Display Order is required</div>
                                            </div>

                                    </div>



                                    <div class="marg-t-20">
                                        <div>
                                            <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                                        </div>
                                        <ckeditor  [(ngModel)]="nutritionDescription"
                                        formControlName="editdescription" debounce="500">
                                        </ckeditor>

                                        <div *ngIf="editSubmitted && editRegForm.get('editdescription').errors" class="alert alert-danger">
                                            <div *ngIf="editRegForm.get('editdescription').errors.required">Description is required</div>
                                        </div>



                                    </div>


                                    <div class="form-group marg-t-20">
                                        <label class="fsize16 clr_black font_bold_gilroy">Diet Type *</label>
                                        <div class="row m-0">
                                            <div>
                                                <input [(ngModel)]="diet_type"  formControlName="dietTypes"
                                                    type="radio" id="html" name="dietTypes" value="veg">
                                                <label class="marg-l-8">Veg</label>
                                            </div>

                                            <div class="marg-l-8">
                                                <input [(ngModel)]="diet_type"  formControlName="dietTypes"
                                                    type="radio" id="css" name="dietTypes" value="nonveg">
                                                <label class="marg-l-8">Non-Veg</label>
                                            </div>
                                        </div>

                                        <div *ngIf="editSubmitted && editRegForm.get('dietTypes').errors" class="alert alert-danger">
                                            <div *ngIf="editRegForm.get('dietTypes').errors.required">Diet Type is required</div>
                                        </div>

                                    </div>
                                </form>

                                </div>
                                <div class=" row justify-content-center  font_bold_gilroy"
                                    style="margin-top:20px; margin-bottom:20px; ">
                                    <button type="button" class="btn submit_btn_clr"
                                        (click)="submitUpdate()">Save</button>

                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- ------------------------------------------- -->
                    <div class="modal fade" id="exampleModalDelete" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">

                                <div class="modal-body">

                                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                                    <div>
                                        Do You Want to Delete ?
                                    </div>

                                </div>
                                <div class=" row justify-content-center  font_bold_gilroy"
                                    style="margin-top:20px; margin-bottom:20px;">
                                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                        (click)="delete_submit()">Submit</button>
                                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- ----------------------------------------------------------------- -->

                    <div class="modal fade" id="exampleModalAdd" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">


                        <div class="modal-dialog" role="document">
                       
                            <div class="modal-content">
                                <div data-dismiss="modal" style="font-size:18px; display: flex; justify-content: end; margin-top: 10px; margin-right: 14px;">
                                    X
                                </div> 

                                <div class="modal-body">

                                    <form [formGroup]="registerForm">


                                    <div>
                                        <div class="fsize18 font_bold_gilroy "> Order No* </div>
                                        <div>
                                            <input type="text" [(ngModel)]="order_no"
                                            formControlName="orderno"
                                                class="form-control input_bdr_clr font_text" maxlength="10" />
                                        </div>
                                     
                                        <div *ngIf="submitted && registerForm.get('orderno').errors" class="alert alert-danger">
                                            <div *ngIf="registerForm.get('orderno').errors.required">Order No is required</div>
                                        </div>



                                    </div>

                                    <div class="fsize18 font_bold_gilroy marg-t-20"> Title* </div>

                                    <div>

                                        <input type="text" [(ngModel)]="nutritionTitle"
                                        formControlName="title"
                                            class="form-control input_bdr_clr font_text" maxlength="150" />
                                    </div>
                                  
                                    <div *ngIf="submitted && registerForm.get('title').errors" class="alert alert-danger">
                                        <div *ngIf="registerForm.get('title').errors.required">Title is required</div>
                                    </div>




                                    <div class="marg-t-20">
                                        <div class="fsize18 font_bold_gilroy "> Description* </div>
                                        <ckeditor name="myckeditor" [(ngModel)]="nutritionDescription"
                                        formControlName="description" debounce="500">
                                        </ckeditor>


                                        <div *ngIf="submitted && registerForm.get('description').errors" class="alert alert-danger">
                                            <div *ngIf="registerForm.get('description').errors.required">Description is required</div>
                                        </div>


                                    </div>


                                    <div class="form-group marg-t-20">
                                        <label class="fsize16 clr_black font_bold_gilroy">Diet Type *</label>
                                        <div class="row m-0">
                                            <div>
                                                <input [(ngModel)]="diet_type"  formControlName="diettype"
                                                    type="radio" id="html" name="dietTypes1" value="veg">
                                                <label class="marg-l-8">Veg</label>
                                            </div>

                                            <div class="marg-l-8">
                                                <input [(ngModel)]="diet_type"  formControlName="diettype"
                                                    type="radio" id="css" name="dietTypes1" value="nonveg">
                                                <label class="marg-l-8">Non-Veg</label>
                                            </div>
                                        </div>

                                      
                                        <div *ngIf="submitted && registerForm.get('diettype').errors" class="alert alert-danger">
                                            <div *ngIf="registerForm.get('diettype').errors.required">Diet Type is required</div>
                                        </div>




                                    </div>

                                </form>
                                </div>
                                <div class=" row justify-content-center  font_bold_gilroy"
                                    style="margin-top:20px; margin-bottom:20px;">
                                    <button type="button" class="btn_submit_cancel bgr_clr_red" 
                                        (click)="add_submit()">Submit</button>
                                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div class="form-group" style="margin-top:100px;">
                        <button type="button" (click)="nutritionUpdate()" class="btn  submit_btn_clr">Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="nutritionBackBtn()">Cancel</button>
                    </div>

                </form>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            </div>
        </div>
    </div>
</div>