import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;
declare const $: any;
@Component({
  selector: 'app-content-sos-add',
  templateUrl: './content-sos-add.component.html',
  styleUrls: ['./content-sos-add.component.scss']
})
export class ContentSosAddComponent implements OnInit {
  show_questionText_div = false;
  show_questionImage_div = false;
  show_questionAudio_div = false;
  affirmation_div = false;
  grid_div = false;
  multi_response_div = false;
  review_div = false;
  Yes_or_No_div = false;
  isShown: boolean = false;
  YesQuestion_ShowDiv = false;
  NoQuestion_ShowDiv = false;
  // -----

  sos_add_cancelBtn = '/sos-feel-table';
  sos_Edit_Page = '/sos-edit';

  question_Type = "";
  noOfquestions = "";
  answer_placeholder_text = ""
  answer_placeholder_text1 = "";
  answer_placeholder_text2 = "";
  mood_Type = "";
  yesPlaceholderText = "";
  yesQuestionText = "";
  noQuestionText = "";
  noPlaceholderText = "";
  questionYesRadioBtn = "";
  questionNoRadioBtn = "";
  // check1  = "";
  // check2 = "";
  show_FeelingWheels = "";
  show_Cognitive_Biases = "";
  status = "";
  getAllEmotion = [];
  showFeelingwheel = false;
  showCognitiveBiases = false;
  emotionsId: any;
  showFeelingWheel = "";
  noShowFeelings = false;
  yesShowFeelings = false;
  redirectLogin_url = "/"
  registerForm: FormGroup;
  submitted = false;
  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder) { }

  ngOnInit() {
    var selectRange = '';
    var val = '';
    for (var day = 1; day <= 10; day++) {
      selectRange += '<option val=' + day + '>' + day + '</option>';
    }
    $('select#sosRange').html(selectRange);
    // ----------------Checkbox-select any one------------------------------------------
    $("input:checkbox").on('click', function () {

      var $box = $(this);
      if ($box.is(":checked")) {

        var group = "input:checkbox[name='" + $box.attr("name") + "']";

        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });
    // ----------------SOS Get Api list------------------------------
    this.sosGetEmotionListApi();
    // ---------------------------------------------------------------


    this.registerForm = this.fb.group(
      {
        emotion: ["", Validators.required],
        showFeel: ["", Validators.required],
        noOfquest: ["", Validators.required],
        status: ["", Validators.required],
        // answerType: ["", Validators.required],
        // examples: ["", Validators.required],
      },
    );





  }
  onlyOneValue(e) {
    if (e.target.id == "show_feelingWheel") {
      this.showFeelingwheel = true;
      this.showCognitiveBiases = false;

      if (this.showFeelingwheel = true) {

      }

    }
    else if (e.target.id == "show_cognitiveBiases") {
      this.showFeelingwheel = false;
      this.showCognitiveBiases = true;
    }
  }

  emotionItem(emotionItem) {
    this.emotionsId = emotionItem.themotion_id;

  }

  async sosAddSubmit() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var postapi = "/sos/create_sos";
    // if (this.showFeelingwheel == true) {

    //   this.show_FeelingWheels = $("#show_feelingWheel").val();
    // }
    // else if (this.showCognitiveBiases == true) {
    //   this.show_Cognitive_Biases = $("#show_cognitiveBiases").val();
    // }
    var showFeelingwheel_Yes = $("#yesShowFeeling").val();
    var showFeeling_wheel_no = $("#noShowFeeling").val();

    if (showFeelingwheel_Yes != "") {
      var showFeeling_wheel = showFeelingwheel_Yes;
    }
    else if (showFeeling_wheel_no != "") {
      var showFeeling_wheel = showFeeling_wheel_no;
    }

    var sosADDdata = {
      "no_of_question": this.noOfquestions,
      "emotion": this.emotionsId,
      "show_feeling_wheel": showFeeling_wheel,
      "status": this.status,
      "is_active": 1
    };

    (await this.ApiService.postApi(postapi, sosADDdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

            swal({
              title: "Success!",
              text: "SOS Add Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });

            this.ApiService.sos_Add_id = res.data;
            this.router.navigate([this.sos_add_cancelBtn]);
          }

          else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);

            swal({
              icon: 'error',
              title: 'Oops...',
              text: "Unauthorized access",
              type: "error"
            }, function () {
              alert('yo');
            });
          }






        }
      });
  }

  async sosGetEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data
    });
  }



  questionType_One(e) {

    var questionValue = e.target.value;
    if (questionValue == 'text') {
      this.show_questionText_div = true;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'image') {
      this.show_questionText_div = false;
      this.show_questionImage_div = true;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'audio') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = true;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'affirmation') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = true;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'grid') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = true;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'multi_response') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = true;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }

    if (questionValue == 'Review') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = true;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'yes_or_no') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = true;
      this.YesQuestion_ShowDiv = false;
      this.NoQuestion_ShowDiv = false;
    }

  }

  yesOrNo_questionType(e) {
    var questionValue = e.target.value;
    if (questionValue == 'Yes') {
      this.YesQuestion_ShowDiv = true;
      this.NoQuestion_ShowDiv = false;
    }
    if (questionValue == 'No') {
      this.YesQuestion_ShowDiv = false;
      this.NoQuestion_ShowDiv = true;
    }
  }
  sosAddCancel() {
    this.router.navigate([this.sos_add_cancelBtn]);
  }

}
