<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Feed List - Add
    </div>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <form>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Post Description</label>
                        <input type="text" id="" class="form-control input_bdr_clr " />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Post Date</label>
                        <input type="text" id="" class="form-control input_bdr_clr " />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Post No of Comments</label>
                        <input type="text" id="" class="form-control input_bdr_clr " />
                    </div>



                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr">Submit</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>

        </div>
    </div>

</div>