import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-exercises-databank-view',
  templateUrl: './exercises-databank-view.component.html',
  styleUrls: ['./exercises-databank-view.component.scss']
})
export class ExercisesDatabankViewComponent implements OnInit {

  isShown: boolean = false;
  showVideoDiv = false;
  showImageDiv = true;
  exerciseBenefit: any;
  questionType: any;
  exerciseViewDatabankId:any;
  
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.exerciseViewDatabankId=this.ApiService.exerciseDatabankViewId;
    this.getExerciseDatabank(); 

  }

  questionOne(e) {
    this.questionType = e.target.value;
    if (this.questionType == 'image') {
      this.showVideoDiv = false;
      this.showImageDiv = true;

    }
    if (this.questionType == 'video') {
      this.showImageDiv = false;
      this.showVideoDiv = true;


    }






  }

  async getExerciseDatabank() {
    var getapi = "/list_exercise/"+this.exerciseViewDatabankId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {

   this.exerciseBenefit = res.result.exercise_benefits;
   this.questionType = res.result.exercise_type
  //  exercise_type
    
   }
      
    });
  }



}
