<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/tidbit_master']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Tidbit Master Edit</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">

                <div class="">
                    <form>
                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Emotion *</label>
                            <select disabled (change)="emotionItem($event)"
                                class=" form-control input_bdr_clr">
                                <option [value]="[item.themotion_id]" [selected]="emotionsId == item.themotion_id"
                                    *ngFor="let item of getAllEmotion">
                                    {{item.themotion_name}} </option>
                            </select>
                        </div>

                       <div style="background-color: #fff !important;
                       padding: 10px;" >

<table class="row-border hover table w-100"  *ngIf="tidbitList.tidbit.length > 0">
    <thead>
        <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
            <th>S.No</th>
            <th>Order</th>
            <th>Image</th>
            <th style="width:110px;">Action</th>
        </tr>
    </thead>

    <tbody>
        <tr class="font_medium_gilroy" *ngFor="let item of tidbitList.tidbit; let i = index">
            <td *ngIf="!item.delete">{{ i+1}}</td>

            <td *ngIf="!item.delete">{{item.order_no}}
            </td>

            <td *ngIf="!item.delete" class="" ><img class="w-190" src="{{item.image}}" alt="">
            </td>

            <td *ngIf="!item.delete">
                <!-- <span class="cursor_pointer"><img height="22px" width="22px" class="bgr_black"
                        src="assets/View_icon.png" alt=""></span>  -->

                        <!-- data-toggle="modal" data-target="#exampleModalEdit" -->

                <span class="marg-l-10 cursor_pointer" (click)="edit_tidbit(item)"><img
                        height="22px" width="22px" class="bgr_black" src="assets/Edit_icon.png"
                       alt=""></span>

                <span class="marg-l-10 cursor_pointer" data-toggle="modal"
                    data-target="#exampleModalDelete" (click)="deleteItems(item)"> <button
                        type="button" class="border_clr_none cursor_pointer"> <img height="22px"
                            width="22px" class="bgr_black" src="assets/Delete_icon.png" alt="">
                    </button>

                </span>
            </td>
        </tr>
    </tbody>
</table>
                       </div>



                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">

                                <!-- data-toggle="modal" data-target="#exampleModalAdd" -->

                                <button (click)="tidbitAddPopup()"  style="margin-right:20px;"
                                    class="btn btn-primary">Add</button>

                                <!-- <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                    class="btn btn-warning">remove</button> -->
                            </div>
                        </div>

                        <!-- ----------------------------------------------------------------- -->

                        <div class="modal fade" id="exampleModalAdd" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">

                                    <div class="modal-body">

                                        <form [formGroup]="registerForm">

                                        <div class="fsize18 font_bold_gilroy "> Display order* </div>

                                        <div>

                                            <input type="text" class="form-control input_bdr_clr font_text"
                                                [(ngModel)]="orderNo" formControlName="displayorder"
                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                maxlength="50" />
                                        </div>


                                        <div *ngIf="submitted && registerForm.get('displayorder').errors" class="alert alert-danger">
                                            <div *ngIf="registerForm.get('displayorder').errors.required">Display order is required</div>
                                        </div>
            


                                        <div class="marg-t-20">
                                            <div class="fsize18 font_bold_gilroy "> Image* </div>

                                            <input type="file" accept="image/*" (change)="handleFileSelectOne($event)"
                                                class="form-control input_bdr_clr font_text"  formControlName="uploadimage"  />

                                                <div *ngIf="submitted && registerForm.get('uploadimage').errors" class="alert alert-danger">
                                                    <div *ngIf="registerForm.get('uploadimage').errors.required">Image is required</div>
                                                </div>

                                        </div>

                                    </form>

                                    </div>
                                    <div class=" row justify-content-center  font_bold_gilroy"
                                        style="margin-top:20px; margin-bottom:20px;">
                                        <button type="button" class="btn_submit_cancel bgr_clr_red" 
                                            (click)="add_Submit()">Submit</button>
                                        <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ----------------------------------------------------------------- -->

                        <div class="modal fade" id="exampleModalEdit" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">

                                    <div class="modal-body">
                                        <form [formGroup]="editRegForm">

                                        <div class="fsize18 font_bold_gilroy "> Display order* </div>

                                        <div>

                                            <input type="text" class="form-control input_bdr_clr font_text"
                                                [(ngModel)]="orderNo" formControlName="editdisplayorder"
                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                maxlength="50" />

                                         
                                        <div *ngIf="editSubmitted && editRegForm.get('editdisplayorder').errors" class="alert alert-danger">
                                            <div *ngIf="editRegForm.get('editdisplayorder').errors.required">Display order is required</div>
                                        </div>

                                        </div>

                                        <div class="marg-t-20">
                                            <div class="fsize18 font_bold_gilroy "> Image* </div>

                                            <div class="row m-0">

                                                <input type="file" accept="image/*" (change)="handleFileSelectOne($event)"
                                                class=" input_bdr_clr font_text"   />

                                                <div style="margin-left: 12px;">
                                                   <img height="40px" width="40px" src="{{base64textStringTwo}}" alt="">
                                                </div>
                                            </div>


                                                <!-- <div *ngIf="editSubmitted && editRegForm.get('edituploadimage').errors" class="alert alert-danger">
                                                    <div *ngIf="editRegForm.get('edituploadimage').errors.required">Image is required</div>
                                                </div> -->
                                        </div>
                                        </form>
                                    </div>
                                    <div class=" row justify-content-center  font_bold_gilroy"
                                        style="margin-top:20px; margin-bottom:20px;">
                                        <button type="button" class="btn_submit_cancel bgr_clr_red" 
                                            (click)="updateSubmit()">Submit</button>
                                        <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- -------------------------------------------------------------------------------- -->



                        <!-- ------------------------------------------- -->
                        <div class="modal fade" id="exampleModalDelete" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">

                                    <div class="modal-body">

                                        <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                                        <div>
                                            Do You Want to Delete ?
                                        </div>

                                    </div>
                                    <div class=" row justify-content-center  font_bold_gilroy"
                                        style="margin-top:20px; margin-bottom:20px;">
                                        <button type="button" class="btn_submit_cancel bgr_clr_red cursor_pointer" data-dismiss="modal"
                                            (click)="deleteSubmit()">Submit</button>
                                        <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue cursor_pointer"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="" style="margin-top: 80px;">
                            <span class="fsize16 clr_black font_bold_gilroy">Status * :
                            </span>
                            <span>
                                <label for="vehicle1"> Active</label>
                                <input class="marg-l-8" [(ngModel)]="is_active" [ngModelOptions]="{standalone: true}"
                                    type="radio" id="vehicle1" name="viewBeliefs" value="1">
                            </span>

                            <span>
                                <label class="marg-l-8" for="vehicle1">In Active</label>
                                <input class="marg-l-8" [(ngModel)]="is_active" [ngModelOptions]="{standalone: true}"
                                    type="radio" id="vehicle1" name="viewBeliefs" value="2">
                            </span>
                        </div>





                        <div class="form-group marg-t-40">
                            <button type="button" (click)="updateEditApi()" class="btn  submit_btn_clr">Submit</button>
                            <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                                (click)="blogCancelBtn()">Cancel</button>
                        </div>
                    </form>
                </div>


                <div>

                </div>


            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            </div>
        </div>




    </div>

</div>