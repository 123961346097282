import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;
@Component({
  selector: 'app-happiness-expendition-table',
  templateUrl: './happiness-expendition-table.component.html',
  styleUrls: ['./happiness-expendition-table.component.scss']
})
export class HappinessExpenditionTableComponent implements OnInit {
  expedition_View_url = '/happiness-expedition-view';
  expedition_add = '/expedition-module-add';
  expedition_Edit_url = '/camp-Edit-Expedition';
  expeditionCampDetails = [];
  campSiteList = [];
  listCampId: any
  moduleTitleList = [];
  moduleListName = [];
  expeditiondelete_Id: any;
  editDescription: any;
  editModuleIds: any;
  editTitle: any;
  activeStatus: any;
  redirectLogin_url = "/"
  registerForm: FormGroup;
  submitted = false;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit() {
    this.getExpeditionListData();
    this.getCampSiteListApi();
    this.getModuleListApi();

    this.registerForm = this.fb.group(
      {
      title: ["", Validators.required],
      editdescription: ["", Validators.required],
      status: ["", Validators.required],
      // 
      },
    );


  }

  async getExpeditionListData() {

    var getapi = "/get_expedition";

    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.expeditionCampDetails = res.data.reverse();

      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Your session has been expired!",
          type: "error"
        }, function () {
          alert('yo');
        });
      }

      for (let item of this.expeditionCampDetails) {

        this.listCampId = item.stress_id;
      }

    });

  }

  async getCampSiteListApi() {
    var getapi = "/get_camp_site";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.campSiteList = res.data;

      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Your session has been expired!",
          type: "error"
        }, function () {
          alert('yo');
        });
      }

      var ids = this.listCampId;

      //   for(let item of this.campSiteList ){
      //     var rajal = item.id;


      //     if(ids == rajal){
      //       var raja = item.camp_name;

      //     }
      //  }
    });
  }

  async getModuleListApi() {
    var getapi = "/get_expedition_title";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.moduleTitleList = res.data.reverse();
      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Your session has been expired!",
          type: "error"
        }, function () {
          alert('yo');
        });
      }

      for (let item of this.moduleTitleList) {
        var idd = item.id;
        if (this.listCampId == idd) {
          this.moduleListName = item.title;
        }
      }
    });
  }

  expedition_EditPage(editItem: any) {
    this.ApiService.expeditionEditId = editItem.id;
    this.ApiService.expeditionModuleTitle = editItem.title;
    this.router.navigate([this.expedition_Edit_url]);
  }

  expedition_ViewPage(selectedItem: any) {
    this.ApiService.expeditionViewId = selectedItem.id;
    this.router.navigate([this.expedition_View_url]);

  }

  expeditionAdd() {
    this.router.navigate([this.expedition_add]);
  }
  expedition_delete(deleteItem: any) {
    this.expeditiondelete_Id = deleteItem.id
  }
  async expeditionTableDeleteApi() {
    var postApi = "/admin_delete_expedition";
    var deletedata = {
      "id": this.expeditiondelete_Id,
    };

    (await this.ApiService.postApi(postApi, deletedata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Expedition Deleted Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
          }
        }
        this.getExpeditionListData();
      });
  }

  editModule(items: any) {
    this.editModuleIds = items.id;
    this.editDescription = items.description;
    this.editTitle = items.title;
    var activestatus = items.status;

    if (activestatus == "1") {
      this.activeStatus = "1"
    }
    else if (activestatus == "0") {
      this.activeStatus = "0"
    }

  }

  async editModuleSaveApi() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var postapi = "/update_expedition_title_description";
    var editModuledata = {
      'exp_id': this.editModuleIds,
      'status': this.activeStatus,
      'title': this.editTitle,
      'description': this.editDescription,

    };
    (await this.ApiService.postApi(postapi, editModuledata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

            swal({
              title: "Success!",
              text: "Expedition  Added Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });

       window.location.reload();
            this.getModuleListApi();
          }
          else if (res.status === 401) { }
        }
      });


  }




}
