import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-exercises-databank',
  templateUrl: './exercises-databank.component.html',
  styleUrls: ['./exercises-databank.component.scss']
})
export class ExercisesDatabankComponent implements OnInit {
  exerciseAdd_url = "/exercisesAddDatabank";
  exerciseEdit_url = "/exercisesEditDatabank";
  exerciseView_url = "/exercises_View_Databank";
  databankList: any;
  deleteExerciseId: any;
  showLoaderDiv = false;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.getlist_exercise();
  }

  exerciseAddDatabank() {
    this.router.navigate([this.exerciseAdd_url]);
  }

  exerciseEditDatabank(item: any) {
    this.ApiService.exerciseDatabankEditId = item.id;
    localStorage.setItem("exerciseEdit_id", item.id);

    this.router.navigate([this.exerciseEdit_url]);
  }
  exerciseViewDatabank(items: any) {
    this.ApiService.exerciseDatabankViewId = items.id
    this.router.navigate([this.exerciseView_url]);
  }
  // -------------------------------------------


  async getlist_exercise() {
    this.showLoaderDiv = true;
    var getapi = "/list_exercise";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.databankList = res.result;
        this.showLoaderDiv = false;
      }
    });

  }

  deleteItems(items: any) {
    this.deleteExerciseId = items.id
  }


  async exercisesDelete() {
    var deleteApi = "/delete_exercise/" + this.deleteExerciseId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      if (res.status === 200) {

        this.getlist_exercise();

      }
    });
  }


}
