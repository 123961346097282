import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { title } from 'process';

declare var swal: any;

@Component({
  selector: 'app-happiness-test-addmanagement',
  templateUrl: './happiness-test-addmanagement.component.html',
  styleUrls: ['./happiness-test-addmanagement.component.scss']
})
export class HappinessTestAddmanagementComponent implements OnInit {

  getallcategory: any[];
  id: any;
  isShown: boolean = false;
  container: any[] = [1];
  newArray: {
    question: any,
    // strongly_disagree: any,
    // disagree: any,
    // neutral: any,
    // agree: any,
    // strongly_agree: any
    isactive: any,
  }[] = [{ question: "", isactive: 1 }];
  keyValue: any;
  happinessBack_url = "/happiness-test-management"

  happinesstestlist: any[]
  categoryId: any

  showErrorMessage = false;


  constructor(private router: Router, private ApiService: ApiService, private http: HttpClient) { }

  ngOnInit(): void {
    this.getcategoryListApi()
    this.getlist_happinesstestApi()
  }

  // =========================================================

  async getcategoryListApi() {
    var getapi = "/listHappinesstestcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getallcategory = res.result
      console.log("adddd", this.getallcategory)
    });
  }

  // =====================================================

  categoryitem(event: any) {
    this.id = event.target.value;
    console.log("this.categoryid", this.id)
    // const categoryExists = this.happinesstestlist.some(item => item.category_id === this.id);
    // this.showErrorMessage = categoryExists;

    // if (categoryExists) {
    //   this.showErrorMessage = true;
    // } else {
    //   this.showErrorMessage = false;
    // }
  }

  // ====================================================

  async getlist_happinesstestApi() {
    var getapi = "/listHappinesstest";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.happinesstestlist = res.result;
        this.categoryId = res.result.category_id
      }
      console.log("aaaaaaaa", this.happinesstestlist);
    });
  }

  // =====================================================

  async addBenefits() {

    if (this.newArray.length >= 4) {
      swal({
        title: 'Error!',
        text: 'Only Four questions for each category',
        type: 'error'
      });
      return;
    }

    this.keyValue = this.container.length + 1;
    this.newArray.push({ question: "", isactive: 1 });
    this.container.push(this.keyValue);
    var allTitle = this.newArray.map((item) => {
      return { isactive: item.isactive }
    })
    console.log(allTitle)

  }

  // =========================================================

  async removeBenefits(index: any) {
    // if (this.container.length > 1) {
    //   this.container.pop();
    //   this.newArray.pop();
    // }
    if (index >= 0 && index < this.container.length) {
      this.container.splice(index, 1);
      this.newArray.splice(index, 1);
    }
  }

  // ==========================================================

  onchange(event: any, index: number) {
    console.log("aaaaaaaaasasa", event);
    this.newArray[index].isactive = event.checked ? 1 : 0;
  }

  // ============================================================

  async happinesstestAddSubmit() {

    if (this.newArray.length < 4) {
      swal({
        title: 'Error!',
        text: 'Four questions are mandatory',
        type: 'error'
      });
      return;
    }

    var allTitle = this.newArray.map((item) => {
      return { question: item.question, isactive: item.isactive }
    })

    var postapi = "/addAndUpdateHapinessTest";
    var exercisesAdd = {
      "category_id": this.id,
      "happiness_test": allTitle
    };

    (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
      res => {

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Test Question Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.happinessBack_url]);
        }

      },

      (error) => {
        var errMsg = '';
        if (typeof error.error.message === 'object') {
          for (var i in error.error.message) {
            errMsg += error.error.message[i] + '<br />';
          }
        } else {
          errMsg = error.error.message
        }
        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });

      }
    )
  }


  // ==================================================================

  happinesstestBackUrl() {
    this.router.navigate([this.happinessBack_url]);
  }

}
