<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/expendition-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page"> Expedition View</li>
        </ol>
    </nav>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <div>
                        <form id="question_form_Id">
                            <div class="form-group marg-t-20">

                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Module *</label>
                                    <input type="text" id="module" class="form-control input_bdr_clr "
                                        [(ngModel)]="module" [ngModelOptions]="{standalone: true}" />
                                </div>


                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Camp *</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="campName" [ngModelOptions]="{standalone: true}" />
                                </div>
                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Camp Title *</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="camp_Title" [ngModelOptions]="{standalone: true}" />
                                </div>
                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                                    <!-- <textarea class="form-control input_bdr_clr "  style="min-height: 100px;"
                                        [(ngModel)]="descriptions " [ngModelOptions]="{standalone: true}"
                                         >
                                     </textarea> -->

                                    <ckeditor name="myckeditor" [(ngModel)]="descriptions"
                                        [ngModelOptions]="{standalone: true}" debounce="500">
                                    </ckeditor>
                                </div>


                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">No.of questions*</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="noOfQuestion" [ngModelOptions]="{standalone: true}" />
                                </div>
                            </div>

                        </form>
                    </div>


                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>