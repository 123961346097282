<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/home']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" style="cursor: pointer; " aria-current="page">
                Appointment
                Details
            </li>
        </ol>
    </nav>

    <div class="marg-t-30">
        <div class="card-container">
            <!-- Repeat this card for each appointment detail -->
            <div class="card">
                <div class="card-body">
                    <div class="details-section">
                        <div class="mb-4">
                            <h5>Basic Details</h5>
                        </div>
                        <div class="detail-item">

                            <span class="detail-key">Coach Name</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.coach_name ?
                                getallAppointment_appointment?.coach_name : "NA"}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">User Name</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.user_name ?
                                getallAppointment_appointment?.user_name : "NA"}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">User Type</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.user_type ?
                                getallAppointment_appointment?.user_type : "NA"}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Mobile Number</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.user_mobile ?
                                getallAppointment_appointment?.user_mobile : "NA"}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Email</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.user_email ?
                                getallAppointment_appointment?.user_email : "NA"}}</span>

                        </div>

                        <div *ngIf="getallAppointment_appointment?.college_name || getallAppointment_appointment?.corporate_name"
                            class="detail-item">
                            <span class="detail-key">College/Corporate</span> :
                            <span class="detail-value">
                                {{getallAppointment_appointment?.college_name ?
                                getallAppointment_appointment.college_name : ''}}
                                {{getallAppointment_appointment?.corporate_name ?
                                getallAppointment_appointment.corporate_name : ''}}
                            </span>
                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Status</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.appointment_status ?
                                getallAppointment_appointment?.appointment_status : "NA"}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Appt Date</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.date ?
                                getallAppointment_appointment?.date : "NA"}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Appt Time</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.time ?
                                getallAppointment_appointment?.time : "NA"}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">App Number</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.app_number ?
                                getallAppointment_appointment?.app_number : "NA"}}</span>

                        </div>

                        <div class="detail-item">
                            <span class="detail-key">Session Duration</span> : <span
                                class="detail-value">{{getallAppointment_appointment?.duration ?
                                getallAppointment_appointment?.duration : "NA"}}</span>

                        </div>
                    </div>

                    <div class="details-section">
                        <div class="mb-4">
                            <h5>Payment Details</h5>
                        </div>
                        <div class="detail-item">

                            <span class="detail-key">Order ID</span> : <span
                                class="detail-value">{{getallAppointment_payment?.order_id ?
                                getallAppointment_payment?.order_id : 'NA'}}

                            </span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Payment Method</span> : <span
                                class="detail-value">{{getallAppointment_payment?.payment_method
                                ? getallAppointment_payment?.payment_method : 'NA' }}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Payment date&Time</span> : <span
                                class="detail-value">{{getallAppointment_payment?.payment_datetime ?
                                getallAppointment_payment?.payment_datetime : 'NA'}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Amount</span> : <span
                                class="detail-value">{{getallAppointment_payment?.amount ?
                                getallAppointment_payment?.amount : 'NA'}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Discount Amount</span> : <span
                                class="detail-value">{{getallAppointment_payment?.discount_amount ?
                                getallAppointment_payment?.discount_amount : 'NA'}}</span>

                        </div>

                        <div class="detail-item">

                            <span class="detail-key">Sub Total Amount</span> : <span
                                class="detail-value">{{getallAppointment_payment?.subtotal_amount ?
                                getallAppointment_payment?.subtotal_amount : 'NA'}}</span>

                        </div>
                    </div>

                    <div class="details-section" *ngIf="validAppointments.length > 0">
                        <div>
                            <h5>Session Details</h5>
                        </div>
                        <div class="table-responsive mt-4">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date & Time</th>
                                        <th>Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of validAppointments">
                                        <td>{{item.joined_datetime ? item.joined_datetime : item.left_datetime}}</td>
                                        <td>
                                            <ng-container
                                                *ngIf="item.joined_datetime && item.joined_by; else leftStatus">
                                                {{item.joined_by}} joined
                                            </ng-container>
                                            <ng-template #leftStatus>
                                                <ng-container *ngIf="item.left_datetime && item.left_by">
                                                    {{item.left_by}} left
                                                </ng-container>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Repeat end -->
        </div>
    </div>
</div>