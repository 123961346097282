<div>
    <div class="padd-0-25">
        <div class="marg-t-70 fsize27 font_bold_gilroy">
            WorkOut Day
        </div>

        <div class="marg-t-30">
            <div class="f-right marg-t-20 marg-b-20">
                <button class="add_btn" (click)="workOutAdd()">Add</button>
            </div>
            <div class=""  >
                <div  *ngIf="workoutDayQuestionAll.length > 0" >
                    <table class="row-border hover table w-100" datatable style="background-color: #fff; border-radius: 8px;" >
                        <thead>
                        <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;" >
                            <th>S.No</th>
                            <th>Day</th>
                            <th>Level</th>
                            <th>Range</th>
                            <th>No.Of.Questions</th>
                            <th>Title</th>
                            <th>Category</th>
                            <!-- <th>Show ReadMore</th> -->
                            <th>Read More Content </th>
                            <th style="width: 120px;text-align: center;">Action</th>

                        </tr>
                    </thead>
                    <tbody >
                        <tr id="workoutquestionid" class=" font_medium_gilroy"
                            *ngFor="let item of workoutDayQuestionAll;let i=index">
                            <td>{{i+1}}</td>
                            <td>{{item.day}}</td>
                            <td>{{item.level}}</td>
                            <td>{{item.range}}</td>
                            <td>{{item.no_of_question}}</td>
                            <td> {{item.day_title}} </td>
                            <td> {{item.category_name}} </td>
                            <!-- <td> {{item.show_readmore}} </td> -->
                            <td [innerHTML]="item.readmore_content">  </td>

                            <td style="text-align:center;">
                                 <!-- <span class="cursor_pointer"><img height="22px" width="22px"
                                        (click)="workout_ViewPage(item)" class="" src="assets/View_icon.png" alt=""></span> -->



                                <span (click)="edit_happyWorkOut(item)" class="marg-l-10 cursor_pointer"><img height="22px"
                                        width="22px" class="" src="assets/Edit_icon.png" alt=""></span>
                                <span class="marg-l-10">

                                    <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                        data-target="#exampleModal" (click)="deleteDay(item)"> <img height="22px"
                                            width="22px" class="" src="assets/Delete_icon.png" alt=""> </button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>


                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                                <div class="fsize16">
                                    Are you sure you want to delete this day workout? it will remove all the exercise
                                    and
                                    questions associated.
                                </div>

                            </div>
                            <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                                <button type="button" class="btn_submit_cancel bgr_clr_red" (click)="deletedItem()"
                                    data-dismiss="modal">Submit</button>
                                <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                    data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>




            </div>

        </div>

    </div>


    <!-- <happy-workout-edit.component></happy-workout-edit.component> -->

</div>
