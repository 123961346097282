import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-question-expedition-view',
  templateUrl: './question-expedition-view.component.html',
  styleUrls: ['./question-expedition-view.component.scss']
})
export class QuestionExpeditionViewComponent implements OnInit {

  isShown: boolean = false;
  questions: any;
  questionOrder: any;
  status: any;
  collectResponse: any;
  responsFromPrevious: any;
  expeditionQuestion_ViewId: any;
  getExpeditionQuestion_List = [];
  answerPlaceholder: any;
  view_Beliefs: any;
  campName: any;
  previouscampSiteQuestion: any;
  previousCampSite_Yes_Div = false;
  viewCampId: any;
  campSiteList: any;
  prevCampid: any;
  prevCampidTwo: any;
  prevCampQuestionId: any;
  prevCampQuestionIdTwo: any;
  previousCampsiteList: any;
  previousCampsiteListTwo: any;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.expeditionQuestion_ViewId = this.ApiService.questionExpeditionViewId;
    this.expeditionGetQuestionApi();
    this.getCampSiteListApi();
    this.getCampSitePreviousQuestion();



  }

  async expeditionGetQuestionApi() {

    var postapi = "/get_single_question";
    var getQuestiondata = {
      "question_id": this.expeditionQuestion_ViewId
    };
    (await this.ApiService.postApi(postapi, getQuestiondata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

            this.getExpeditionQuestion_List = res.data;
            for (let item of this.getExpeditionQuestion_List) {
              this.questions = item.question;
              this.questionOrder = item.question_order;
              this.collectResponse = item.collect_res_user;
              this.responsFromPrevious = item.display_res_previous_camp;
              // this.status = item.status; = 
              this.answerPlaceholder = item.answer_placeholder;
              this.view_Beliefs = item.view_beliefs;
              this.campName = item.camp_name
              this.previouscampSiteQuestion = item.camp_site_question;
              this.viewCampId = item.camp_id
              this.prevCampid = item.prvs_camp_id
              this.prevCampidTwo = item.prvs_camp_id_2;
              this.prevCampQuestionId = item.prvs_question_id;
              this.prevCampQuestionIdTwo = item.prvs_question_id_2;

              if (this.prevCampid != "" || this.prevCampid != undefined || this.prevCampid != null) {
                this.getCampSitePreviousQuestion();
              }

              if (this.prevCampidTwo != "" || this.prevCampidTwo != undefined || this.prevCampidTwo != null) {
                this.getCampSitePreviousQuestionTwo();
              }

            }
          }
        }
        this.responsePreviousCampSiteYes();
      });
  }
  responsePreviousCampSiteYes() {

    var campsiteYes = this.responsFromPrevious;
    if (campsiteYes == "1") {

      this.previousCampSite_Yes_Div = true;

    }
    else if (campsiteYes == "0") {
      this.previousCampSite_Yes_Div = false;
    }

  }


  async getCampSiteListApi() {

    var getapi = "/get_camp_site";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.campSiteList = res.data.reverse();
      this.getCampSitePreviousQuestion();
    });

  }

  async getCampSitePreviousQuestion() {
    var postapi = "/get_camp_question";


    var campsiteQuestiondata = {
      "camp_id": this.prevCampid
    };
   
    (await this.ApiService.postApi(postapi, campsiteQuestiondata)).subscribe(
      res => {
     if (res) {
          if (res.status === 200) {
            this.previousCampsiteList = (this.prevCampid) ? res.data : [];


          }
        }
      });
  }

  async getCampSitePreviousQuestionTwo() {
    var postapi = "/get_camp_question";

    var campsiteQuestiondata = {
      "camp_id": this.prevCampidTwo
    };
  
    (await this.ApiService.postApi(postapi, campsiteQuestiondata)).subscribe(
      res => {
    
        if (res) {
          if (res.status === 200) {

            this.previousCampsiteListTwo = (this.prevCampidTwo) ? res.data : [];
          }
        }
      });
  }




}
