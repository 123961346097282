<div id="wrapper" [ngClass]="status ? 'toggled' : 'danger'">
  <!-- <span>raja</span> -->

  <aside id="sidebar-wrapper">
    <div class="sidebar-brand">
      <span class="font_regular_gilroy fsize28"><img height="100px" width="100px" class="brainImg"
          src="assets/Web_logo.png" alt="" /></span>
    </div>
    <ul class="sidebar-nav font_regular_gilroy marg-t-28">
      <li>
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/home']" class="fsize16 marg-t-20">
          <img height="30px" width="30px" src="assets/Dashboardicon.png" alt="" style="margin-right: 6px" />
          Dashboard</a>
      </li>
      <!-- <li><a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/appointment']" class="fsize16">

          <img height="30px" width="30px" src="assets/Appointment_icon.png" style="margin-right: 5px;"> Appointment
          management</a></li> -->
      <!-- <li><a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/coach']" class="fsize16"> <img
            height="30px" width="30px" src="assets/Coach_icon.png" alt="" style="margin-right: 6px;"> Coach Management
        </a></li> -->
      <!-- -------------------------------------- -->

      <li>
        <div class="sidenav">
          <div class="row marg-0" style="width: 285px">
            <div>
              <img height="30px" width="30px" src="assets/Appointment_icon.png" style="margin-left: 16px" />
            </div>
            <div>
              <button class="dropdown-btn" style="
                  color: hsla(0, 0%, 100%, 0.5);
                  width: 140px;
                  font-size: 16px;
                ">
                Appointments
                <i class="fa fa-caret-down"></i>
              </button>

              <div class="dropdown-container">
                
                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/appointment']"
                  href="/workout-day-table" class="fsize16 yellow_hover">
                  All Appointments
                </a>

                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/upcomming_appointment']"
                  href="/sos-feel-table" class="fsize16 yellow_hover">
                  Upcoming Appointments</a>

              </div>
            </div>
          </div>
        </div>
      </li>

      <!-- ---------------------------------------------- -->
      <li>
        <div class="sidenav">
          <div class="row marg-0">
            <div>
              <img height="30px" width="30px" style="margin-left: 16px" src="assets/Content_icon.png" alt="" />
            </div>
            <div>
              <button class="dropdown-btn" style="
                  color: hsla(0, 0%, 100%, 0.5);
                  width: 198px;
                  font-size: 16px;
                ">
                Coach Management
                <i class="fa fa-caret-down"></i>
              </button>

              <div class="dropdown-container">
                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/coach']"
                  href="/workout-day-table" class="fsize16 yellow_hover">
                  Coach List</a>

                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/adminApprove']"
                  href="/sos-feel-table" class="fsize16 yellow_hover">
                  Coach Approvals</a>

                <!-- <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/slot-management-list']"
                  href="/workout-day-table" class="fsize16 yellow_hover">
                  Slot Management</a> -->
              </div>
            </div>
          </div>
        </div>
      </li>

      <li>
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/user-table']" class="fsize16">
          <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px" />
          Users</a>
      </li>
      <li>
        <div class="sidenav">
          <div class="row marg-0">
            <div>
              <img height="30px" width="30px" style="margin-left: 16px" src="assets/Content_icon.png" alt="" />
            </div>
            <div>
              <button class="dropdown-btn" style="
                  color: hsla(0, 0%, 100%, 0.5);
                  width: 198px;
                  font-size: 16px;
                ">
                Content Management
                <i class="fa fa-caret-down"></i>
              </button>

              <div class="dropdown-container">
                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/workout-day-table']"
                  href="/workout-day-table" class="fsize16 yellow_hover">
                  Happiness WorkOut</a>

                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/sos-feel-table']"
                  href="/sos-feel-table" class="fsize16 yellow_hover">
                  Sos: I Feel</a>

                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/expendition-table']"
                  href="/happiness_Expendition_table" class="fsize16 yellow_hover">
                  Happiness Expedition</a>
              </div>
            </div>
          </div>
        </div>

        <!-- <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/content-management']"
          href="/content-management" class="fsize16">
          <img height="30px" width="30px" src="assets/Content_icon.png"
            alt="">


            Content Management</a>

        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/happiness_Workout_table']"
          href="/happiness_Workout_table" class="fsize16 marg-l-32">
        i) Happiness WorkOut</a>

         <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/sos_feel_table']"
          href="/sos_feel_table" class="fsize16 marg-l-32">
       ii) Sos: I Feel</a>

        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/happiness_Expendition_table']"
        href="/happiness_Expendition_table" class="fsize16 marg-l-32">
    iii) Happiness Expendition</a> -->
      </li>

      <li>
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/payment']" class="fsize16">
          <img height="30px" width="30px" src="assets/Payment_icons.png" alt="" style="margin-right: 6px" />
          Payments</a>
      </li>

      <li>
        <!--
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/forum-management']"
          class="fsize16"> <img height="30px" width="30px" src="assets/Forum_icon.png" alt="" style="margin-right: 6px;" > Community Management </a> -->

        <div class="row marg-0">
          <div>
            <img height="30px" width="30px" src="assets/Forum_icon.png" alt="" style="margin-left: 16px" />
          </div>
          <div>
            <button class="dropdown-btn" style="color: hsla(0, 0%, 100%, 0.5); font-size: 16px">
              Community Management
              <i class="fa fa-caret-down"></i>
            </button>

            <div class="dropdown-container">
              <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/community-topic-table']"
                href="/community-topic-table" class="fsize16 yellow_hover">
                Topics
              </a>

              <a [routerLinkActive]="['active']" (click)="isShown = false"
                [routerLink]="['/community-moderation-table']" href="/community-moderation-table"
                class="fsize16 yellow_hover">
                Comments Moderation
              </a>

              <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/poster-moderation-table']"
                href="/poster-moderation-table" class="fsize16 yellow_hover">
                Posts Moderation
              </a>

              <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/community-events-list']"
                href="/community-events-list" class="fsize16 yellow_hover">
                Community Events
              </a>

              <!-- <a (click)="isShown = false" href="/community-events-list" [routerLink]="['/community-events-list']"
                routerLinkActive="router-link-active">
                Community Events
              </a> -->

              <!-- <a [routerLinkActive]="['active']" (click)="isShown = false"
                [routerLink]="['/expendition-table']" href="/happiness_Expendition_table"
                class="fsize16 yellow_hover">
                Happiness Expendition</a> -->
            </div>
          </div>
        </div>
      </li>

      <li>
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/coupon-Table']" class="fsize16">
          <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px" />
          Coupons</a>
      </li>
      <li>
        <!-- <li><a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/adminApprove']" class="fsize16">
          <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px;"> Approve
          Management</a></li>
      <li> -->
      </li>

      <!-- <li><a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/student-portal-table']" class="fsize16">
        <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px;">  College List
        </a></li> -->
      <!-- --------------College ManageMent----------------------------------------------- -->

      <li>
        <div class="sidenav">
          <div class="row marg-0">
            <div>
              <img height="30px" width="30px" style="margin-left: 16px" src="assets/Content_icon.png" alt="" />
            </div>
            <div>
              <button class="dropdown-btn" style="
                  color: hsla(0, 0%, 100%, 0.5);
                  width: 198px;
                  font-size: 16px;
                ">
                College Management
                <i class="fa fa-caret-down"></i>
              </button>

              <div class="dropdown-container">
                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/college-portal-list']"
                  href="/college-portal-list" class="fsize16 yellow_hover">
                  College Users
                </a>

                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/student-portal-table']"
                  href="/student-portal-table" class="fsize16 yellow_hover">
                  College List</a>

                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/approve_College']"
                  href="/sos-feel-table" class="fsize16 yellow_hover">
                  Approve User</a>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li>
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/blog_master_list']"
          class="fsize16">
          <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px" />
          Blog Management</a>
      </li>
      <li>
        <!-- -------------------------------------------------------------------- -->
        <div class="row marg-0">
          <div>
            <img height="30px" width="30px" src="assets/Forum_icon.png" alt="" style="margin-left: 16px" />
          </div>
          <div>
            <button class="dropdown-btn" style="color: hsla(0, 0%, 100%, 0.5); font-size: 16px">
              Personalized plan
              <i class="fa fa-caret-down"></i>
            </button>

            <div class="dropdown-container">
              <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/assestment_results']"
                class="fsize16">
                <img height="30px" width="30px" src="assets/User_icons.png" alt=""
                  style="margin-right: 6px" />Assessment Result
              </a>
              <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/assestment_question']"
                class="fsize16">
                <img height="30px" width="30px" src="assets/User_icons.png" alt=""
                  style="margin-right: 6px" />Assessment Question
              </a>
              <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/exercisesdatabank']"
                class="fsize16">
                <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px" />
                Exercise Databank
              </a>
              <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/nutrition_Databank']"
                class="fsize16">
                <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px" />
                Nutrition Databank
              </a>
            </div>
          </div>
        </div>

        <!-- <div class="row marg-0">
          <div>
            <img
              height="30px"
              width="30px"
              src="assets/Forum_icon.png"
              alt=""
              style="margin-left: 16px"
            />
          </div>
          <div>
            <button
              class="dropdown-btn"
              style="color: hsla(0, 0%, 100%, 0.5); font-size: 16px"
            >
              Assessment Management
              <i class="fa fa-caret-down"></i>
            </button>

            <div class="dropdown-container">
              <a
                [routerLinkActive]="['active']"
                (click)="isShown = false"
                [routerLink]="['/assestment_results']"
                class="fsize16"
              >
                <img
                  height="30px"
                  width="30px"
                  src="assets/User_icons.png"
                  alt=""
                  style="margin-right: 6px"
                />Assessment Result
              </a>
              <a
                [routerLinkActive]="['active']"
                (click)="isShown = false"
                [routerLink]="['/assestment_question']"
                class="fsize16"
              >
                <img
                  height="30px"
                  width="30px"
                  src="assets/User_icons.png"
                  alt=""
                  style="margin-right: 6px"
                />Assessment Question
              </a>
            </div>
          </div>
        </div> -->

        <div class="row marg-0">
          <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/tidbit_master']"
            class="fsize16">
            <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-left: 16px" />
            Tidbit Master
          </a>
        </div>
      </li>

      <li>
        <div class="sidenav">
          <div class="row marg-0">
            <div>
              <img height="30px" width="30px" style="margin-left: 16px" src="assets/Content_icon.png" alt="" />
            </div>
            <div>
              <button class="dropdown-btn" style="
                  color: hsla(0, 0%, 100%, 0.5);
                  width: 216px;
                  font-size: 16px;
                ">
                Corporate Management
                <i class="fa fa-caret-down"></i>
              </button>

              <div class="dropdown-container">
                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/corporate_List']"
                  href="/corporate_List" class="fsize16 yellow_hover">
                  Corporate List</a>

                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/corporate_Userlist']"
                  href="/corporate_Userlist" class="fsize16 yellow_hover">
                  Corporate User List</a>

                <!-- <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/corporate_Approvelist']"
                        href="/corporate_Approvelist" class="fsize16 yellow_hover">
                        Approve User</a> -->
              </div>
            </div>
          </div>
        </div>
      </li>

      <li>
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/packageManager']" class="fsize16">
          <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px" />
          Package Management</a>
      </li>


      <li>
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/subscription']" class="fsize16">
          <img height="30px" width="30px" src="assets/subscription.svg" alt="" style="margin-right: 6px" />
          Subscription</a>
      </li>


      <li>
        <div class="sidenav">
          <div class="row marg-0">
            <div>
              <img height="30px" width="30px" src="assets/Forum_icon.png" alt="" style="margin-left: 16px" />
            </div>
            <div>
              <button class="dropdown-btn" style="color: hsla(0, 0%, 100%, 0.5); font-size: 16px">
                Happiness Index Test
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-container">
                <a [routerLinkActive]="['active']" (click)="isShown = false"
                  [routerLink]="['/happiness-test-management']" class="fsize16">
                  <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px" />Test
                  Questions
                </a>
                <a [routerLinkActive]="['active']" (click)="isShown = false"
                  [routerLink]="['/happiness-test-category-result']" class="fsize16">
                  <img height="30px" width="30px" src="assets/User_icons.png" alt=""
                    style="margin-right: 6px" />Category-wise results
                </a>
                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/happiness-test-result']"
                  class="fsize16">
                  <img height="30px" width="30px" src="assets/User_icons.png" alt="" style="margin-right: 6px" />
                  Overall results
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>


      <li>
        <a (click)="isShown = false" href="/pre-assessment-list" [routerLink]="['/pre-assessment-list']"
          routerLinkActive="router-link-active">
          <img height="25px" width="25px" src="assets/pre-assessment.png" alt="" style="margin-right: 6px" />
          Pre Assessment
        </a>
      </li>

      <li>
        <a (click)="isShown = false" href="/rewardslist" [routerLink]="['/rewardslist']"
          routerLinkActive="router-link-active">
          <img height="25px" width="25px" src="assets/rewards.png" alt="" style="margin-right: 6px" />
          Rewards
        </a>
      </li>

      <li>

      </li>


      <li>
        <a (click)="isShown = false" href="/review-management" [routerLink]="['/review-management']"
          routerLinkActive="router-link-active">
          <img height="25px" width="30px" src="assets/review-management.png"
            style="margin-right: 6px; border-radius: 5px;">
          Review Management
        </a>
      </li>

      <li>
        <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/settings']" class="fsize16">
          <img height="30px" width="30px" src="assets/setting.svg" alt="" style="margin-right: 6px" />
          Settings</a>
      </li>

      <!-- -------------------------------------------------------- -->

    </ul>
  </aside>
  <div>
    <div id="navbar-wrapper">
      <nav class="navbar navbar-inverse">
        <div class="">
          <div class="navbar-header">
            <div class="row">
              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <a (click)="clickEvent()" style="margin-left: 28px" class="navbar-brand" id="sidebar-toggle"><i
                    class="fa fa-bars"></i></a>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <button type="button" class="btn heading_right log_Out_Btn" data-toggle="modal"
                  data-target="#exampleModalLogout">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div>
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div class="modal fade" id="exampleModalLogout" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="" style="padding: 25px">
            <span>Do you Want to Logout ?..</span>
            <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <button type="button" class="btn model_SaveBtn" (click)="logoutConfirm()">
              Confirm
            </button>
            <button type="button" class="btn model_CancelBtn marg-l-16" data-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section id="content-wrapper">
    <router-outlet></router-outlet>
  </section>
</div>