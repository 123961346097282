import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;
declare var $: any;
@Component({
  selector: 'app-tidbit-master-add',
  templateUrl: './tidbit-master-add.component.html',
  styleUrls: ['./tidbit-master-add.component.scss']
})
export class TidbitMasterAddComponent implements OnInit {
  isShown: boolean = false;
  tidbitBack_url = "/tidbit_master";
  // container: any[] = [];
  container: {
    base64textString: string,
    order_no: string,
  }[] = [{ base64textString: "", order_no: "" }];
  // newArray: {
  //   base64textString: string,
  //   order: string,
  // }[] = [{ base64textString: "", order: "" }];
  keyValue: any;
  type_Type: any;
  base64textString: any;
  index: number;
  getAllEmotion: any;
  emotionsId: any;
  is_active: any;
  registerForm: FormGroup;
  submitted = false;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.registerForm = this.fb.group(
      {
        emotions: ["", Validators.required],
        order: ["", Validators.required],
        // order:this.fb.array(["", Validators.required])

      },
    );
    this.getEmotionListApi();
  }

  blogCancelBtn() {
    this.router.navigate([this.tidbitBack_url]);
  }

  async submitAddTidbit() {

    // this.submitted = true;
    // if (this.registerForm.invalid) {
    //   return;
    // }

    const nums = this.container.map(o => o.order_no);
    var orderNum = [...(new Set(nums))].length

    if (orderNum != nums.length) {
      swal({
        title: "error !",
        text: " Order Numbers Should not be repeated ",
        type: "error",
      }, function () {
        alert('yo');
      });

    }
    else if (orderNum == nums.length) {
      // console.log([...(new Set(nums))].length != nums.length?" Has duplicate values":" Does not have duplicate values")
      var tidbit = this.container.map((item) => {
        if (item.order_no != undefined && item.order_no != '') {
          return { image: this.container[this.index].base64textString, order_no: item.order_no }
        }
      })

      console.log(tidbit, 'tidbit....');
      // ------------------------------------------------------------------------      


      var tidbitArr = false;
      for (var item of tidbit) {
        if (!item) {
          tidbitArr = true;
          break;
        }
        if (item.image != undefined || item.order_no == '') {
          tidbitArr = true;
          break;
        }
      }


      if (this.emotionsId != undefined && !tidbitArr) {

        var postapi = "/add_and_update_tidbit ";
        var exercisesAdd = {
          "emotion_id": this.emotionsId,
          "tidbit": tidbit,
          "isactive": this.is_active
        };
        console.log(exercisesAdd, 'exercisesAdd');

        (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
          res => {
            console.log(res);

            if (res.status === 200) {
              swal({
                title: "Success!",
                text: res.message,
                type: "success",
              }, function () {
                alert('yo');
              });
              this.router.navigate([this.tidbitBack_url]);
            }
            else if (res.status === 400) {

              swal({
                title: "error!",
                text: res.message,
                type: "error",
              }, function () {
                alert('yo');
              });

            }

          },


          (error) => {

            var errMsg = '';
            console.log()
            for (var i in error.error.message) {
              errMsg += error.error.message[i] + '<br />';
            }
            swal({
              title: "error!",
              text: errMsg,
              type: "error",
            }, function () {
              alert('yo');
            });



          }


        )



      }
      else {

        swal({
          title: "error!",
          text: "Please Fill The All Details",
          type: "error",
        }, function () {
          alert('yo');
        });

      }

      // ---------------------------------------------------------------------------------

    }


  }

  // (change)="orderInputFun(i)"

  inputOrderVal(i) {
    console.log($("#questions[i]").val());

  }


  orderInputFun(i) {
    console.log(this.container[i].order_no, 'array of i');
    var raja = this.container.map(o => o.order_no).includes(this.container[i].order_no);
    console.log(raja, 'raja');

  }


  async addBenefits() {

    this.keyValue = this.container.length + 1;
    this.container.push({ base64textString: "", order_no: "" });

    // this.container.push(this.keyValue);
    var allTitle = this.container.map((item) => {
      if (item.base64textString != undefined && item.base64textString != '') {
        return { image: this.container[this.index].base64textString, order_no: item.order_no }
      }
    })
    // console.log(allTitle, 'alltitle');

  }

  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }
  }


  async handleFileSelectOne(evt, item: any, index) {

    // console.log(this.container,'rrr',index);

    var files = evt.target.files;

    var file = files[0];
    this.type_Type = file.type;

    // this.container[index].base64textString= this.type_Type;

    if (files && file) {
      var reader = new FileReader();
      this.index = index
      reader.onload = await this._handleReaderLoadedOne.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoadedOne(readerEvt) {
    console.log(this.index, 'indexxx');

    var binaryString = readerEvt.target.result;
    this.container[this.index].base64textString = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
    console.log(this.container[this.index].base64textString, 'base');

  }

  // --------------------------------------

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res.data);
      this.getAllEmotion = res.data
    });
  }

  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;
    console.log(this.emotionsId, 'this.emotionsId...');
  }


}
