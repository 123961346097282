import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
@Component({
  selector: 'app-community-moderation-table',
  templateUrl: './community-moderation-table.component.html',
  styleUrls: ['./community-moderation-table.component.scss']
})
export class CommunityModerationTableComponent implements OnInit {
  isShown: boolean = false;
  getModerationList = [];
  deleteId:any;
  deleteUserid:any;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    this.getcommunityModerationApi();
  }

  async getcommunityModerationApi() {
 var getapi = "/community_moderation_v2";
  (await this.ApiService.getApi(getapi)).subscribe((res) => {
    if(res.status == 200) {
      this.getModerationList = res.data.reverse();
      
    }
    else {
      this.getModerationList = [];
    }
   
      
       });
 }


 deletedItem(deleteitems:any){
  this.deleteId = deleteitems.comment_id;
  console.log('=====>',this.deleteId);
  
 

}
 async deleteCommunity(){
  var postapi = '/delete_community_moderation_v2';
  var communityDeleteId = {
    "comment_id" : this.deleteId

  };
  // console.log(communityDeleteId);
  
  // return
  (await this.ApiService.postApi(postapi,communityDeleteId)).subscribe(
    res => {
      if(res.status == 200) {
        this.getcommunityModerationApi();
        

      }
    
    }
  )
 }










//  async getcommunityDeleteApi() {
//   var postapi = "/delete_community_moderation";
//   var communityFeeddata = {
//     "id": this.deleteId ,
//     "user_id" : this.deleteUserid
//   };
//   (await this.ApiService.postApi(postapi, communityFeeddata)).subscribe(
//     res => {

//       if (res.status === 200) {
//         swal({
//           title: "Success!",
//           text: " Community Moderation Deleted Sucessfully ",
//           type: "success"
//         }, function () {
//           alert('yo');
//         });

//       }
//       this.getcommunityModerationApi();
//     });
// }






}
