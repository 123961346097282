<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/nutrition_Databank']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Nutrition Databank View</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Emotion *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Range *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" />

                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                        <!-- <input type="text" class="form-control input_bdr_clr font_text" maxlength="150" /> -->

                        <ckeditor 
                        name="myckeditor"
                     
                       
                        debounce="500" 
                >
              </ckeditor>  
                    </div>

                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>