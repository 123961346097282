import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nutrition-databank',
  templateUrl: './nutrition-databank.component.html',
  styleUrls: ['./nutrition-databank.component.scss']
})
export class NutritionDatabankComponent implements OnInit {

  nutritionAdd_url = "/nutrition_Add_Databank";
  nutritionEdit_url = "/nutrition_Edit_Databank";
  nutritionView_url = "/nutrition_View_Databank";
  nutritionList = [];
  deleteNutritionId: any;
  showLoaderDiv = false;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.getlist_nutrition();
  }

  nutritionAddDatabank() {
    this.router.navigate([this.nutritionAdd_url]);
  }

  nutritionEditDatabank(item:any) {
    this.ApiService.nutritionEditId = item.id ;
    localStorage.setItem("nutritionId",item.id )
    this.router.navigate([this.nutritionEdit_url]);
  }
  nutritionViewDatabank() {
    this.router.navigate([this.nutritionView_url]);
  }

  async getlist_nutrition() {
    this.showLoaderDiv = true;
    var getapi = "/list_nutrition";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      
      if (res.status === 200) {
        this.nutritionList = res.result;
        this.showLoaderDiv = false;
      }
    });

  }
  // Delete Api

  deleteItems(items: any) {
    this.deleteNutritionId = items.id;
  }
  async nutritionDelete() {
    var deleteApi = "/delete_nutrition/" + this.deleteNutritionId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      if (res.status === 200) {
        this.getlist_nutrition();
      }
    });
  }



}
