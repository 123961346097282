<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/assestment_question']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Assessment Question Edit</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                <div class="">
                    <form>
                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy"> Emotion*</label>
                            <select disabled (change)="emotionItem($event)" style="width:400px;"
                                class=" form-control input_bdr_clr ">
                                <!-- <option [disabled]="true" [selected]="true">Select an Emotion</option> -->
                                <option [selected]="emotionsId == item.themotion_id" [value]="[item.themotion_id]"
                                    *ngFor="let item of getAllEmotion">
                                    {{item.themotion_name}} </option>
                            </select>
                        </div>

                        <div>
                            <table class="row-border hover table w-100" datatable>
                                <thead>
                                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                        <th>S.No</th>
                                        <th>Order No</th>
                                        <th style="width:300px;">Question</th>
                                        <th>Never</th>
                                        <th>Almost Never</th>
                                        <th>Sometimes</th>
                                        <th>Fairly Often</th>
                                        <th>very Often</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="font_medium_gilroy" *ngFor="let item of container; let i = index">
                                        <td *ngIf="!item.delete">{{i+1}}</td>

                                        <td *ngIf="!item.delete">
                                            <input type="text" maxlength="10" [(ngModel)]="item.order_no"
                                                [ngModelOptions]="{standalone: true}" style="width:50px;">
                                        </td>

                                        <td *ngIf="!item.delete" class="para_line">
                                            <input type="text" maxlength="1000" [(ngModel)]="item.question"
                                                [ngModelOptions]="{standalone: true}" style="width:260px;">
                                        </td>
                                        <td *ngIf="!item.delete" class="para_line">
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)"
                                                type="text" maxlength="1" [(ngModel)]="item.never"
                                                [ngModelOptions]="{standalone: true}">
                                        </td>
                                        <td *ngIf="!item.delete" class="para_line">
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)"
                                                type="text" maxlength="1" [(ngModel)]=" item.almost_never"
                                                [ngModelOptions]="{standalone: true}">
                                        </td>
                                        <td *ngIf="!item.delete" class="para_line">
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)"
                                                type="text" maxlength="1" [(ngModel)]="item.sometimes"
                                                [ngModelOptions]="{standalone: true}">
                                        </td>
                                        <td *ngIf="!item.delete" class="para_line">
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)"
                                                type="text" maxlength="1" [(ngModel)]="item.fairly_often"
                                                [ngModelOptions]="{standalone: true}">
                                        </td>
                                        <td *ngIf="!item.delete" class="para_line">
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)"
                                                type="text" maxlength="1" [(ngModel)]="item.very_often "
                                                [ngModelOptions]="{standalone: true}">
                                        </td>

                                        <td *ngIf="!item.delete">

                                            <span class="marg-l-10 cursor_pointer" data-toggle="modal"
                                                data-target="#exampleModalDelete" (click)="assestmentEditDelete(item)">
                                                <button type="button" class="border_clr_none cursor_pointer"> <img
                                                        height="22px" width="22px" class="bgr_black"
                                                        src="assets/Delete_icon.png" alt=""> </button>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- ------------------------------------------- -->
                        <div class="modal fade" id="exampleModalDelete" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">

                                    <div class="modal-body">

                                        <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                                        <div>
                                            Do You Want to Delete ?
                                        </div>

                                    </div>
                                    <div class=" row justify-content-center  font_bold_gilroy"
                                        style="margin-top:20px; margin-bottom:20px;">
                                        <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                            (click)="delete_submit()">Submit</button>
                                        <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>

                                <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                    class="btn btn-warning">remove</button>
                            </div>
                        </div>


                        <div class="form-group" style="margin-top:100px;">
                            <button  (click)="assestmentUpdateSubmit()"
                                class="btn  submit_btn_clr">Submit</button>
                            <button type="button" (click)="assessmentBackUrl()"
                                class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                        </div>
                    </form>
                </div>


                <div>

                </div>


            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
        </div>





    </div>

</div>