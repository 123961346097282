<div class="padd-0-25">
  <div *ngIf="showLoaderDiv">
    <div id="pause" class="d-flex align-items-center justify-content-center">
      <div id="spinner"></div>
    </div>
  </div>



  <div class="marg-t-70">
    <div class="row">
      <div class="col-6">
        <div class="fsize27 font_bold_gilroy">
          Subscription
        </div>
      </div>
      <div class="col-6">
        <div style="float: right;">
          <button class="add-bttn" (click)="add_subscription()">Add</button>
        </div>
      </div>
    </div>
    <div class="col-lg-12" style="margin-top:40px;">
      <div class="" style="background-color: #fff; border-radius: 8px;">

        <table id="myTable" datatable class="row-border hover table w-100" *ngIf="subcriptionList.length > 0">
          <thead>
            <tr class="font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
              <th>S.No</th>
              <th>Name</th>
              <th>Description</th>
              <th>Duration</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of subcriptionList; let i = index" class="font_medium_gilroy">
              <td>{{ i + 1 }}</td>
              <td>{{ data.subscription_name }}</td>
              <td>{{ data.subscription_description }}</td>
              <td>{{ data.subscription_duration }}</td>
              <!-- <td>{{ data.access_modules }}</td> -->
              <td>{{ data.isactive === 1 ? 'Active':'Inactive'}}</td>
              <td>
                <span class="marg-0-12 cursor_pointer" (click)="edit_subscription(data.id)">
                  <img height="22px" width="22px" class="bgr_black" src="assets/Edit_icon.png" alt="">
                </span>
                <span class="marg-l-10 cursor_pointer"> <button type="button" style="outline: none; border: none; "
                    class="border_clr_none cursor_pointer" data-toggle="modal" data-target="#exampleModal"
                    (click)="SubscriptionId(data.id)"> <img height="22px" width="22px"
                      style="outline: none; border: none; " class="bgr_black" src="assets/Delete_icon.png" alt="">
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                <div>
                  Do you want to delete ? </div>
              </div>
              <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                  (click)="deleteSubscription()">Confirm</button>
                <button type="button" data-dismiss="modal"
                  class="btn_submit_cancel marg-l-10 bgr_clr_blue">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>