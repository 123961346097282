import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-assestment-questionlist',
  templateUrl: './assestment-questionlist.component.html',
  styleUrls: ['./assestment-questionlist.component.scss']
})
export class AssestmentQuestionlistComponent implements OnInit {
  assestmentQuestionList:any;
  deleteAssestmentId:any;
  assestmentAdd_url = "/assestment_question_add";
  assestmentEdit_url = "/assestment_question_edit";
  showLoaderDiv = false;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    this.getlist_assestmentApi();
  }

  async getlist_assestmentApi() {
    this.showLoaderDiv = true;
    var getapi = "/list_assessment";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
  
      if (res.status === 200) {
        this.assestmentQuestionList = res.result;
        this.showLoaderDiv = false;
      }
    });
  
  }

  assestmentQuestionAdd(){
    this.router.navigate([this.assestmentAdd_url]); 
  }
  assestmentQuestionEdit(item:any){
    this.ApiService.assestment_questionEditId = item.id;
    localStorage.setItem("assestmentQuestionEditIds",item.id)
    this.router.navigate([this.assestmentEdit_url]); 
  }

  deleteAssestmentquestion(items:any){
    this.deleteAssestmentId = items.id
  }
async  assestmentDelete(){
  var deleteApi = "/delete_assessment/" + this.deleteAssestmentId;
  (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
    if (res.status === 200) {
this.getlist_assestmentApi();

    }
  });
}


// ---------------------------------------






}
