<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/expendition-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Expedition Module  Add </li>
        </ol>
    </nav>
    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>
                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">Module *</label>
                        <select [(ngModel)]="module" [ngModelOptions]="{standalone: true}"
                            class=" form-control input_bdr_clr "   
                            (ngModelChange)="moduleItem($event)"
                            >
                            <option [value]="item.id" *ngFor="let item of moduleTitleList ">{{item.title}}</option>
                        </select>
                    </div>
          

         <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr" >Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>

</div>