import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare const $: any;
declare var swal: any;
@Component({
  selector: 'app-question-workout-edit',
  templateUrl: './question-workout-edit.component.html',
  styleUrls: ['./question-workout-edit.component.scss']
})
export class QuestionWorkoutEditComponent implements OnInit {

  questionEdit = "";
  questiontypeEdit: any;
  placeholder_Text = "";
  positionEdit = "";
  isShown: boolean = false;
  questionType_Text_question = false;
  type_Text_placeholder = false;
  showFeelingType_Text = false;
  position_questionType_Text = false;
  question_EditSubmit_url = "/workout-edit";
  workout_questionIds : any;
  questionworkoutAdd_ids :any;
  show_FeelingRadioBtn:any;

  constructor(private ApiService: ApiService, private router: Router) { }
  async ngOnInit() {

    this.workout_questionIds = this.ApiService.workoutQuestion_id;
    this.questionworkoutAdd_ids  = this.ApiService.workout_day_ids;  
 

    // this.questionworkoutAdd_ids = this.ApiService.workoutAdd_id;
   
    // ------------------------------------------------
    // this.workoutAdd_ids = this.ApiService.workoutAdd_id;

    // if (this.workout_questionIds != "") {
    //   var workout_Dayids = this.workout_questionIds;
    // }
    // else if (this.questionworkoutAdd_ids != "") {
    //   var workout_Dayids = this.questionworkoutAdd_ids;
    // }


    var id_edit = localStorage.getItem('questionEditId');
    var idworkout = localStorage.getItem('inserkeyId');
    var getapi = "/workout/get_questions?" + "day_id=" + this.questionworkoutAdd_ids  + "&type=question"+"&id="+this.workout_questionIds ;
    // var getapi = "/workout/get_questions?day_id=1&type=question";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var myArray = res.data;
      for (var i = myArray.length; i--;) {
        var item = myArray[i];
        var ids = item.id
        if (ids == this.workout_questionIds) {
          this.questionEdit = item.question;
          this.questiontypeEdit = item.question_type;
          this.placeholder_Text = item.placeholder;
          this.positionEdit = item.position;
         var showFeeling = item.show_feelings;
        
          if(showFeeling == 1){
            this.show_FeelingRadioBtn = "1"
          }
          else if(showFeeling == 0){
            this.show_FeelingRadioBtn = "0"
          }
        }
      }
      this.questionType_One();
    });

  }

  questionType_One() {
    var questionTypeOneValue = this.questiontypeEdit;
    if (questionTypeOneValue == 'text') {
      this.questionType_Text_question = true;
      this.type_Text_placeholder = true;
      this.showFeelingType_Text = true;
      this.position_questionType_Text = true;
    }
    if (questionTypeOneValue == 'minddump') {
      this.questionType_Text_question = true;
      this.type_Text_placeholder = false;
      this.showFeelingType_Text = false;
      this.position_questionType_Text = true;
    }

  }

  async questionSubmit() {
    var id_edit = JSON.parse(localStorage.getItem('questionEditId'));
    var idworkout = localStorage.getItem('inserkeyId');
    var putApi = "/workout/update_questions/" + this.workout_questionIds;
    var workout_Day = $("#workoutDay").val();
    var workout_Level = $("#workoutLevel").val();
    var workout_Range = $("#workoutRange").val();
    var no_Of_Question = $("#noOfQuestion").val();

    var workOutEditdata = {
      "question": this.questionEdit,
      "workout_day_id": this.questionworkoutAdd_ids,
      "placeholder": this.placeholder_Text,
      "position": this.positionEdit,
      // "answer_label": "sample answer label",
      "question_type": this.questiontypeEdit,
      "type": "question",
      "is_active": 1,
      "show_feelings": this.show_FeelingRadioBtn
    };

   

    (await this.ApiService.putApi(putApi, workOutEditdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

            swal({
              title: "Success!",
              text: "Workout Question Update Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.question_EditSubmit_url]);
          }
        }
      });
  }

}
