import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-student-portal',
  templateUrl: './student-portal.component.html',
  styleUrls: ['./student-portal.component.scss']
})
export class StudentPortalComponent implements OnInit {
  datas : any;
  studentdeleteId:any;
  studentPortalEdit_url ="/student-portal-Edit";
  studentPortalView_url = "/student-portal-view";
  studentPortalAdd_url = "/student-portal-add";
  showLoaderDiv = false;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(){
    this.getCollegeListApi();
  }

  async getCollegeListApi(){

    this.showLoaderDiv = true;    
    var getapi = "/college_list";
  
    var table;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
   this.datas = res.data;
   this.showLoaderDiv = false;  
   }
  );
  
   }
 studentPortalEdit(etitItem:any){
    this.ApiService.adminStudent_id = etitItem.id ;
    localStorage.setItem("studentEditIds",etitItem.id)
    this.router.navigate([this.studentPortalEdit_url]);
   }

   studentPortalview(viewItem:any){
    this.ApiService.adminStudent_viewid = viewItem.id ;
   this.router.navigate([this.studentPortalView_url]);
  }

  studentPortalAdd(){
    this.router.navigate([this.studentPortalAdd_url]);
  }
  studentDeleteId(deleteItem:any){
  this.studentdeleteId= deleteItem.id ;
  }
 async studentDeleteApi(){

  var postapi = "/delete_college";
      var deleteIddata = {
        "id":  this.studentdeleteId
      
      };
    
    (await this.ApiService.postApi(postapi, deleteIddata)).subscribe(
          res => {
       
     if (res) {
     if (res.status === 200) {
      }  
      else if (res.status === 401) {
    
                }
    
            }
            
          this.getCollegeListApi();
          });

  
  }




}
