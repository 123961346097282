import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-question-workout-view',
  templateUrl: './question-workout-view.component.html',
  styleUrls: ['./question-workout-view.component.scss']
})
export class QuestionWorkoutViewComponent implements OnInit {

  questionview="";
  questiontypeView = "";
  placeholder_Text = "";
  positionView = "";
  questionViewId : any;
  questionworkoutAdd_ids:any;
  isShown:boolean = false;
  show_FeelingRadioBtn:any;
  constructor(private ApiService: ApiService) { }

  async ngOnInit(){
  var id_view = localStorage.getItem('questionViewId');
  var idworkout = localStorage.getItem('inserkeyId');
  this.questionworkoutAdd_ids  = this.ApiService.workout_day_ids;  
  
this.questionViewId =this.ApiService.workoutQuestionView_id;

    // var getapi = "/workout/get_questions?day_id=1&type=question";
    var getapi = "/workout/get_questions?" + "day_id=" + this.questionworkoutAdd_ids + "&type=question";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var myArray = res.data;
      for (var i = myArray.length; i--;) {
        var item = myArray[i];
        var ids = item.id
        if (ids == this.questionViewId) {
          this.questionview = item.question;
          this.questiontypeView = item.question_type;
          this.placeholder_Text = item.placeholder;
          this.positionView = item.position;
          var showFeeling = item.show_feelings;
        
          if(showFeeling == 1){
            this.show_FeelingRadioBtn = "1"
          }
          else if(showFeeling == 0){
            this.show_FeelingRadioBtn = "0"
          }

        }
      }

    });

  }

}
