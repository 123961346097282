<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/rewardslist']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" style="cursor: pointer;" aria-current="page">Add Rewards</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form [formGroup]="registerForm">

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward Type *</label>
                        <select (change)="rewarditem($event)" class=" form-control input_bdr_clr "
                            formControlName="rewardType">
                            <option [disabled]="true" [selected]="true">Select any type</option>
                            <option [value]="[item.id]" *ngFor="let item of getrewardlist">
                                {{item.category_name}} </option>
                        </select>
                        <div *ngIf="submitted && registerForm.get('rewardType').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('rewardType').errors.required">Reward type is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward Name *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [value]="reward_name"
                            [(ngModel)]="reward_name" formControlName="rewardName" maxlength="50" />

                        <div *ngIf="submitted && registerForm.get('rewardName').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('rewardName').errors.required">Reward is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward Description *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="reward_description"
                            formControlName="rewardDescrip" maxlength="100" />

                        <div *ngIf="submitted && registerForm.get('rewardDescrip').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('rewardDescrip').errors.required">Reward description is
                                required
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="selectedRewardId === '3'">
                        <label class="fsize16 clr_black font_bold_gilroy">External Link *</label>
                        <input type="url" class="form-control input_bdr_clr font_text" [(ngModel)]="external_link"
                            formControlName="externalLink" maxlength="100" />
                        <div
                            *ngIf="(submitted || registerForm.get('externalLink').touched) && registerForm.get('externalLink').errors">
                            <div class="alert alert-danger">
                                <span *ngIf="registerForm.get('externalLink').errors.required">
                                    External Link is required.
                                </span>
                                <span *ngIf="registerForm.get('externalLink').errors.invalidUrl">
                                    Please enter a valid URL.
                                </span>
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Happiness points *</label>
                        <input type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            maxlength="6" class="form-control input_bdr_clr font_text" [(ngModel)]="happinesspoints"
                            formControlName="happinessPoints" />

                        <div *ngIf="submitted && registerForm.get('happinessPoints').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('happinessPoints').errors.required">Happiness points is
                                required
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="selectedRewardId === '1' || selectedRewardId === '3'">
                        <label class="fsize16 clr_black font_bold_gilroy">Reedem Code</label>
                        <input type="text" oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '');"
                            class="form-control input_bdr_clr font_text" [(ngModel)]="redeem_code"
                            formControlName="reedemCode" maxlength="10" />

                        <!-- <div *ngIf="submitted && registerForm.get('reedemCode').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('reedemCode').errors.required">Reedem Code is required
                            </div>
                        </div> -->
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Active from *</label>
                        <input type="date" class="form-control input_bdr_clr font_text" [(ngModel)]="fromdate"
                            formControlName="fromdate_timeRequired" [min]="today" />

                        <div class="time-picker" style="margin-top: 10px;">
                            <select style="height: 30px; width: 50px;" [(ngModel)]="selectedHour1"
                                formControlName="fromdate_timeRequired" (change)="openTimePicker1()">
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;" [(ngModel)]="selectedMinute1"
                                formControlName="fromdate_timeRequired" (change)="openTimePicker1()">
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;"
                                formControlName="fromdate_timeRequired" [(ngModel)]="selectedPeriod1"
                                (change)="periodAm($event)">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>

                        <div *ngIf="submitted && registerForm.get('fromdate_timeRequired').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('fromdate_timeRequired').errors.required">Valid From Date &
                                time is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">valid upto *</label>
                        <input type="date" class="form-control input_bdr_clr font_text" [(ngModel)]="todate"
                            formControlName="todate_timeRequired" [min]="today" />

                        <div class="time-picker" style="margin-top: 10px;">

                            <select style="height: 30px; width: 50px;" [(ngModel)]="selectedHour2"
                                formControlName="todate_timeRequired" (change)="openTimePicker2()">
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;" [(ngModel)]="selectedMinute2"
                                formControlName="todate_timeRequired" (change)="openTimePicker2()">
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;"
                                formControlName="todate_timeRequired" [(ngModel)]="selectedPeriod2"
                                (change)="periodPm($event)">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>

                        </div>
                        <div *ngIf="submitted && registerForm.get('todate_timeRequired').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('todate_timeRequired').errors.required">Valid To Date & time is
                                required</div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Status</label>
                        <div>
                            <span>
                                <input type="radio" id="active" [(ngModel)]="isactive"
                                    [ngModelOptions]="{standalone: true}" name="status" value="1"
                                    [checked]="isactive === '1'">
                                <label for="active" class="marg-l-8"> Active </label>
                            </span>

                            <span>
                                <input class="marg-l-8" type="radio" id="inactive" [(ngModel)]="isactive"
                                    [ngModelOptions]="{standalone: true}" name="inactive" value="2">
                                <label class="marg-l-8"> Inactive</label>
                            </span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward File *</label>
                        <div>
                            <input type="file" class="file-upload" accept=".png, .jpg, .jpeg"
                                (change)="fileupload($event)" formControlName="fileMandatory" />

                        </div>

                        <div *ngIf="submitted && registerForm.get('fileMandatory').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('fileMandatory').errors.required">Reward File is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" style="cursor: pointer;" class="btn  submit_btn_clr"
                            (click)="submitAddrewards()">Save</button>
                        <button type="button" style="cursor: pointer;" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="cancelAddrewards()">Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>