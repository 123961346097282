import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-workout-table',
  templateUrl: './workout-table.component.html',
  styleUrls: ['./workout-table.component.scss']
})
export class WorkoutTableComponent implements OnInit {

  constructor(private router: Router) { }
  availabilty_url = '/workout-edit-table';
   
  ngOnInit(): void {
  }

  edit_table(){
    this.router.navigate([this.availabilty_url]);
  }


}
