
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { AbstractControl, ValidatorFn } from '@angular/forms';
declare var swal: any;
import { FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
@Component({
  selector: 'app-addrewards',
  templateUrl: './addrewards.component.html',
  styleUrls: ['./addrewards.component.scss']
})
export class AddrewardsComponent implements OnInit {


  showTimePicker1: boolean = false;
  selectedHour1: number;
  selectedMinute1: number;
  selectedPeriod1: string;

  showTimePicker2: boolean = false;
  selectedHour2: number;
  selectedMinute2: number;
  selectedPeriod2: string;

  hours: number[] = Array.from(Array(12).keys()).map(hour => hour + 1); // Generate array [1, 2, ..., 12]
  minutes: number[] = Array.from(Array(60).keys());

  selectedRewardId: any;
  isShown: boolean = false;
  getrewardlist: any[] = [];
  cancelrewards_url = "/rewardslist";
  reward_name: string = "";
  reward_description: any;
  external_link: string = "";
  happinesspoints: any;
  redeem_code: any = "";
  isactive = '1';
  fromdate: any;
  todate: any;
  startTime: any;
  endTime: any;

  periodRequiredAm: any;
  periodRequiredPm: any

  today: string;

  file: any;
  registerForm: FormGroup;
  submitted = false;

  reward_image: string | undefined;
  fileToUpload: File | null = null;


  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder, private http: HttpClient) {


    const current = new Date();
    this.today = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current.getDate().toString().padStart(2, '0')}`;


  }

  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        rewardName: ["", Validators.required],
        rewardType: ["", Validators.required],
        rewardDescrip: ["", Validators.required],
        fileMandatory: ["", Validators.required],
        externalLink: ['', [Validators.required, this.urlValidator()]],
        // dateRequired: ["", Validators.required],
        fromdate_timeRequired: ["", Validators.required],
        todate_timeRequired: ["", Validators.required],
        happinessPoints: ["", Validators.required],
        reedemCode: [""]
        // 
      },
    );
    this.getlistrewardscategory()
  }


  // =============================================================================


  urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      const isValid = urlRegex.test(control.value);
      return isValid ? null : { 'invalidUrl': { value: control.value } };
    };
  }

  // ================================================================================

  periodAm(event: any) {
    this.periodRequiredAm = event.target.value
    console.log(this.periodRequiredAm)
  }


  // ================================================================================

  periodPm(event: any) {
    this.periodRequiredPm = event.target.value
    console.log(this.periodRequiredPm)
  }

  // ================================================================================

  openTimePicker1() {
    this.showTimePicker1 = true;
    this.startTime = this.selectedHour1 + ':' + this.selectedMinute1;
    this.periodRequiredAm = this.selectedPeriod1

    console.log(this.periodRequiredAm)
    console.log(" this.startTime  this.startTime ", this.startTime)
  }

  // ===============================================================================

  openTimePicker2() {
    this.showTimePicker2 = true;
    this.endTime = this.selectedHour2 + ':' + this.selectedMinute2;
    this.periodRequiredPm = this.selectedPeriod2
    console.log(this.periodRequiredPm)

    console.log("this.endTimethis.endTimethis.endTime", this.endTime)

  }

  // ===============================================================================

  cancelAddrewards() {
    this.router.navigate([this.cancelrewards_url]);
  }

  // =============================================================================


  async getlistrewardscategory() {
    var getapi = "/listrewardcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getrewardlist = res.result
      console.log("category list", this.getrewardlist)
    })
  }


  // ==============================================================================

  rewarditem(event: any) {
    this.selectedRewardId = event.target.value;
    const selectedItem = this.getrewardlist.find(item => item.id == this.selectedRewardId); // First declaration
    console.log("selectedId", this.selectedRewardId, "selectedItem", selectedItem)

    if (selectedItem) {
      if (selectedItem.id == 3) {
        this.reward_name = '';
      } else {
        this.reward_name = selectedItem.category_name;
      }
    }

    if (event.target.value == "3") {
      this.registerForm.addControl("externalLink", new FormControl("", Validators.required))
    } else {
      this.registerForm.removeControl("externalLink")
    }
    // if (event.target.value == "3") {
    //   this.registerForm.addControl("rewardName", new FormControl("", Validators.required))
    // } else {
    //   this.registerForm.removeControl("rewardName")
    // }
  }

  // ================================================================================

  // fileupload(event: any) {
  //   this.fileToUpload = event.target.files[0]
  //   console.log("event", this.fileToUpload)
  // }

  fileupload(event: any) {
    const files = event.target.files;
    const maxSize = 1 * 1024 * 1024;

    if (files.length > 0) {
      this.fileToUpload = files[0];
      if (this.fileToUpload.size > maxSize) {
        swal({
          title: 'Error!',
          text: '1MB is only allowed to upload.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
      // Optionally, if you want to display the image preview:
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.reward_image = e.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
    }
    console.log("file selected", files)
  }


  // ================================================================================


  // async submitAddrewards() {
  //   this.submitted = true;

  //   if (this.registerForm.invalid) {
  //     return;
  //   }

  //   // if (!this.file) {
  //   //   console.error("No file selected.");
  //   //   return;
  //   // }

  //   var formdata = new FormData();

  //   const fromDate = moment(this.fromdate).format('YYYY/M/D');
  //   const toDate = moment(this.todate).format('YYYY/M/D');

  //   if (moment(this.fromdate).isAfter(this.todate)) {
  //     // Start date is after end date
  //     console.log('Start date must be before end date');
  //     swal({
  //       title: 'Error!',
  //       text: 'From Date Must be Before To Date',
  //       type: 'error',
  //     });
  //     return; // Exit the function
  //   } else {


  //     const startTimeformate = moment(this.startTime, 'H:mm').format('HH:mm')
  //     const startTime12Hour = `${startTimeformate} ${this.periodRequiredAm}`;

  //     const endTimeformate = moment(this.endTime, 'H:mm').format('HH:mm')
  //     const endTime12Hours = `${endTimeformate} ${this.periodRequiredPm}`;

  //     const startTime24Hour = moment(startTime12Hour, 'h:mm A').format('HH:mm');
  //     const endTime24Hour = moment(endTime12Hours, 'h:mm A').format('HH:mm');


  //     formdata.append('reward_category_id', this.selectedRewardId)
  //     formdata.append('reward_name', this.reward_name);
  //     formdata.append('reward_description', this.reward_description);
  //     formdata.append('external_link', this.external_link);
  //     formdata.append('points', this.happinesspoints);
  //     formdata.append('redeem_code', this.redeem_code);
  //     formdata.append('isactive', this.isactive === '1' ? '1' : '0');
  //     formdata.append('valid_from_date', fromDate);
  //     formdata.append('valid_to_date', toDate);
  //     formdata.append('valid_from_time', startTime24Hour);
  //     formdata.append('valid_to_time', endTime24Hour)
  //     formdata.append('file', this.fileToUpload);

  //     console.log("formdata", formdata)

  //     var postapi = "/addAndUpdatereward";
  //     (await this.ApiService.postApi(postapi, formdata)).subscribe(
  //       res => {
  //         if (res.status === 200) {
  //           swal({
  //             title: "Success!",
  //             text: "Reward Added successfully",
  //             type: "success",
  //           }, function () {
  //             alert('yo');
  //           });
  //           this.router.navigate([this.cancelrewards_url]);
  //           console.log("Data added successfully", res);
  //         }
  //       });

  //     (error) => {
  //       var errMsg = '';
  //       if (typeof error.error.message === 'object') {
  //         for (var i in error.error.message) {
  //           errMsg += error.error.message[i] + '<br />';
  //         }
  //       } else {
  //         errMsg = error.error.message
  //       }
  //       swal({
  //         title: "error!",
  //         text: errMsg,
  //         type: "error",
  //       }, function () {
  //         alert('yo');
  //       });

  //     }
  //   }
  // }

  async submitAddrewards() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var formdata = new FormData();

    const fromDate = moment(this.fromdate).format('YYYY/M/D');
    const toDate = moment(this.todate).format('YYYY/M/D');

    if (moment(this.fromdate).isAfter(this.todate)) {
      console.log('Start date must be before end date');
      swal({
        title: 'Error!',
        text: 'From Date Must be Before To Date',
        type: 'error',
      });
      return; // Exit the function
    } else {
      const startTimeformate = moment(this.startTime, 'H:mm').format('HH:mm');
      const startTime12Hour = `${startTimeformate} ${this.periodRequiredAm}`;

      const endTimeformate = moment(this.endTime, 'H:mm').format('HH:mm');
      const endTime12Hours = `${endTimeformate} ${this.periodRequiredPm}`;

      const startTime24Hour = moment(startTime12Hour, 'h:mm A').format('HH:mm');
      const endTime24Hour = moment(endTime12Hours, 'h:mm A').format('HH:mm');

      formdata.append('reward_category_id', this.selectedRewardId);
      formdata.append('reward_name', this.reward_name);
      formdata.append('reward_description', this.reward_description);
      formdata.append('external_link', this.external_link);
      formdata.append('points', this.happinesspoints);
      formdata.append('redeem_code', this.redeem_code);
      formdata.append('isactive', this.isactive === '1' ? '1' : '0');
      formdata.append('valid_from_date', fromDate);
      formdata.append('valid_to_date', toDate);
      formdata.append('valid_from_time', startTime24Hour);
      formdata.append('valid_to_time', endTime24Hour);
      formdata.append('file', this.fileToUpload);

      console.log("formdata", formdata);

      var postapi = "/addAndUpdatereward";

      try {
        const response = await (await this.ApiService.postApi(postapi, formdata)).toPromise();
        if (response.status === 200) {
          swal({
            title: "Success!",
            text: "Reward Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.cancelrewards_url]);
          console.log("Data added successfully", response);
        }
      } catch (error) {
        var errMsg = '';
        if (typeof error.error.message === 'object') {
          for (var i in error.error.message) {
            errMsg += error.error.message[i] + '<br />';
          }
        } else {
          errMsg = error.error.message;
        }
        swal({
          title: "Error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });
      }
    }
  }
}
