<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/assestment_question']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Assessment Question Add</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                <div class="">
                    <form>
                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy"> Emotion*</label>
                            <select (change)="emotionItem($event)" style="width:400px;"
                                class=" form-control input_bdr_clr ">
                                <option [disabled]="true" [selected]="true">Select an Emotion</option>
                                <option [value]="[item.themotion_id]" *ngFor="let item of getAllEmotion">
                                    {{item.themotion_name}} </option>

                            </select>
                        </div>

                        <!-- 
                        <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                            <input type="text" id="questions" class="form-control input_bdr_clr " />
                        </div>

                        <div *ngFor="let ele of container; let i = index">

                            <div class="form-group ">
                                <label class="fsize16 clr_black font_bold_gilroy">Never *</label>
                                <select class="form-control input_bdr_clr " 
                                [(ngModel)]="newArray[i].never" [ngModelOptions]="{standalone: true}"
                                >
                                    <option [disabled]="true" [selected]="true">Select an Never</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
    
    
                                </select>
                            </div>
    
                            <div class="form-group ">
                                <label class="fsize16 clr_black font_bold_gilroy">Fairly Often *</label>
                                <select class="form-control input_bdr_clr "
                                [(ngModel)]="newArray[i].fairly" [ngModelOptions]="{standalone: true}"
                                >
                                    <option [disabled]="true" [selected]="true">Select an Emotion</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
    
    
                            <div class="form-group ">
                                <label class="fsize16 clr_black font_bold_gilroy">Almost Never *</label>
                                <select class="form-control input_bdr_clr "
                                [(ngModel)]="newArray[i].almost_never" [ngModelOptions]="{standalone: true}"
                                >
                                    <option [disabled]="true" [selected]="true">Select an Emotion</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <div class="form-group ">
                                <label class="fsize16 clr_black font_bold_gilroy">Sometimes *</label>
                                <select class="form-control input_bdr_clr "
                                [(ngModel)]="newArray[i].sometimes" [ngModelOptions]="{standalone: true}"
                                >
                                    <option [disabled]="true" [selected]="true">Select an Sometimes</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                         
    
                            <div class="form-group ">
                                <label class="fsize16 clr_black font_bold_gilroy">Very Often *</label>
                                <select class="form-control input_bdr_clr "
                                [(ngModel)]="newArray[i].very_often " [ngModelOptions]="{standalone: true}"
                                >
                                    <option [disabled]="true" [selected]="true">Select an Sometimes</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>

                        </div>
               
                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>

                                <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                    class="btn btn-warning">remove</button>
                            </div>
                        </div> -->


                        <div style="background-color: #fff !important;
                        padding: 10px;" >
                            <table id="myTable" class="row-border hover table w-100">
                                <thead>
                                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                        <th>S.No</th>
                                        <th>Order No</th>
                                        <th style="width:300px;">Question</th>
                                        <th>Never</th>
                                        <th>Almost Never</th>
                                        <th>Sometimes</th>
                                        <th>Fairly Often</th>
                                        <th>very Often</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="font_medium_gilroy" *ngFor="let ele of container; let i = index">
                                        <td>{{i+1}}</td>
                                        <td class="para_line">
                                            <input type="text" maxlength="10" [(ngModel)]="newArray[i].no" [ngModelOptions]="{standalone: true}" style="width:50px;" >
                                        </td>

                                        <td class="para_line">
                                            <input type="text" maxlength="1000" [(ngModel)]="newArray[i].question" [ngModelOptions]="{standalone: true}" style="width:280px;">
                                        </td>
                                 
                                        <td class="para_line">
                                            <input class="w_30 padd_0_8"  oninput="this.value=this.value.match(/[0-4]/)" type="text" maxlength="1"  [(ngModel)]="newArray[i].never" [ngModelOptions]="{standalone: true}" >
                                        </td>
                                        <td class="para_line">                                                                                   
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)" type="text" maxlength="1" [(ngModel)]="newArray[i].almost_never" [ngModelOptions]="{standalone: true}" >
                                        </td>
                                        <td class="para_line">
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)" type="text" maxlength="1" [(ngModel)]="newArray[i].sometimes" [ngModelOptions]="{standalone: true}" >
                                        </td>
                                        <td class="para_line">
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)" type="text" maxlength="1" [(ngModel)]="newArray[i].fairly" [ngModelOptions]="{standalone: true}"
                                             >
                                        </td>
                                        <td class="para_line">
                                            <input class="w_30 padd_0_8" oninput="this.value=this.value.match(/[0-4]/)" type="text" maxlength="1"  [(ngModel)]="newArray[i].very_often " [ngModelOptions]="{standalone: true}" >
                                        </td>

                                        <!-- <td>
                                            <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px"
                                                    class="bgr_black" src="assets/Edit_icon.png" 
                                                    data-toggle="modal" data-target="#exampleModal" alt=""></span>
    
                                            <span class="marg-l-10 cursor_pointer" data-toggle="modal"
                                                data-target="#exampleModalDelete" 
                                                > <button type="button"
                                                    class="border_clr_none cursor_pointer"> <img height="22px" width="22px"
                                                        class="bgr_black" src="assets/Delete_icon.png" alt=""> </button>
    
                                            </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>

                                <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                    class="btn btn-warning">remove</button>
                            </div>
                        </div>


                        <div class="form-group" style="margin-top:100px;">
                            <button type="submit" (click)="assestmentAddSubmit()" class="btn  submit_btn_clr">Submit</button>
                            <button type="button" (click)="assessmentBackUrl()" class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                        </div>
                    </form>
                </div>


                <div>

                </div>


            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
        </div>





    </div>

</div>