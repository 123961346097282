import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-corporate-portal-edit',
  templateUrl: './corporate-portal-edit.component.html',
  styleUrls: ['./corporate-portal-edit.component.scss']
})
export class CorporatePortalEditComponent implements OnInit {
  corporateName: any;
  corporateAddress: any;
  isShown: boolean = false;
  back_url = "/corporate_List";
  is_active: any;
  corporateEditId :any;
  corporateAllList:any;
  registerForm: FormGroup;
  submitted = false;
  constructor(private ApiService: ApiService, private router: Router ,private fb: FormBuilder ) { }

  ngOnInit(): void {
    this.corporateEditId = this.ApiService.corporate_EditId;
    this.corporateEditId = localStorage.getItem("corporateEditIds");
    // 
    this.getlist_corporateApi();

    this.registerForm = this.fb.group(
      {
        corporateName: ["", Validators.required],
        corporateaddress: ["", Validators.required],
        corporateStatus: ["", Validators.required],
         },
    );




  }

  cancelCorporate() {
    this.router.navigate([this.back_url]);
  }

  async getlist_corporateApi() {
    var getapi = "/list_corporate_admin/" + this.corporateEditId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        // this.corporateAllList = res.result;
        this.corporateName = res.result.name;
        this.corporateAddress = res.result.address
        var isActive = res.result.isactive;
        if(isActive == 1){
          this.is_active = "1"
        }
        if(isActive == 2){
          this.is_active = "2"
        }

      
      }
    });

  }


  async updateCorporate() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var putApi = "/add_and_update_corporate/" + this.corporateEditId;
    var exerciseUpdatedata = {
      "corporate_name": this.corporateName,
      "corporate_address": this.corporateAddress,
      "isactive": this.is_active
    };
 
    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: res.message,
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.back_url]);
          }
          else if (res.status === 401) {

          }
        }
      });
  }

}
