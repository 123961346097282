import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
@Component({
  selector: 'app-community-topic-table',
  templateUrl: './community-topic-table.component.html',
  styleUrls: ['./community-topic-table.component.scss']
})
export class CommunityTopicTableComponent implements OnInit {

  getAdminListCommunity: any;
  communityDeleteIds: any;

  community_adds_url = "/community-topic-add";
  community_Edit_url = "/community-topic-Edit";
  community_View_url = "/community-topic-view";
   
  showLoaderDiv = false;


  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {

    this.adminListCommunityApi();
  }

  async adminListCommunityApi() {

    this.showLoaderDiv = true;

    var getapi = "/admin_list_community";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
     
      this.getAdminListCommunity = res.data;
      console.log("this.getAdminListCommunity",this.getAdminListCommunity)
      this.showLoaderDiv = false;

    });
  }


  communityAdd() {
    this.router.navigate([this.community_adds_url]);
  }
  community_TopicEdit(editItem: any) {

    this.ApiService.communityId_Edit = editItem.id;

    this.router.navigate([this.community_Edit_url]);
  }
  community_TopicView(viewitem: any) {
    this.ApiService.communityId_view = viewitem.id;
    this.router.navigate([this.community_View_url]);
  }
  communityTopicDelete(items: any) {
    this.communityDeleteIds = items.id;

  }

  async communityTopicDeleteApi() {
    var postapi = "/admin_delete_community";
    var communitydeleteData = {
      "id": this.communityDeleteIds
    };
    (await this.ApiService.postApi(postapi, communitydeleteData)).subscribe(
      res => {
   
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: " Community Deleted Sucessfully ",
            type: "success"
          }, function () {
            alert('yo');
          });

        }
            
      });
      this.adminListCommunityApi();
  }

}
