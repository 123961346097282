import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { $ } from 'protractor';
import * as $ from 'jquery';
declare var swal: any;
// declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isShown: boolean = false;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }
  submit_url = '/home';

  registerForm: FormGroup;
  submitted = false;

  ngOnInit(): void {

    this.registerForm = this.fb.group(
      {
        email: ["", Validators.required],
        password: ["", Validators.required],
      },
    );
  }
  async submit_btn() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    // var emailId = $("#emailid").val();

    var postapi = "/login_admin";
    var email = $("#emailid").val();
    var password = $("#password").val();
    var signupdata = {
      'email': email,
      'password': password
    };
    
    //   (await this.ApiService.postApi(postapi, signupdata)).subscribe(

    //     res => {
    //       localStorage.setItem('token', '');
    //       localStorage.setItem('token', res.token);

    //       if (res) {
    //         if (res.status === 200) {
    //           this.router.navigate([this.submit_url]);
    //         }
    //         else if (res.status === 401) {

    //           swal({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: "Invalid username and password",
    //             type: "error"
    //           }, function () {
    //             alert('yo');
    //           });

    //         }
    //       }
    //     });



    try {
      const res = await (await this.ApiService.postApi(postapi, signupdata)).toPromise();

      localStorage.setItem('token', '');
      localStorage.setItem('token', res.token);

      if (res && res.status === 200) {
        this.router.navigate([this.submit_url]);
      } else if (res && res.status === 401) {
        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Invalid username and password",
          type: "error"
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle errors here
    }




  }

}
