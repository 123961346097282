<div class="add_subs">
  <div class="marg-t-70 marg-l-40">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb fsize27" style="padding-left: 32px;">
        <li class="breadcrumb-item">
          <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/subscription']">Back</a>
        </li>
        <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Edit Subscription</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="mb-3">
                <div>
                  <div *ngIf="dataFetched">
                    Name:<span style="color: red;">*</span>
                  </div>
                  <div class="mt-2">
                    <input type="text" class="input_brdr" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="subscription_name">
                    <span class="text-danger" *ngIf="nameError">Name is required.</span>
                  </div>
                  <!-- {{ getSingleElement.subscription_name }} -->

                  <div>
                    Description:<span style="color: red;">*</span>
                  </div>
                  <div class="mt-2">
                    <input type="text" class="input_brdr" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="subscription_description">
                    <span class="text-danger" *ngIf="descriptionError">Description is required.</span>
                  </div>


                  <div class="row mt-3 mb-3">
                    <!-- <div class="col-8">
                    <div class="astrick">
                      Duration:<span style="color: red;">*</span>
                    </div>
                    <div class="">
                      <div>
                        <select class="input_brdr mt-2" name="duration" id="duration" [(ngModel)]="isToggleDisabled">
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                          <option value="complementary">Complementary</option>
                        </select>
                      </div>
                    </div>
                  </div> -->

                    <div class="col-6">
                      <div>
                        Duration:<span style="color: red;">*</span>
                      </div>

                      <div>
                        <select class="input_brdr mt-2" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="subscription_duration">
                          <option value="complementary">Complementary</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                        </select>
                        <span class="text-danger" *ngIf="durationError">Select any one Duration</span>
                      </div>
                    </div>

                    <div class="col-3">
                      <div>
                        Pricing:<span style="color: red;">*</span>
                      </div>
                      <div class="mt-2">
                        <input type="text" class="input_brdr" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="pricing"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          required>
                        <span class="text-danger" *ngIf="pricingError">Pricing is required.</span>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="astrick">
                        Status:<span style="color: red;">*</span>
                      </div>
                      <div class="mt-2 status_update">
                        <mat-slide-toggle [checked]="isactive" [(ngModel)]="isactive"
                          (change)="onchange()"></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 40px;">
                <div class="row mb-6">
                  <span class="col-md-2"></span>
                  <span class="col-md-4">Allowed Modules</span>
                  <span class="col-md-6 px-0">Limit Access</span>
                </div>

                <div class="mt-3">
                  <div class="col-12" *ngFor="let module of modules; let i = index">
                    <div class="row align-items-center mb-2">
                      <!-- <input class="checkSized" type="checkbox"> -->
                      <span class="col-md-2">
                        <input class="checkSized" type="checkbox" [checked]="module.module_status"
                          (click)="ModuleSelect($event, module, i)">
                      </span>

                      <span class="mx-0 col-md-4">{{ module.modules }}</span>
                      <span class="mx-0 col-md-2">
                        <mat-slide-toggle [(ngModel)]="modules" [checked]="module.access_limit === 1"
                          (change)="ModuleLimitChange($event, module,i)"></mat-slide-toggle>
                      </span>

                      <span *ngIf="module.access_limit === 1" class="mx-0 col-md-2">
                        <input maxlength="2" [(ngModel)]="module.limit_count" [value]="module.limit_count"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          style="height: 40px; width: 40px; border-radius: 8px; border: 1px solid #dee2e6; outline: none !important;"
                          type="text" (keyup)="ModuleLimitCount($event, module)">
                      </span>

                      <span *ngIf="module.access_limit === 1" class="col-md-2 mx-0">
                        <select [(ngModel)]="module.limit_type" (change)="ModuleType($event, module, i)">
                          <option [selected]="module.limit_type == 'Days' " value="Days">Days</option>
                          <option [selected]="module.limit_type == 'Emotions' " value="Emotions">Emotions</option>
                          <option [selected]="module.limit_type == 'Theraphy' " value="Theraphy">Theraphy</option>
                        </select>
                        <small *ngIf="limit_type_error" class="text-danger">Mandatory to
                          select</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div id="error-message" class="text-danger" style="display: none;">
                At least one checkbox must be selected.
              </div>

              <div class="row mt-4 mb-1">
                <div class="col-6">
                  <div>
                    <button class="submit_btn_clr" (click)="updateData()">Save</button>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <button class="cancel_Btn" (click)="cancel_submission()">Cancel</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
    <!-- hhh -->
    <div class="row mt-5">
      <div class="col-3"></div>
      <div class="col-6">
        <div class="row">
          <div class="col-3"></div>
          <div class="col-6"></div>
          <div class="col-3"></div>
        </div>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</div>

<!-- 
async subscriptionList() {
  console.log("sssss", localStorage.getItem("token"))
  var getapi = '/getSubscriptions';
  (await this.ApiService.getApi(getapi)).subscribe(
    res => {
      if (res.status === 200 && res.result && res.result.length > 0) {
        this.subcriptionList = res.result;
        res.result.forEach(subscription => {
          // Check if 'isactive' is defined in the current subscription object
          if (subscription.hasOwnProperty('isactive')) {
            // Process the subscription as needed
            console.log('Subscription ID:', subscription.id);
            console.log('Subscription Name:', subscription.subscription_name);
            console.log('Is Active:', subscription.isactive);


            localStorage.setItem(`get_isactive_${subscription.id}`, subscription.isactive.toString());
          }
        });
      }

    }
  ) -->