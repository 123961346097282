import { Component, OnInit } from '@angular/core';
// import $ from 'jquery';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import * as moment from 'moment';
import * as $ from 'jquery';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dashboardAllCount: any;
  coachCount: any;
  userCount: any;
  dashboardAllData = [];
  filterdashboardData: any;
  todayAppointmentCount: any;
  upcomingAppointmentCount: any;
  dateAll: any;
  today_Dates: any;
  todaySessions: any[] = [];
  upCommingSessions: any[] = [];
  communityModeration: any;
  loading: boolean = false;

  dtOptions: DataTables.Settings = {
    paging: false,
    info: false,
    searching: false
  };
  upcommingAppointment: any[];
  currentPage: number = 1;
  entriesPerPage: number = 50;
  totalPages: number = 0;
  totalEntries: number = 0
  searchValue: string = ''
  appointment_url = '/appointment-details';

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.getAdminDashboardApi();
    // this.getAdminSessionDashboard();
    this.getForumManagementApi();
    this.getAppointmentListApi();

    // const arr = [1,0,2,0,3,0,4,5,6,7,8,9,10,11,12,13,14];
    // const filtered = arr.filter(function(item) {
    //   if (this.count < 5 && item > 0) {
    //     this.count++;
    //     return true;
    //   }
    //   return false;
    // }, {count: 0});




  }

  async getAppointmentListApi() {
    this.loading = true;
    // const search = this.upcomingappointment || "" ;
    const page = this.currentPage;
    const entries = this.entriesPerPage;
    const searchValue = this.searchValue
    var getapi = `/admin_appointment_list?upcomming=true&search=${searchValue}&page=${page}&limit=${entries}`;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var getAppointmentList = res.data;
      this.totalPages = res.pageCount;
      this.totalEntries = res.totalCount

      console.log(getAppointmentList);
      // Waiting
      // this.getAllAppointmentList = getAppointmentList.filter(
      //   (x) => x.appointment_status != 'Waiting'
      // );
      // console.log(this.getAllAppointmentList);
      // var today_Dates = moment();

      this.upcommingAppointment = getAppointmentList;
      console.log(this.upcommingAppointment);
      this.loading = false;
    },
      (error) => {
        console.error('Error fetching appointment list', error);
        this.loading = false;
      }


      // this.upcommingAppointment = this.getAllAppointmentList.filter(
      //   (person) =>
      //     moment(person.date + ' ' + person.time, 'DD-MM-YYYY HH:mm:ss') >=
      //     today_Dates
      // );

      // this.designersOverForty = this.getAllAppointmentList.filter((person) => {
      //   return moment(person.date,"DD-MM-YYYY") >= today_Dates ;
      // });

      // this.raja =  this.designersOverForty
    );
  }
  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getAppointmentListApi();
    }
  }
  searchTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue
    this.getAppointmentListApi()

  }
  onEntriesPerPageChange() {
    this.currentPage = 1;
    this.getAppointmentListApi();
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.getAppointmentListApi();
    }
  }


  async getAdminDashboardApi() {
    this.loading = true;

    var getapi = "/admin_dashboard";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.dashboardAllCount = res.data;
      for (let item of this.dashboardAllCount) {
        this.coachCount = item.coach_count;
        this.userCount = item.user_count;
        this.todayAppointmentCount = item.today_appoint_count;
        this.upcomingAppointmentCount = item.upcoming_appoint_count;
      }

    });
  }
  // --------------------------------------

  async getAdminSessionDashboard() {
    var getapi = "/admin_appointment_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.dashboardAllData = res.data;
      for (let item of this.dashboardAllData) {
        this.dateAll = item.date;

      }
      var today1 = new Date();
      this.today_Dates = moment(today1).format('DD-MM-YYYY');


      this.todaySessions = this.dashboardAllData.filter((obj) => {
        return obj.date === this.today_Dates;
      });



      this.upCommingSessions = this.dashboardAllData.filter((obj) => {
        return obj.date != this.today_Dates;
      });


      // --------------------------------------------------------------

      const todays = new Date().getTime();
      var today_Datess = moment(todays).format('DD-MM-YYYY');

      const filterByExpiration = this.dashboardAllData.filter((items) => {
        return new Date(items.date).getTime() > todays;
      })
      this.loading = false;
      // --------------------------------------------------------------------- 

    },
      (error) => {
        console.error('Error fetching appointment list', error);
        this.loading = false;
      });
  }


  async getForumManagementApi() {
    var getapi = "/community_moderation";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.communityModeration = res.data;
    });
  }
}
