<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Corporate User List
    </div>



    <div class="marg-t-40">
        <div class="" style="background-color: #fff; border-radius: 8px;">

            <table id="myTable" datatable class="row-border hover table w-100" *ngIf="corporateUserList.length > 0">
                <thead>
                    <tr class="font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No.
                        </th>
                        <th>User Name</th>
                        <th>Corporate Name </th>
                        <th> Corporate Address </th>


                    </tr>
                </thead>

                <tbody>

                    <tr class=" font_medium_gilroy" *ngFor ="let item of corporateUserList; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.name}}</td>
                        <td> {{item.corporate_name}}</td>
                        <td> {{item.corporate_address}} </td>

                    </tr>
                </tbody>
            </table>


        </div>

    </div>

</div>