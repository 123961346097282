import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-corporate-approve-list',
  templateUrl: './corporate-approve-list.component.html',
  styleUrls: ['./corporate-approve-list.component.scss']
})
export class CorporateApproveListComponent implements OnInit {
  corporateApproveList:any;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.getlist_corporateListApi();
  }

  async getlist_corporateListApi() {
    var getapi = "/corporate_approval_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
  
      if (res.status === 200) {
        this.corporateApproveList = res.result;
      }
    });

  }


  async corporateApproved(item:any) {

    var userId = item.user_id;

    var postapi = "/corporate_approval";
    var corporateData = {
      "isactive": "1",
      // isactive is 0 for reject and 1 froo approval
      "user_id": userId
    
      };
      (await this.ApiService.postApi(postapi,corporateData)).subscribe(
        res => {
        if (res.status === 200) {
        swal({
              title: "Success!",
              text: "Corporate Approved successfully",
              type: "success",
            }, function () {
              alert('yo');
            });
            this.getlist_corporateListApi();
          }
  
        });
  
    }

  async corporateRejected(items:any){

      var userId = items.user_id;

      var postapi = "/corporate_approval";
      var corporateData = {
        "isactive": "0",
        // isactive is 0 for reject and 1 froo approval
        "user_id": userId
      
        };
        (await this.ApiService.postApi(postapi,corporateData)).subscribe(
          res => {
              if (res.status === 200) {
            swal({
                title: "Success!",
                text: "Corporate Rejected successfully",
                type: "success",
              }, function () {
                alert('yo');
              });
              this.getlist_corporateListApi();
            }
    
          });


    }


}
