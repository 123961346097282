<div class="padd-0-25 marg-t-70">
  <nav aria-label="breadcrumb ">
    <ol class="breadcrumb fsize27 padd_0 ">
      <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
          [routerLink]="['/expedition-Camp-edit']">Back</a>
      </li>
      <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Question Expedition Edit   </li>
    </ol>
  </nav>
  <div class="marg-t-40">
    <div class="row marg-0">
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <form>
          <div>
            <form id="question_form_Id" [formGroup]="registerForm">
              <div class="form-group marg-t-20">
                <div class="form-group marg-t-20">
                  <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                  <input type="text" id="questions" class="form-control input_bdr_clr " [(ngModel)]="questions"
                    formControlName="questions" />
                  <div *ngIf="submitted && registerForm.get('questions').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('questions').errors.required">Question is required
                    </div>
                  </div>
                </div>
                <div class="form-group marg-t-20">
                  <label class="fsize16 clr_black font_bold_gilroy">Question Order*</label>
                  <input type="text" id="questions" class="form-control input_bdr_clr "
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    [(ngModel)]="questionOrder" formControlName="questionsorder" />
                  <div *ngIf="submitted && registerForm.get('questionsorder').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('questionsorder').errors.required">Question Order
                      is required</div>
                  </div>
                </div>

                <div class="form-group marg-t-20" *ngIf="options_question == 0">
                  <label class="fsize16 clr_black font_bold_gilroy">Answer Placeholder{{answerPlaceholder}}</label>

                  <ckeditor name="myckeditor" data="{{answerPlaceholder}}" id="myckeditor" [(ngModel)]="answerPlaceholder"
                    formControlName="answerplaceholders" debounce="500">
                  </ckeditor>
                  <div class="err_msg">
                    <div *ngIf="submitted && registerForm.get('answerplaceholders').errors" class="alert alert-danger">
                      <div *ngIf="registerForm.get('answerplaceholders').errors.required">Answer
                        Placeholder is required</div>
                    </div>
                  </div>
                </div>


                <!-- <div class="form-group marg-t-20" *ngIf="options_question == 1">
                  <label class="fsize16 clr_black font_bold_gilroy">Answer Placeholder
                  </label>

                  <div class="">
                    <input type="text" name="" id="" class="multi-placeholder-camp13 input_bdr_clr w-100 mb-2"
                      [value]="[item.name]" *ngFor="let item of multiPlaceholderCamp13"
                      (change)="changeMultiPlaceholderCamp13($event,item)" />
                  </div>
                  <div>
                    <button style="margin-right:20px;" class="w-100 border-0 inpt-outline model_SaveBtn"
                      (click)="addmultiPlaceholderCamp13()">Add</button>
                  </div>
                </div> -->

                <!-- remove -->

                <!-- <div class="form-group marg-t-20" *ngIf="options_question == 1">
                  <label class="fsize16 clr_black font_bold_gilroy">Answer Placeholders
                  </label>

                  <div class="">
                    <div  *ngFor="let item of multiPlaceholderCamp13">

                      <input type="text" name="" id="" class="multi-placeholder-camp13 input_bdr_clr w-100 mb-2"
                        [value]="[item.name]"
                        (change)="changeMultiPlaceholderCamp13($event,item)" />
                        <button (click)="removemultiPlaceholderCamp13(item)">X</button>
                    </div>
                  </div>
                  <div>
                    <button style="margin-right:20px;" class="w-100 border-0 inpt-outline model_SaveBtn"
                      (click)="addmultiPlaceholderCamp13()">Ad</button>
                  </div>
                </div> -->
                <!-- remove -->


                <div class="marg-t-20">
                  <span class="fsize16 clr_black font_bold_gilroy">View Beliefs * :
                  </span>
                  <span>
                    <label for="vehicle1"> Yes</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" value="1"
                      [(ngModel)]="view_Beliefs" formControlName="viewBeliefs">
                  </span>
                  <span>
                    <label class="marg-l-8" for="vehicle1">No</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" value="0"
                      [(ngModel)]="view_Beliefs" formControlName="viewBeliefs">
                  </span>
                  <div *ngIf="submitted && registerForm.get('viewBeliefs').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('viewBeliefs').errors.required">View Beliefs is
                      required</div>
                  </div>
                </div>
                <div class="marg-t-20">
                  <span class="fsize16 clr_black font_bold_gilroy">Show feeling wheels Button * :
                  </span>
                  <span>
                    <label for="vehicle1"> Yes</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="showfeeling" value="1"
                      [(ngModel)]="feeling_wheel" formControlName="showfeeling">
                  </span>
                  <span>
                    <label class="marg-l-8" for="vehicle1">No</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="showfeeling" value="0"
                      [(ngModel)]="feeling_wheel" formControlName="showfeeling">
                  </span>
                  <div *ngIf="submitted && registerForm.get('showfeeling').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('showfeeling').errors.required">Show feeling wheels is
                      required</div>
                  </div>
                </div>
                <div class="marg-t-20">
                  <span class="fsize16 clr_black font_bold_gilroy">Show cognitivebias Button * :
                  </span>
                  <span>
                    <label> Yes</label>
                    <input class="marg-l-8" type="radio" name="showCognitive" value="1" [(ngModel)]="cognitive_bias"
                      formControlName="showCognitive">
                  </span>
                  <span>
                    <label class="marg-l-8">No</label>
                    <input class="marg-l-8" type="radio" name="showCognitive" value="0" [(ngModel)]="cognitive_bias"
                      formControlName="showCognitive">
                  </span>
                  <div *ngIf="submitted && registerForm.get('showfeeling').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('showfeeling').errors.required">Show cognitivebias is
                      required</div>
                  </div>
                </div>
                <div class="marg-t-20">
                  <span class="fsize16 clr_black font_bold_gilroy">Collect Response * :
                  </span>
                  <span>
                    <label for="vehicle1"> Yes</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="collectResponses" value="1"
                      [(ngModel)]="collectResponse" formControlName="collectResponses">
                  </span>
                  <span>
                    <label class="marg-l-8" for="vehicle1">No</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="collectResponses" value="0"
                      [(ngModel)]="collectResponse" formControlName="collectResponses">
                  </span>
                  <div *ngIf="submitted && registerForm.get('showfeeling').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('showfeeling').errors.required">Collect Response is
                      required</div>
                  </div>
                </div>
                <!--  -->
                <div class="marg-t-20" *ngIf="expeditionEditAllId == 6 && expeditionsCampid == 13">
                  <span class="fsize16 clr_black font_bold_gilroy">Option * :
                  </span>
                  <span>
                    <label for="vehicle1"> Yes</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="options_question" value="1"
                      [(ngModel)]="options_question" [ngModelOptions]="{standalone: true}">
                  </span>
                  <span>
                    <label class="marg-l-8" for="vehicle1">No</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="options_question" value="0"
                      [(ngModel)]="options_question" [ngModelOptions]="{standalone: true}">
                  </span>
                  <div *ngIf="submitted && registerForm.get('showfeeling').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('showfeeling').errors.required">Collect Response is
                      required</div>
                  </div>
                </div>

                <div class="form-group marg-t-20 mb-2" *ngIf="options_question == 1">
                  <span class="fsize16 clr_black font_bold_gilroy">Edit Options * :</span>
                  <span>
                    <!-- <button style="margin-right:20px;" class="border-0 mx-2 "
                    (click)="addmultiPlaceholderCamp13()"> -->

                    <img src="assets/add_circle_FILL1_wght300_GRAD0_opsz24.svg" alt="" class="h-24 marg-l-8" (click)="addmultiPlaceholderCamp13()">
                  <!-- </button> -->
                  </span>
                  <!-- <label class="fsize16 clr_black font_bold_gilroy">Edit Options * :
                  </label> -->

                  <div class="mt-2">
                    <div  *ngFor="let item of multiPlaceholderCamp13">
                      <div class="row">
                        <div class="col-8">
                          <div>
                            <input type="text" name="" id="" class="multi-placeholder-camp13 input_bdr_clr w-100 mb-2"
                            [value]="[item.name]"
                            (change)="changeMultiPlaceholderCamp13($event,item)" />
                          </div>
                        </div>
                        <div class="col-4 p-0">
                          <div class="mt-2">
                            <!-- <button style="margin-right:20px;" class="border-0 inpt-outline plus-icon mx-2 "
                            (click)="addmultiPlaceholderCamp13()">+</button> -->
                            <!-- <button (click)="removemultiPlaceholderCamp13(item)" class="minus-icon"> -->
                              <img src="assets/close_FILL1_wght300_GRAD0_opsz24.svg" alt="" (click)="removemultiPlaceholderCamp13(item)">
                            <!-- </button> -->


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>

                  </div>




                </div>
                <!--  -->
                <div class="marg-t-20" *ngIf="campnew_Id > 1">
                  <span class="fsize16 clr_black font_bold_gilroy">Display Response From
                    Previous Camp site * : </span>
                  <span>
                    <label for="vehicle1"> Yes</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="vehicle1" value="1"
                      (change)="responsePreviousCampSiteYes()" [(ngModel)]="responsFromPrevious"
                      [ngModelOptions]="{standalone: true}">
                  </span>
                  <span>
                    <label class="marg-l-8" for="vehicle1">No</label>
                    <input class="marg-l-8" type="radio" id="vehicle1" name="vehicle1"
                      (change)="responsePreviousCampSiteYes()" value="0" [(ngModel)]="responsFromPrevious"
                      [ngModelOptions]="{standalone: true}">
                  </span>
                </div>
                <div *ngIf="previousCampSite_Yes_Div">
                  <div class="marg-t-20" *ngIf="campnew_Id > 1">
                    <span class="fsize16 clr_black font_bold_gilroy">Camp * : </span>
                    <select [(ngModel)]="prvCampId" [ngModelOptions]="{standalone: true}"
                      class=" form-control input_bdr_clr" (change)="camplistTitleFun($event)">
                      <!-- <option value="" disabled selected data-default>Select a value</option> -->
                      <option [value]="null" [disabled]="true" [selected]="prvCampId==null">Please
                        Select Camp</option>
                      <option [value]="[item.id] " [selected]="prvCampId == item.id" *ngFor="let item of campSiteList">
                        {{item.camp_name}} </option>
                    </select>
                  </div>
                  <div class="form-group marg-t-20" *ngIf="campnew_Id > 1">
                    <label class="fsize16 clr_black font_bold_gilroy">Camp site Question
                      *</label>
                    <!-- <input type="text" id="questions1" [(ngModel)]="previouscampSiteQuestion"
          [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr " /> -->
                    <!-- [ngModelOptions]="{standalone: true}"
          [(ngModel)]="previouscampSiteQuestion" -->
                    <!-- prvCampIdTwo -->
                    <!-- [selected]="prvCampQuestionId == item.id" -->
                    <select (change)="editCampSiteQuestion($event)" class=" form-control input_bdr_clr">
                      <option [disabled]="true" [selected]="true">Please Select Camp site Question
                      </option>
                      <option [value]="[item.id]" [selected]="prvCampQuestionId == item.id"
                        *ngFor="let item of previousCampsiteList">
                        {{item.question}} </option>
                    </select>
                  </div>
                  <!-- -------------------------------------------- -->
                  <!--  -->
                  <div *ngIf="campnew_Id == 7 || campnew_Id == 8 || campnew_Id ==9 ">
                    <div class="marg-t-20">
                      <span class="fsize16 clr_black font_bold_gilroy">Camp : </span>
                      <select [(ngModel)]="prvCampIdTwo" [ngModelOptions]="{standalone: true}"
                        class=" form-control input_bdr_clr" (change)="camplistTitleFunTwo($event)">
                        <option [value]="null" [disabled]="true" [selected]="prvCampIdTwo==null">Please Select Camp
                        </option>
                        <option [value]="[item.id]" [selected]="prvCampIdTwo == item.id"
                          *ngFor="let item of campSiteList">
                          {{item.camp_name}} </option>
                      </select>
                    </div>
                    <div class="form-group marg-t-20">
                      <label class="fsize16 clr_black font_bold_gilroy">Camp site Question
                      </label>
                      <!-- [(ngModel)]="previouscampSiteQuestionTwo"
          [ngModelOptions]="{standalone: true}" -->
                      <select (change)="editCampSiteQuestionTwo($event)" class=" form-control input_bdr_clr">
                        <option [disabled]="true" [selected]="true">Please Select Camp site
                          Question</option>
                        <option [value]="[item.id] " [selected]="prvCampQuestionIdTwo == item.id"
                          *ngFor="let item of previousCampsiteListTwo">
                          {{item.question}} </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- <div class="marg-t-20">
          <span class="fsize16 clr_black font_bold_gilroy">Status * : </span>
          <span>
              <label for="vehicle1"> Active</label>
              <input class="marg-l-8" type="radio" id="vehicle1" name="status" value="1"
                  [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
          </span>
          <span>
              <label class="marg-l-8" for="vehicle1">In active</label>
              <input class="marg-l-8" type="radio" id="vehicle1" name="status" value="0"
                  [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
          </span>
          </div> -->
              </div>
            </form>
          </div>
          <div class="form-group marg-t-40">
            <button type="submit" class="btn  submit_btn_clr"
              (click)="expeditionQuestionUpdate($event)">Submit</button>
            <button (click)="editCancel()" type="button" class="btn  cancelBtn_clr "
              style="height: 40px;margin-left: 20px;font-size: 16px;">Cancel</button>
          </div>
        </form>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
      </div>
    </div>
  </div>
</div>
