import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
@Component({
  selector: 'app-package-manager',
  templateUrl: './package-manager.component.html',
  styleUrls: ['./package-manager.component.scss']
})
export class PackageManagerComponent implements OnInit {

  packageAdd_url = "/packageManagerAdd";
  packageEdit_url = "/packageManagerEdit";
  packageTotalList: any;
  deletePackageId:any;
  Math:any;
  description:any;
  updateStatusChecked:any;
 constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.packageManagerListApi();
    this.Math = Math;
  }

  async packageManagerListApi() {
    var getapi = "/session/package/list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.packageTotalList = res.data
      }
    });
  }

  packageAdd() {
    this.router.navigate([this.packageAdd_url]);
  }

  editPackageManager(item:any){
    localStorage.setItem("packageEditId",item.id)
    this.router.navigate([this.packageEdit_url]);
  }
  packageManagerAdd(){
    this.router.navigate([this.packageAdd_url]);
  }

  deletePackageManager(items:any){
    this.deletePackageId = items.id;
    console.log( this.deletePackageId,' this.deletePackageId');

  }
  viewDescription(item:any) {

    this.description  = item.description
  }

  async packageManagerDeleteApi() {
    var getapi = "/session/package/delete/"+this.deletePackageId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {

        swal({
          title: "Success!",
          text: " Package Manager Deleted successfully",
          type: "success",
        }, function () {
          alert('yo');
        });
        this.packageManagerListApi();
        this.description = res.data.description;

      }
    });
  }


  async statusUpdate(e:any,id:any){

    var getapi = `/session/package/status/update/${id}/${(e.checked)?'0':'1'}`;
    (await this.ApiService.getApi(getapi)).subscribe(
      res => {
        if(res.status == 200){
          // this.updateStatusChecked = res.data;
          // this.statusId = res.

        }

      }
    )
  }

}
