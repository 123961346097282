import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { event } from 'jquery';
import { title } from 'process';

declare var swal: any;


@Component({
  selector: 'app-coach-slot-management-list',
  templateUrl: './coach-slot-management-list.component.html',
  styleUrls: ['./coach-slot-management-list.component.scss']
})
export class CoachSlotManagementListComponent implements OnInit {


  coach_datas: any[];
  showLoaderDiv = false;
  slot_create = '/slot-management';
  back_management_list = '/coach'
  coach_slot_list: any[]
  coach_name: any
  appointment_status: any[] = [];
  loading: boolean = false;

  deleteid: any

  user_id: any;
  thcoach_type: any

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.user_id = localStorage.getItem('user_id');
    this.thcoach_type = localStorage.getItem('coach_type');

    console.log("this.adminUserCoachId", this.user_id)
    console.log("this.coach_type", this.thcoach_type)

    this.coach_list();


  }


  async delete_id(deletedId: any) {
    this.deleteid = deletedId.id;
    console.log("delete_id", this.deleteid)

  }

  // async delete_slot() {

  //   var deleteSlot = '/delete_slots';

  //   var deletedId_slot = {
  //     "deleted_by": 'admin',
  //     "slot_ids": this.deleteid
  //   };

  //   (await (this.api.postApi(deleteSlot, deletedId_slot))).subscribe((res) => {
  //     if (res == 200) {
  //       console.log("Sucessfulyyyyyyyyyyyy deleted", res)
  //     }
  //   })
  // }

  async delete_slot() {
    // Find the specific slot with the clicked ID (this.deleteid)
    const selectedSlot = this.coach_slot_list.find(slot => slot.id === this.deleteid);

    if (selectedSlot && (selectedSlot.appointment_status == null || selectedSlot.appointment_status == '' || selectedSlot.appointment_status == undefined)) {
      // Proceed with deletion if appointment_status is null, empty string, or undefined
      var deleteSlot = '/delete_slots';

      var deletedId_slot = {
        "deleted_by": 'admin',
        "slot_ids": this.deleteid
      };

      (await (this.api.postApi(deleteSlot, deletedId_slot))).subscribe((res) => {
        if (res.status === 200) {
          swal({
            title: "Success !",
            text: 'Slot deleted Successfully',
            type: "success",
          }).then(() => {
            window.location.reload();
          });
        }
      });
    } else {
      swal({
        title: 'Error !',
        text: 'You cant delete the slot',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }
  }



  async coach_list() {
    this.loading = true;
    const postapi = '/admin_get_availability_coach';

    var userID = {
      "user_id": this.user_id
    };

    (await (this.api.postApi(postapi, userID))).subscribe((res) => {

      this.coach_slot_list = res.result
      this.coach_name = res.coach_name
      this.appointment_status = res.result.map(item => item.appointment_status);

      // localStorage.setItem('coach_name', this.coach_name);

      console.log("----------------------", this.coach_slot_list)
      console.log("coach_name:", this.coach_name);
      console.log("this.appointment_status:", this.appointment_status);
      this.loading = false
    }, (error) => {
      console.error('Error fetching appointment list', error);
      this.loading = false;
    })

  }

  slotcreate() {
    // Retrieve adminUserCoachId from the component property
    const storedAdminUserCoachId = this.user_id;
    const stored_coach_type = this.thcoach_type;
    const stored_coach_name = this.coach_name

    if (storedAdminUserCoachId) {
      // Navigate to the slot_create route with adminUserCoachId as a query parameter
      this.router.navigate([this.slot_create], { queryParams: { coach_id: storedAdminUserCoachId, coach_type: stored_coach_type, coach_name: stored_coach_name } });
    } else {
      console.error('adminUserCoachId not available.');
    }
  }

  back_management() {
    this.router.navigate([this.back_management_list])
  }

}
