import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
// import * as bootstrap from 'bootstrap';
declare var swal: any;
// import $ from 'jquery';
declare const $: any;
declare var window: any;
declare var bootstrap: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-assestmenteditresult',
  templateUrl: './assestmenteditresult.component.html',
  styleUrls: ['./assestmenteditresult.component.scss']
})
export class AssestmenteditresultComponent implements OnInit {

  isShown: boolean = false;
  getAllEmotion = [];
  emotionsId: any;
  resultId: any;
  getResultAll: any;
  assestment_List: any;
  assestment_EditId: any;
  descriptionAdd: any;
  updateDescription: any;
  updateEditId: any;
  updateDeleteId: any;
  orderNo: any
  assestmentBack_url = "/assestment_results";
  registerForm: FormGroup;
  submitted = false;
  editRegForm: FormGroup;
  editSubmitted = false;
  formModal: any;
  editFormModal: any;

  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }


  ngOnInit(): void {

    this.assestment_EditId = this.ApiService.assestmentEditId;
    this.assestment_EditId = localStorage.getItem("assestmentEditIds")
    console.log("the id based ",this.assestment_EditId)
    this.getlist_assestmentList();
    this.getEmotionListApi();
    this.getResultApi();

    this.registerForm = this.fb.group(
      {
        displayorder: ["", Validators.required],
        description: ["", Validators.required],

      },
    );


    this.editRegForm = this.fb.group(
      {
        editdisplayorder: ["", Validators.required],
        editdescription: ["", Validators.required],

      },
    );
    // ------------------------------------------------------------

    this.formModal = new window.bootstrap.Modal(
      document.getElementById('exampleModalAdd')
    );

    this.editFormModal = new window.bootstrap.Modal(
      document.getElementById('exampleModal')
    );



  }

  showModel() {
    this.formModal.show();
  }

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data
    });
  }

  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;

  }

  // --------------------

  resultItem(e: any) {
    this.resultId = e.target.value;

  }
  // ----------------Result List Api---------------------------

  async getResultApi() {
    var getapi = "/list_range";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getResultAll = res.result
    });
  }

  //  --------------------------------------


  async getlist_assestmentList() {
    var getapi = "/list_assessment_result/" + this.assestment_EditId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.assestment_List = res.result;
        this.emotionsId = res.result.emotion;
        this.resultId = res.result.result;
      }
    });

  }

  edit_update(item: any) {
   this.updateEditId = item.id
    this.updateDescription = item.description;
    this.orderNo = item.no;
    this.editFormModal.show();
  }

  assestmentEditDelete(item: any) {
    this.updateDeleteId = item.id;
  }

  add_submit() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    $("#exampleModalAdd .close").click()

    this.assestment_List.slides.push({ no: this.orderNo, description: this.updateDescription })

    this.formModal.hide();


  }

  submitUpdate() {

    this.editSubmitted = true;

    if (this.editRegForm.invalid) {
      return;
    }

    this.assestment_List.slides.forEach((item, index) => {
      if (item.id == this.updateEditId) {
        item.description = this.updateDescription
        item.no = this.orderNo
      }
    })
    this.editFormModal.hide();

  }

  delete_submit() {

    this.assestment_List.slides = this.assestment_List.slides.map((item, index) => {
      if (item.id == this.updateDeleteId) {
        return { ...item, delete: this.updateDeleteId };
      }
      else {
        return item;
      }
    })


  }


  async assestmentUpdate() {
    // var data = { ...item, no: item.order_no }
    // delete data.order_no
    // return data;

    // this.assestment_List.slides = this.assestment_List.slides.map((item, index) => {
    //   if (item.id == this.updateEditId) {
    //     item.description = this.updateDescription
    //     item.no = this.orderNo
    //     var data = { ...item, no: item.order_no }
    //     delete data.order_no
    //     return data;
    //   }
    //   else {
    //     return item;
    //   }
    // })

    var putApi = "/add_and_update_assessment_result/" + this.assestment_EditId;
    var exerciseUpdatedata = {

      "emotion_id": this.emotionsId,
      "result_id": this.resultId,
      "slides": this.assestment_List.slides
    };

    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Assessment Result Updated Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.assestmentBack_url]);
          }
          else if (res.status === 401) {

          }
        }
      });

  }

  assestmentCancelBtn() {
    this.router.navigate([this.assestmentBack_url]);
  }


}
