import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-question-sos-view',
  templateUrl: './question-sos-view.component.html',
  styleUrls: ['./question-sos-view.component.scss']
})
export class QuestionSosViewComponent implements OnInit {

  question_Type: any;
  questions: any;
  answer_placeholder_text: any;
  answer_placeholder_text1: any;
  answer_placeholder_text2: any;
  questionYesRadioBtn: any;
  questionNoRadioBtn: any;
  yesQuestionText: any;
  showToastMessage: any;
  collectResponse: any;
  yesPlaceholderText: any;
  noPlaceholderText: any;
  noQuestionText: any;
  noQuestion_ShowDiv = false;
  yesQuestion_ShowDiv = false;
  isShown: boolean = false;
  show_questionText_div = false;
  show_questionImage_div = false;
  show_questionAudio_div = false;
  affirmation_div = false;
  grid_div = false;
  multi_response_div = false;
  review_div = false;
  Yes_or_No_div = false;
  sos_questionAddIds: any;
  sos_questionEditids: any;
  sos_addId: any;
  question_EditSubmit_url = "/sos-edit";
  question_one: any;
  question_two:any;
  isLoading = false;
  question_NoRadioBtn:any;
  no_no_question:any;
  no_radiobtn:any;
  no_no_answer_placeholder:any;
  showNo_NoquestionDiv = false;
  showYes_NoquestionDiv = false;
  constructor(private ApiService: ApiService) { }

  ngOnInit() {

    this.sos_questionEditids = this.ApiService.sosQuestionViewEdit_Id;
    this.sos_questionAddIds = this.ApiService.sosQuestionViewAdd_Id;
    this.getQuestionapi();
  }
  async getQuestionapi() {
   
   var getapi = "/sos/get_questions_byId?sos_id=" + this.sos_questionAddIds + "&type=question&id=" + this.sos_questionEditids;

    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var myArray = res.data;
      // this.isLoading = true;
      for (let item of myArray) {
        var ids = item.id;
        if (ids == this.sos_questionEditids) {
          this.question_Type = item.question_type;
          this.questions = item.question1;
          this.question_one = item.question2;
          this.question_two = item.question3;
          this.answer_placeholder_text = item.answer_placeholder1;
          this.answer_placeholder_text1 = item.answer_placeholder2;
          this.answer_placeholder_text2 = item.answer_placeholder3;
          this.questionYesRadioBtn = item.selected_yes_no_type;
          this.question_NoRadioBtn = item.no_question_type;
          this.no_no_question = item.no_question;
          this.no_no_answer_placeholder = item.no_placeholder;
          this.no_radiobtn = item.no_checkbox;
        }
      }
  
      this.questionOne();
      this.yesOrNo_questionType();
      this.no_yesOrNo_questionType();
      
    });
    // this.isLoading = false;

   
  }

  questionOne() {
    var questionValue = this.question_Type;
    if (questionValue == 'text') {
      this.show_questionText_div = true;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'image') {
      this.show_questionText_div = false;
      this.show_questionImage_div = true;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'audio') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = true;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'affirmation') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = true;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'grid') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = true;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'multi_response') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = true;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }

    if (questionValue == 'review') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = true;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'yes_or_no') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = true;
      this.yesQuestion_ShowDiv = false;
      this.noQuestion_ShowDiv = false;
    }

  }
  yesOrNo_questionType() {

    var questionValue = this.questionYesRadioBtn;
    if (questionValue == 'Yes') {
      this.yesQuestion_ShowDiv = true;
      this.noQuestion_ShowDiv = false;
    }
    if (questionValue == 'No') {
      this.yesQuestion_ShowDiv = false;
      this.noQuestion_ShowDiv = true;
    }
  }

  no_yesOrNo_questionType(){

    var questionValue = this.question_NoRadioBtn;
    if (questionValue == '1') {
      this.showYes_NoquestionDiv = true;
      this.showNo_NoquestionDiv = false;
    }
    if (questionValue == '0') {
      this.showYes_NoquestionDiv = false;
      this.showNo_NoquestionDiv = true;
    }

  }



}
