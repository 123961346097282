import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-blog-master',
  templateUrl: './blog-master.component.html',
  styleUrls: ['./blog-master.component.scss']
})
export class BlogMasterComponent implements OnInit {
  blogAdd_url = "/blog_master_add";
  blogEdit_url = "/blog_master_edit";
  blogView_url = "/blog_master_view";
  blogAllList: any;
  blogDeleteId: any;
  showLoaderDiv = false;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.getlist_blogMasterApi()
  }

  async getlist_blogMasterApi() {

    this.showLoaderDiv = true;
    var getapi = "/list_blog";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.blogAllList = res.result;
        this.showLoaderDiv = false;
      }
    });

  }

  blogAddMaster() {

    this.router.navigate([this.blogAdd_url]);
  }

  blogMasterEdit(items: any) {
    this.ApiService.blogEditId = items.id;
    localStorage.setItem("blogEditIds", items.id)
    this.router.navigate([this.blogEdit_url]);
  }

  blogDelete(item: any) {
    this.blogDeleteId = item.id
  }

  async blogDeleteApi() {
    var deleteApi = "/delete_blog/" + this.blogDeleteId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      if (res.status === 200) {
        this.getlist_blogMasterApi();

      }
    });
  }



}
