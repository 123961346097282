<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/workout-day-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Workout Edit </li>
        </ol>
    </nav>

    <!-- <div class="marg-t-30 fsize27 font_bold_gilroy">
        Happy WorkOut Edit
    </div> -->
    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div style="display: flex; float: right;">
                    <span style="margin-right: 14px;"> <button data-toggle="modal" data-target="#addExerciseModel"
                            class="submit_btn_clr" (click)="addexerbtn()">Add Exercise</button></span>

                    <span> <button class="submit_btn_clr" data-toggle="modal" data-target="#exampleModalquestion"
                            (click)="add_Questions()" id="addQuestion" value="1"> Add
                            Question</button></span>

                </div>
                <div class="marg-t-40">

                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Title *</label>
                            <input type="text" [(ngModel)]="workoutEditTitle" formControlName="title" id="titleEdit"
                                class="form-control input_bdr_clr" maxlength="150" />
                            <!-- title -->

                            <div *ngIf="submitted && registerForm.get('title').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('title').errors.required">No of question is required
                                </div>
                            </div>
                        </div>


                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Category</label>
                            <select formControlName="category" [(ngModel)]="category_name"
                                ng-reflect-model="category_name" (change)="get_category_list($event)"
                                class="form-control input_bdr_clr">
                                <option [ngValue]="null" disabled>Select any type</option>
                                <option [ngValue]="item.id" *ngFor="let item of getallcategory">{{ item.category_name }}
                                </option>
                            </select>
                        </div>


                        <div class="row marg_0">

                            <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy">Day *</label>
                                <div>
                                    <!-- <input type="text" style="width: 150px;" id="dayEdit" [(ngModel)]="workoutEditDay"
                                        [ngModelOptions]="{standalone: true}"
                                        class=" input_bdr_clr edit_input_bdrClr" /> -->
                                    <select id="myid" [(ngModel)]="workoutEditDay" [ngModelOptions]="{standalone: true}"
                                        class=" form-control input_bdr_clr " (change)="dayid($event)"
                                        style="width: 130px;">

                                    </select>
                                </div>

                            </div>

                            <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy"
                                    style="margin-left:20px;">Level</label>
                                <select id="workoutLevel" disabled [(ngModel)]="workoutEditLevel"
                                    [ngModelOptions]="{standalone: true}" class=" form-control input_bdr_clr "
                                    style="width:130px;margin-left: 20px;">

                                </select>
                            </div>

                            <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy"
                                    style="margin-left:20px;">Range</label>
                                <select id="workoutRange" disabled [(ngModel)]="workoutEditRange"
                                    [ngModelOptions]="{standalone: true}" class=" form-control input_bdr_clr "
                                    style="width: 130px;margin-left: 20px;">
                                </select>
                            </div>

                        </div>


                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">No Of Question *</label>

                            <input type="text" [(ngModel)]="noOf_Question" id="example" formControlName="noOfquestion"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                class="form-control input_bdr_clr" maxlength="150" />

                            <div *ngIf="submitted && registerForm.get('noOfquestion').errors"
                                class="alert alert-danger">
                                <div *ngIf="registerForm.get('noOfquestion').errors.required">No of question is required
                                </div>
                            </div>
                        </div>

                        <div>
                            <span>Show Read More * : </span>
                            <span>
                                <label for="showReadMoreYes"> Yes</label>
                                <input class="marg-l-8" [(ngModel)]="showReadMoreValue"
                                    [ngModelOptions]="{standalone: true}" type="radio" id="showReadMoreYes"
                                    name="showReadMore" value="1" (change)="showReadFunction()">
                            </span>

                            <span>
                                <label class="marg-l-8" for="showReadMoreNo"> No</label>
                                <input class="marg-l-8" type="radio" id="showReadMoreNo" name="showReadMore" value="0"
                                    [(ngModel)]="showReadMoreValue" [ngModelOptions]="{standalone: true}"
                                    (change)="showReadNoFunction()">
                            </span>
                        </div>

                        <div class="marg-t-20">
                            <span>Read More Content <span *ngIf="showReadMandatory"></span>
                                :</span> <span class="marg-l-8">
                                <!-- <input type="text" [(ngModel)]="readMoreContentEdit"
                                    [ngModelOptions]="{standalone: true}" class="input_bdr_bottom " id="readMoreContent"
                                    style="width: 300px;"> -->

                                <div class="marg-t-20">

                                    <ckeditor name="myckeditor" [(ngModel)]="readMoreContentEdit"
                                        [ngModelOptions]="{standalone: true}" id="readMoreContent" debounce="500">
                                    </ckeditor>

                                </div>

                            </span>
                        </div>

                        <div class="form-group marg-t-40">
                            <button type="submit" class="btn  submit_btn_clr">Submit</button>
                        </div>
                    </form>
                </div>

                <div>
                    <!-- Button trigger modal -->

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModalquestion" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="" style="padding: 10px;">
                                    <span class="modal-title font_size22 clr_black font_bold_gilroy">Add
                                        Question</span>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div>
                                        <form id="question_form_Id">
                                            <form [formGroup]="questionTypeRegForm">
                                                <div class="form-group ">
                                                    <label class="fsize16 clr_black font_bold_gilroy">Question
                                                        Type *</label>
                                                    <select formControlName="questionType"
                                                        (change)="questionType_One($event)"
                                                        id="workoutQuestionType{{count}}"
                                                        class=" form-control input_bdr_clr ">
                                                        <option selected disabled val="">Select Question Type</option>
                                                        <option value="text">Text</option>
                                                        <option value="minddump">Mind Dump</option>
                                                        <option value="affirmation">Affirmation</option>
                                                    </select>


                                                    <div *ngIf="submittedQuesType && questionTypeRegForm.get('questionType').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="questionTypeRegForm.get('questionType').errors.required">
                                                            Question Type is
                                                            required</div>
                                                    </div>



                                                </div>

                                            </form>

                                            <!-- <div id="text" class="" *ngIf="show_questionOneType_Text"
                                            style="margin-bottom: 12px;">
                                            <input type="text"
                                                class="form-control input_bdr_clr margin_b_14 h-40"
                                                placeholder="Text">
                                        </div> -->

                                            <!-- <div id="image" class="" *ngIf="show_questionOneType_image"
                                            style="margin-bottom: 12px;">
                                            <input type="file"
                                                class="form-control input_bdr_clr margin_b_14 h-40"
                                                style="padding: 4px;">
                                        </div> -->

                                            <!-- <div id="audio" class="" *ngIf="show_questionOneType_audio"
                                            style="margin-bottom: 12px;">
                                            <input type="file" accept="audio/mp3"
                                                class="form-control input_bdr_clr margin_b_14 h-40"
                                                style="padding: 4px;">
                                        </div> -->


                                            <div *ngIf="questionsTypeValues =='text' ">

                                                <form [formGroup]="questionTypeTextForm">

                                                    <!-- <form  [formGroup]="typeAffirmationForm"  > -->

                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" id="questionsWorkout{{count}}"
                                                            formControlName="questions"
                                                            class="form-control input_bdr_clr " />

                                                        <div *ngIf="submittedQuesType && questionTypeTextForm.get('questions').errors"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="questionTypeTextForm.get('questions').errors.required">
                                                                Question is
                                                                required</div>
                                                        </div>

                                                    </div>









                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy"> Placeholder
                                                            Text *</label>
                                                        <!-- <input type="text" id="placeholder_answer{{count}}"
                                                class="form-control input_bdr_clr " /> -->
                                                        <ckeditor name="myckeditor" formControlName="placeholdertext"
                                                            id="placeholder_answer{{count}}" debounce="500">
                                                        </ckeditor>
                                                        <!-- ---------------------------------------------------------------------------- -->

                                                        <div *ngIf="submittedQuesType && questionTypeTextForm.get('placeholdertext').errors"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="questionTypeTextForm.get('placeholdertext').errors.required">
                                                                Question is
                                                                required</div>
                                                        </div>




                                                    </div>

                                                    <div>
                                                        <span class="fsize16 clr_black font_bold_gilroy">Show Feeling *
                                                            :
                                                        </span>
                                                        <span>
                                                            <label for="vehicle1"> Yes</label>
                                                            <input class="marg-l-8" type="radio"
                                                                formControlName="showFeeling" id="showFeeling{{count}}"
                                                                value="1">
                                                        </span>

                                                        <span>
                                                            <label class="marg-l-8" for="vehicle1"> No</label>
                                                            <input class="marg-l-8" type="radio"
                                                                formControlName="showFeeling" id="showFeeling{{count}}"
                                                                value="0">
                                                        </span>

                                                        <div *ngIf="submittedQuesType && questionTypeTextForm.get('showFeeling').errors"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="questionTypeTextForm.get('showFeeling').errors.required">
                                                                Show Feeling is
                                                                required</div>
                                                        </div>

                                                    </div>


                                                    <div class="form-group marg-t-20">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Position
                                                            *</label>
                                                        <input type="text" id="positions{{count}}"
                                                            formControlName="textPosition"
                                                            class="form-control input_bdr_clr " />
                                                    </div>

                                                    <div *ngIf="submittedQuesType && questionTypeTextForm.get('textPosition').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="questionTypeTextForm.get('textPosition').errors.required">
                                                            Position is
                                                            required</div>
                                                    </div>

                                                </form>


                                            </div>


                                            <div *ngIf="questionsTypeValues =='minddump' ">

                                                <form [formGroup]="typeMinddumpForm">

                                                    <div class="form-group" *ngIf="questionType_Text_question">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" id="questionsWorkout{{count}}"
                                                            formControlName="questionsMinddump"
                                                            class="form-control input_bdr_clr "
                                                            name="questionsMinddump" />

                                                        <div *ngIf="submittedMinddump && typeMinddumpForm.get('questionsMinddump').errors"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="typeMinddumpForm.get('questionsMinddump').errors.required">
                                                                Question is
                                                                required</div>
                                                        </div>

                                                    </div>

                                                    <div class="form-group marg-t-20">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Position
                                                            *</label>
                                                        <input type="text" id="positions{{count}}"
                                                            formControlName="positionMinddump"
                                                            class="form-control input_bdr_clr " />
                                                    </div>

                                                    <div *ngIf="submittedMinddump && typeMinddumpForm.get('positionMinddump').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="typeMinddumpForm.get('positionMinddump').errors.required">
                                                            Position is
                                                            required</div>
                                                    </div>



                                                </form>

                                            </div>



                                            <div *ngIf="questionsTypeValues =='affirmation' ">

                                                <form [formGroup]="typeAffirmationForm">
                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" id="questionsWorkout{{count}}"
                                                            formControlName="questions"
                                                            class="form-control input_bdr_clr " name="questions" />

                                                        <div *ngIf="submittedAffirmation && typeAffirmationForm.get('questions').errors"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="typeAffirmationForm.get('questions').errors.required">
                                                                Question is
                                                                required</div>
                                                        </div>

                                                    </div>

                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy"> Placeholder
                                                            Text *</label>

                                                        <ckeditor name="placeholdertext"
                                                            formControlName="placeholdertext"
                                                            id="placeholder_answer{{count}}" debounce="500">
                                                        </ckeditor>


                                                        <div *ngIf="submittedAffirmation && typeAffirmationForm.get('placeholdertext').errors"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="typeAffirmationForm.get('placeholdertext').errors.required">
                                                                Placeholder
                                                                Text is
                                                                required</div>
                                                        </div>

                                                    </div>


                                                </form>
                                            </div>




                                        </form>
                                    </div>

                                    <div class="marg-t-20">

                                        <button type="button" class="btn model_SaveBtn"
                                            (click)="workoutQuestionSave()">Save</button>
                                        <button type="button" class="btn model_CancelBtn marg-l-16"
                                            data-dismiss="modal">Cancel</button>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <!-- Button trigger modal -->

                    <!-- Modal -->
                    <div class="modal fade" data-toggle="modal" id="addExerciseModel" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="" style="padding: 10px;">
                                    <span class="modal-title font_size22 clr_black font_bold_gilroy">Add
                                        Exercise</span>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form [formGroup]="exerciseRegForm" name="form2" id="form-id">
                                        <div>

                                            <div class="form-group">
                                                <label class="fsize16 clr_black font_bold_gilroy">Placeholder *
                                                </label>
                                                <input type="text" id="examples1" [(ngModel)]="placeholderAdd_exercise"
                                                    formControlName="placeholderExercise"
                                                    class="form-control input_bdr_clr " />

                                                <div *ngIf="submittedExercise && exerciseRegForm.get('placeholderExercise').errors"
                                                    class="alert alert-danger">
                                                    <div
                                                        *ngIf="exerciseRegForm.get('placeholderExercise').errors.required">
                                                        Placeholder is
                                                        required</div>
                                                </div>


                                            </div>


                                            <div class="form-group">
                                                <label class="fsize16 clr_black font_bold_gilroy">Answer
                                                    label *</label>
                                                <input type="text" id="questions1" [(ngModel)]="answer_Label"
                                                    formControlName="answerLabel" class="form-control input_bdr_clr " />

                                                <div *ngIf="submittedExercise && exerciseRegForm.get('answerLabel').errors"
                                                    class="alert alert-danger">
                                                    <div *ngIf="exerciseRegForm.get('answerLabel').errors.required">
                                                        Answer label is required</div>
                                                </div>


                                            </div>

                                            <div class="form-group" style="margin-bottom: 12px;">

                                                <label class="fsize16 clr_black font_bold_gilroy">File Upload *
                                                </label>

                                                <input type="file" class="form-control input_bdr_clr margin_b_14 h-40"
                                                    style="padding: 6px;" id="filePicker"
                                                    formControlName="exercisefileUpload"
                                                    (change)="handleFileSelect($event)">

                                                <div *ngIf="submittedExercise && exerciseRegForm.get('exercisefileUpload').errors"
                                                    class="alert alert-danger">
                                                    <div
                                                        *ngIf="exerciseRegForm.get('exercisefileUpload').errors.required">
                                                        File Upload is required</div>
                                                </div>


                                            </div>

                                            <div>
                                                <span class="fsize16 clr_black font_bold_gilroy">Position * : </span>
                                                <span>
                                                    <label for="vehicle1"> First</label>
                                                    <input class="marg-l-8" type="radio" id="vehicle1"
                                                        [(ngModel)]="positionExercise"
                                                        formControlName="exercisePosition" value="1">
                                                </span>

                                                <span>
                                                    <label class="marg-l-8" for="vehicle1"> Last</label>
                                                    <input class="marg-l-8" [(ngModel)]="positionExercise"
                                                        formControlName="exercisePosition" type="radio" id="vehicle1"
                                                        value="0">
                                                </span>

                                                <div *ngIf="submittedExercise && exerciseRegForm.get('exercisePosition').errors"
                                                    class="alert alert-danger">
                                                    <div
                                                        *ngIf="exerciseRegForm.get('exercisePosition').errors.required">
                                                        Position is required</div>
                                                </div>



                                            </div>

                                        </div>
                                    </form>



                                    <div class="marg-t-20">
                                        <button type="button" class="btn  model_SaveBtn" (click)="addExerciseSave()"
                                            id="addExerciseModels1">Save</button>
                                        <button type="button" class="btn marg-l-16 model_CancelBtn"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
    <div>
        <div *ngIf="showaddExerciceBtn ">
            <div class="marg-t-70 fsize27 font_bold_gilroy" *ngIf="workoutQuestion_All.length > 0">
                WorkOut Question
            </div>

            <div class="marg-t-30">
                <div class="" style="background-color: #fff; border-radius: 8px;">
                    <table class="row-border hover table w-100" datatable *ngIf="workoutQuestion_All.length > 0">
                        <thead>
                            <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                <th>S.No</th>
                                <th>Question</th>
                                <th>Question
                                    Type</th>
                                <th>Placeholder Text</th>
                                <th>Position</th>

                                <th style="width: 150px;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class=" font_medium_gilroy" *ngFor="let item of workoutQuestion_All;let i=index">
                                <td>{{i+1}}</td>
                                <td [innerHTML]="item.question"></td>
                                <td>{{item.question_type}}</td>
                                <td [innerHTML]="item.placeholder"></td>
                                <td>{{item.position}}</td>

                                <td>
                                    <!-- <span class="cursor_pointer" (click)="questionView(item)"><img height="26px"
                                            width="26px" class="" src="assets/View_icon.png" alt=""></span> -->

                                    <span class="marg-l-10 cursor_pointer"><img height="26px" width="26px" class=""
                                            src="assets/Edit_icon.png" (click)="questionEdit(item)" alt=""></span>

                                    <span class="marg-l-10 ">

                                        <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                            data-target="#exampleModaldelete" (click)="deleteQuestion(item)"> <img
                                                height="26px" width="26px" class="" src="assets/Delete_icon.png" alt="">
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="modal fade" id="exampleModaldelete" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                                    <div class="fsize16 marg-t-16">Are you sure you want to delete this question ?
                                    </div>
                                </div>
                                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                        (click)="questionSubmit()">Delete</button>
                                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <!-- ----------------Exercise Table-------------------------- -->

    <div>
        <div *ngIf="showaddExerciceTable ">
            <div class="marg-t-70 fsize27 font_bold_gilroy" *ngIf="workoutExercise_All.length > 0">
                WorkOut Exercise
            </div>

            <div class="marg-t-30">
                <div class="" style=" background-color: #fff; border-radius: 8px;">
                    <table class="row-border hover table w-100" datatable *ngIf="workoutExercise_All.length > 0">
                        <thead>
                            <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                <th>S.No</th>
                                <th>Type</th>
                                <th>Answer Label </th>
                                <th>Placeholder</th>
                                <th>Position</th>

                                <th style="width: 150px;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class=" font_medium_gilroy" *ngFor="let item of workoutExercise_All ; let i=index">
                                <td>{{i+1}}</td>
                                <td>{{item.type}}</td>
                                <td>{{item.answer_label}}</td>
                                <td>{{item.placeholder}}</td>
                                <td>{{item.position}}</td>

                                <td>
                                    <!--
                                    <span class="cursor_pointer"><img height="26px" (click)="exerciseView(item)"
                                            width="26px" class="" src="assets/View_icon.png" alt=""></span> -->

                                    <span class="marg-l-10 cursor_pointer"><img height="26px" width="26px" class=""
                                            (click)="exerciseEdit(item)" src="assets/Edit_icon.png" alt=""></span>

                                    <span class="marg-l-10 ">

                                        <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                            data-target="#exercise_delete"> <img height="26px" width="26px" class=""
                                                src="assets/Delete_icon.png" alt="" (click)="delete_Exercise(item)">
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>


                    </table>

                    <div class="modal fade" id="exercise_delete" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                                    <div class="fsize18 marg-t-16"> Are you sure you want to delete this exercise?
                                    </div>
                                </div>
                                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                        (click)="exerciseUpdateSubmit()">Delete</button>
                                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>





</div>