<div class="padd-0-25">


    <div *ngIf="showLoaderDiv">

        <div id="pause" class="d-flex align-items-center justify-content-center">
            <div id="spinner"></div>
        </div>

    </div>

    <div class="marg-t-70 fsize27 font_bold_gilroy">
        College Portal
    </div>

    <div class="f-right ">
        <button class="add_btn" (click)="studentPortalAdd()">Add</button>

    </div>

    <div class="marg-t-30">
        <div class="" style="background-color: #fff; border-radius: 8px;">

            <table id="myTable" datatable class="row-border hover table w-100" *ngIf="datas.length > 0">
                <thead>
                    <tr class="font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No.
                        </th>
                        <th> College Name </th>
                        <th> College Code </th>
                        <th> City</th>

                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor="let data of datas; let i=index" class=" font_medium_gilroy">
                        <td>{{i+1}}</td>

                        <td>{{data.name}}</td>
                        <td>{{data.college_code}}</td>

                        <td>{{data.address}}</td>

                        <td style="width:120px ;">

                            <!-- <span (click)="studentPortalview(data)" ><img height="22px" width="22px" class="bgr_black" src="assets/View_icon.png"
                                alt=""></span>  -->
                            <span class="marg-l-10" (click)="studentPortalEdit(data)"><img height="22px" width="22px"
                                    class="bgr_black" src="assets/Edit_icon.png" alt=""></span>

                            <span class="marg-l-10"> <button type="button" class="border_clr_none" data-toggle="modal"
                                    data-target="#exampleModal"> <img height="22px" width="22px" class="bgr_black"
                                        src="assets/Delete_icon.png" alt="" (click)="studentDeleteId(data)"> </button>

                            </span>

                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                            <div class="font_regular_gilroy">
                                Do you Want to Delete College ?
                            </div>

                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                (click)="studentDeleteApi()">Submit</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>