import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-corporate-portal-add',
  templateUrl: './corporate-portal-add.component.html',
  styleUrls: ['./corporate-portal-add.component.scss']
})
export class CorporatePortalAddComponent implements OnInit {
  corporateName: any;
  corporateAddress: any;
  isShown: boolean = false;
  back_url = "/corporate_List";
  is_active: any;
  registerForm: FormGroup;
  submitted = false;
  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        corporateName: ["", Validators.required],
        corporateaddress: ["", Validators.required],
        corporateStatus: ["", Validators.required],
      },
    );
  }
  cancelCorporate() {
    this.router.navigate([this.back_url]);
  }
  async submitCorporate() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var postapi = "/add_and_update_corporate";
    var couponAddData = {
      "corporate_name": this.corporateName,
      "corporate_address": this.corporateAddress,
      "isactive": this.is_active
    };
    (await this.ApiService.postApi(postapi, couponAddData)).subscribe(
      res => {
        if (res.status === 200) {

          swal({
            title: "Success!",
            text: res.message,
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.back_url]);
        }

      }, (error) => {

        var errMsg = '';
        if (typeof error.error.message === 'object') {
          for (var i in error.error.message) {
            errMsg += error.error.message[i] + '<br />';
          }
        } else {
          errMsg = error.error.message
        }


        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });

      }

    );


  }

}
