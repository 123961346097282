import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
declare var window: any;
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-tidbit-master-edit',
  templateUrl: './tidbit-master-edit.component.html',
  styleUrls: ['./tidbit-master-edit.component.scss']
})
export class TidbitMasterEditComponent implements OnInit {
  isShown: boolean = false;
  blogCancel_url = "/tidbit_master";
  container: any[] = [1];
  newArray: {
    base64textString: string,
    order: string,
  }[] = [{ base64textString: "", order: "" }];
  keyValue: any;
  getAllEmotion: any;
  emotionsId: any;
  tidbitEdit_id: any;
  tidbitList: any;
  type_Type: any;
  base64textStringTwo: any;
  orderNo: any;
  tidbitEditIds: any;
  deleteEditId: any;
  is_active: any;
  registerForm: FormGroup;
  submitted = false;
  editRegForm: FormGroup;
  editSubmitted = false;
  formModal: any;
  editFormModel: any;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.tidbitEdit_id = this.ApiService.tidbit_EditId;
    this.tidbitEdit_id = localStorage.getItem("tidbitEmotionId")
    // 
    this.getEmotionListApi();
    this.getlist_tidbitApi();

    this.registerForm = this.fb.group(
      {
        displayorder: ["", Validators.required],
        uploadimage: ["", Validators.required],
      },
    );

    this.editRegForm = this.fb.group(
      {
        editdisplayorder: ["", Validators.required],
        // edituploadimage: ["", Validators.required],
      },
    );

    this.formModal = new window.bootstrap.Modal(

      document.getElementById('exampleModalAdd')

    );

    this.editFormModel = new window.bootstrap.Modal(
      document.getElementById('exampleModalEdit')
    );

    // 


  }

  blogCancelBtn() {
    this.router.navigate([this.blogCancel_url]);
  }


  async addBenefits() {
    this.keyValue = this.container.length + 1;
    this.newArray.push({ base64textString: "", order: "" });
    this.container.push(this.keyValue);
    var allTitle = this.newArray.map((item) => {
      if (item.base64textString != undefined && item.base64textString != '') {
        return { image: item.base64textString, order: item.order }
      }
    })
  }

  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }
  }

  // --------------------------------------

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res.data);
      this.getAllEmotion = res.data
    });
  }
  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;
    console.log(this.emotionsId, 'this.emotionsId...');
  }
  // --------------------------

  async getlist_tidbitApi() {
    var getapi = "/list_tidbit/" + this.tidbitEdit_id;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res);
      if (res.status === 200) {
        this.tidbitList = res.result;
        this.emotionsId = res.result.emotion_id;
        this.orderNo = res.result.tidbit.order;
        var tidbit = res.result.tidbit;
        console.log('tidbit', tidbit);
        var isActive = res.result.isactive;

        if (isActive == 1) {
          this.is_active = "1"
        }
        if (isActive == 2) {
          this.is_active = "2"
        }

        // tidbit
      }
    });

  }

  // ------------Thumbnail Image-------------------------
  handleFileSelectOne(evt) {
    var files = evt.target.files;
    var file = files[0];
    this.type_Type = file.type

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoadedOne.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoadedOne(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringTwo = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
  }
  edit_tidbit(items: any) {
    this.editFormModel.show();
    this.tidbitEditIds = items.id;
    this.orderNo = items.order_no;
    this.base64textStringTwo = items.image;
  }

  updateSubmit() {


    this.editSubmitted = true;
    if (this.editRegForm.invalid) {
      return;
    }



    //   var nums = this.tidbitList.tidbit.some(o => o.order_no == this.orderNo);
    //   if (nums) {
    // swal({
    //       title: "error !",
    //       text: "Order Numbers Should not be repeated ",
    //       type: "error",
    //     }, function () {
    //       alert('yo');
    //     });

    //   }
    //   else {
    //   }

    this.tidbitList.tidbit.forEach((item, index) => {
      if (item.id == this.tidbitEditIds) {
        item.order_no = this.orderNo;
        item.image = this.base64textStringTwo
      }
    })

    $("#exampleModalEdit .close").click()
    this.editFormModel.hide();


  }

  add_Submit() {

    // exampleModalAdd


    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    var nums = this.tidbitList.tidbit.some(o => o.order_no == this.orderNo);
    if (nums) {

      swal({
        title: "error !",
        text: "Order Numbers Should not be repeated ",
        type: "error",
      }, function () {
        alert('yo');
      });

    } else {

      this.tidbitList.tidbit.push({ image: this.base64textStringTwo, order_no: this.orderNo });
      console.log(this.tidbitList.tidbit, 'this.tidbitList.tidbit');

      $("#exampleModalAdd .close").click()
      this.formModal.hide();


    }
    console.log(nums, 'nums...arrayList');

    // var orderNum = [...(new Set(nums))].length

    //     if (this.orderNo == nums) {
    //       swal({
    //         title: "error !",
    //         text: "Order Number Is Not Repeated ",
    //         type: "error",
    //       }, function () {
    //         alert('yo');
    //       });

    //     }
    //     else if(this.orderNo == nums){
    // console.log('not repeated');

    //     }




    // var nums =   this.tidbitList.tidbit.map(o => o.order_no);
    // console.log(nums,'nums...arrayList');

    // var orderNum = [...(new Set(nums))].length

    //     if (this.orderNo == nums.length) {
    //       swal({
    //         title: "error !",
    //         text: "Order Number Is Not Repeated ",
    //         type: "error",
    //       }, function () {
    //         alert('yo');
    //       });

    //     }
    //     else if(this.orderNo != nums.length){
    // console.log('not repeated');


    //     }
  }

  async updateEditApi() {

    var putApi = "/add_and_update_tidbit/" + this.tidbitEdit_id;
    var exerciseUpdatedata = {
      "emotion_id": this.emotionsId,
      "tidbit": this.tidbitList.tidbit,
      "isactive": this.is_active

    };
    console.log(exerciseUpdatedata, 'exerciseUpdatedata');
    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Tidbit Updated Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.blogCancel_url]);
          }
          else if (res.status === 401) {

          }
        }
      });
  }

  deleteItems(item: any) {
    this.deleteEditId = item.id
  }
  deleteSubmit() {
    this.tidbitList.tidbit = this.tidbitList.tidbit.map((item, index) => {
      if (item.id == this.deleteEditId) {
        return { ...item, delete: this.deleteEditId };
      }
      else {
        return item;
      }
    })
  }


  tidbitAddPopup() {
    this.formModal.show();
  }



}
