import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-exercise-workout-view',
  templateUrl: './exercise-workout-view.component.html',
  styleUrls: ['./exercise-workout-view.component.scss']
})
export class ExerciseWorkoutViewComponent implements OnInit {

  isShown: boolean = false;
  answerLabel = "";
  place_holder = "";
  file_upload = "";
  position_Exercise = "";
  workout_ExerciseIds = "";
  questionworkoutAdd_ids = "";
  redirectLogin_url = "/"

  constructor(private ApiService: ApiService ,private router: Router) { }

  async ngOnInit() {

    this.workout_ExerciseIds = this.ApiService.exerciseWorkoutView_id;
    
    this.questionworkoutAdd_ids  = this.ApiService.workout_day_ids;  

    var id_view = localStorage.getItem('exerciseViewId');
    var idworkout = localStorage.getItem('inserkeyId');
    var getapi = "/workout/get_questions?"+"day_id="+ this.questionworkoutAdd_ids +"&type=exercise";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Your session has been expired!",
          type: "error"
        }, function () {
          alert('yo');
        });
        }


      var myArray = res.data;
      for (var i = myArray.length; i--;) {
        var item = myArray[i];
        var ids = item.id
        if (ids == this.workout_ExerciseIds) {
          this.answerLabel = item.answer_label;
          this.place_holder = item.placeholder;
          // this.file_upload = item.placeholder;
          this.position_Exercise = item.position;
        }
      }
    });
  }

}
