<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">
        <div class="header-body">
            <div class="padd-0-80">
                <div class="  ">
                    <div class="row marg-0">

                        <div class="marg-t-50">

                            <div class="coach-name">
                                <h5>Availability for {{coach_name}}</h5>
                            </div>

                            <div class="calendar_bdr">
                                <div class="font_clr_white text-center fsize30"
                                    style="padding-top: 40px;font-size: 18px;">
                                    Pick a Date
                                </div>
                                <div class="font_clr_white text-center fsize30 mt-5">
                                    <div (click)="$event.stopPropagation()">
                                        <mat-calendar #calendar (selectedChange)="select($event, calendar)"
                                            [dateClass]="isSelected" [dateFilter]="myFilter">
                                        </mat-calendar>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="marg-t-50">
                            <div class="card_s marg-l-50">
                                <div class="padd-l-60 padd-t-36">
                                    <div class="card_title">
                                        <div class="font_clr_black  fsize23 font_family_Gilroy"
                                            style="font-size: 18px;">
                                            Slot Type
                                        </div>

                                        <div class="marg-t-20">
                                            <div class="row marg-0">
                                                <div>
                                                    <button id="single_date" value="single_date" class="select_btn"
                                                        style="font-size: 18px;"
                                                        [ngClass]="{'highlight': selectedButton == 'single_date'}"
                                                        (click)="selectButton('single_date')">Select Single</button>
                                                </div>
                                                <div class="marg-l-20">
                                                    <button id="multiple_date" value="multiple_date" class="select_btn"
                                                        style="font-size: 18px;"
                                                        [ngClass]="{'highlight': selectedButton == 'multiple_date'}"
                                                        (click)="selectButton('multiple_date')">Select Multiple</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="marg-t-44 font_clr_black fsize23 font_family_Gilroy"
                                            style="font-size: 18px;">
                                            Duration of session

                                        </div>


                                        <div class="marg-t-20">
                                            <div class="row marg-0">
                                                <div>
                                                    <button id="thirty" value="00:30:00" class="duration_btn"
                                                        style="font-size: 18px;"
                                                        [ngClass]="{'highlight': selectedDuration == '00:30:00'}"
                                                        (click)="selectDuration('00:30:00')">30 mins</button>
                                                </div>
                                                <div class="marg-l-20">
                                                    <button id="sixty" value="01:00:00" class="duration_btn"
                                                        style="font-size: 18px;"
                                                        [ngClass]="{'highlight': selectedDuration == '01:00:00'}"
                                                        (click)="selectDuration('01:00:00')">60 mins</button>
                                                </div>
                                            </div>


                                            <div class="marg-t-32 font_clr_black fsize23 font_family_Gilroy"
                                                *ngFor="let timeSelection of timeSelections; let i = index"
                                                class="marg-t-20">
                                                <div class="row marg-0">
                                                    <div class="start-time-selection">
                                                        <div class="font_clr_black fsize23 font_family_Gilroy"
                                                            style="font-size: 18px;">Select
                                                            Start Time</div>
                                                        <div class="time-selection">
                                                            <select style="height: 40px; width: 50px;"
                                                                [(ngModel)]="timeSelection.startHour"
                                                                (change)="onStartTimeChange(i, 'startHour', $event.target.value)">
                                                                <option value="" disabled selected
                                                                    style="font-size: 18px;">HRS</option>
                                                                <option *ngFor="let hour of hours" [value]="hour">{{
                                                                    hour }}</option>
                                                            </select> :
                                                            <select style="margin-left: 1px; height: 40px; width: 50px;"
                                                                [(ngModel)]="timeSelection.startMinute"
                                                                (change)="onStartTimeChange(i, 'startMinute', $event.target.value)">
                                                                <option value="" disabled selected
                                                                    style="font-size: 18px;">MINS</option>
                                                                <option *ngFor="let minute of minutes" [value]="minute">
                                                                    {{ minute }}</option>
                                                            </select> :
                                                            <select style="margin-left: 1px; height: 40px; width: 70px;"
                                                                [(ngModel)]="timeSelection.startPeriod"
                                                                (change)="periodAm($event, i)">
                                                                <option value="" disabled selected
                                                                    style="font-size: 18px;">AM/PM</option>
                                                                <option value="AM">AM</option>
                                                                <option value="PM">PM</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="end-time-selection">
                                                        <div class="font_clr_black fsize23 font_family_Gilroy"
                                                            style="font-size: 18px;">Select
                                                            End Time</div>
                                                        <div class="time-selection">
                                                            <select style="height: 40px; width: 50px;"
                                                                [(ngModel)]="timeSelection.endHour"
                                                                (change)="onStartTimeChange(i, 'endHour', $event.target.value)">
                                                                <option value="" disabled selected
                                                                    style="font-size: 18px;">HRS</option>
                                                                <option *ngFor="let hour of hours" [value]="hour">{{
                                                                    hour }}</option>
                                                            </select> :
                                                            <select style="margin-left: 1px; height: 40px; width: 50px;"
                                                                [(ngModel)]="timeSelection.endMinute"
                                                                (change)="onStartTimeChange(i, 'endMinute', $event.target.value)">
                                                                <option value="" disabled selected
                                                                    style="font-size: 18px;">MINS</option>
                                                                <option *ngFor="let minute of minutes" [value]="minute">
                                                                    {{ minute }}</option>
                                                            </select> :
                                                            <select style="margin-left: 1px; height: 40px; width: 70px;"
                                                                [(ngModel)]="timeSelection.endPeriod"
                                                                (change)="periodPm($event, i)">
                                                                <option value="" disabled selected
                                                                    style="font-size: 18px;">AM/PM</option>
                                                                <option value="AM">AM</option>
                                                                <option value="PM">PM</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <button *ngIf="timeSelections.length > 1"
                                                        (click)="removeTimeSelection(i)"
                                                        class="border_clr_none cursor_pointer no-outline">
                                                        <img height="22px" style="margin-top: 10px;" width="22px"
                                                            class="bgr_black" src="assets/Delete_icon.png" alt="">
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="marg-t-20 padd-b-32 add_availability">
                                                <button (click)="addTimeSelection()" class="add_time no-outline"
                                                    style="font-size: 18px;" style="cursor: pointer;">+ Add Slots
                                                </button>
                                            </div>


                                            <div>
                                                <div class="marg-t-32 font_clr_black fsize23 font_family_Gilroy"
                                                    style="font-size: 18px;">
                                                    Appointment cut-off time
                                                </div>
                                                <div class="marg-t-20">
                                                    <div class="row marg-0">
                                                        <div *ngIf="isCutoffAvailable(1)">
                                                            <button id="twentyFourHours" value="1"
                                                                class="cuttoff_time_btn"
                                                                [ngClass]="{'highlight': selectedCutoff == '1'}"
                                                                (click)="selectCutoff('1')">1 hour before</button>
                                                        </div>
                                                        <div *ngIf="isCutoffAvailable(4)">
                                                            <button id="twentyFourHours" value="4"
                                                                class="cuttoff_time_btn"
                                                                [ngClass]="{'highlight': selectedCutoff == '4'}"
                                                                (click)="selectCutoff('4')">4 hours before</button>
                                                        </div>
                                                        <div *ngIf="isCutoffAvailable(12)">
                                                            <button id="twelveHours" value="12" class="cuttoff_time_btn"
                                                                [ngClass]="{'highlight': selectedCutoff == '12'}"
                                                                (click)="selectCutoff('12')">12 hours before</button>
                                                        </div>
                                                        <div *ngIf="isCutoffAvailable(0)">
                                                            <button id="noHour" value="0" class="cuttoff_time_btn"
                                                                [ngClass]="{'highlight': selectedCutoff == '0'}"
                                                                (click)="selectCutoff('0')">No Cutoff time</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="marg-t-40 padd-b-32 submit">
                                            <button class="confirm_btn" style="cursor: pointer;"
                                                (click)="slotbooking()">Confirm</button>
                                            <button type="submit" class="btn-back"
                                                (click)="back_management()">Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- -------------------------content------------------------------------------- -->
                        <div>
                            <!-- <div class="text-center marg-t-30 padd-b-90">
                                <button class="done_btn" type="button">Update availability</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>