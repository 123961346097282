import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-forum-management',
  templateUrl: './forum-management.component.html',
  styleUrls: ['./forum-management.component.scss']
})
export class ForumManagementComponent implements OnInit {
  show_topic_div = false;
  show_moderation_div = false;
  topicsName : any;
  descriptions : any;
  base64textString: any;
  constructor(private router: Router,private ApiService: ApiService ) { }
  feedListPage_url = '/forum-feedlist';
  userListPage_Url = '/forum-userlist-page';
  ngOnInit(){
  }
  topics() {
 this.show_topic_div = true;
 this.show_moderation_div = false;
  }
  moderation(){
    this.show_topic_div = false;
    this.show_moderation_div = true;
  }
  feedList(){
    this.router.navigate([this.feedListPage_url]);
  }
  userList(){
    this.router.navigate([this.userListPage_Url]);
  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }




  async CommunityTopicSubmit(){
    var postapi = "/admin_add_community";
    let formData: FormData = new FormData();
    formData.append('topic_name', this.topicsName);
    formData.append('description', this.descriptions);
    formData.append('file', this.base64textString );

  (await this.ApiService.postApi(postapi, formData)).subscribe(
      res => {
 
      });
  }

}
