import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-assestmentresult',
  templateUrl: './assestmentresult.component.html',
  styleUrls: ['./assestmentresult.component.scss']
})
export class AssestmentresultComponent implements OnInit {

  constructor(private router: Router, private ApiService: ApiService) { }
  assestmentAdd_url = "/assestment_add_results";
  assestmentEdit_url = "/assestment_edit_results";
  assestmentList: any;
  deleteAssestmentId: any;
  showLoaderDiv = false;
  ngOnInit(): void {
    this.getlist_assestmentApi();
  }

  assestmentAdd() {
    this.router.navigate([this.assestmentAdd_url]);
  }

  assestmentEdit(items: any) {
    this.ApiService.assestmentEditId = items.id;
    localStorage.setItem("assestmentEditIds", items.id)
    this.router.navigate([this.assestmentEdit_url]);
  }

  async getlist_assestmentApi() {

    this.showLoaderDiv = true;

    var getapi = "/list_assessment_result";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.assestmentList = res.result;
        this.showLoaderDiv = false;

      }
    });

  }

  deleteAssestment(items: any) {
    this.deleteAssestmentId = items.id
  }

  async exercisesDelete() {
    var deleteApi = "/delete_assessment_result/" + this.deleteAssestmentId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      if (res.status === 200) {
        this.getlist_assestmentApi();

      }
    });
  }




}
