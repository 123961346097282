<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/corporate_List']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Corporate Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form [formGroup]="registerForm">

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Corporate Name *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="corporateName"
                        formControlName="corporateName" maxlength="150" />
                          
                            <div *ngIf="submitted && registerForm.get('corporateName').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('corporateName').errors.required">Corporate Name is required</div>
                            </div>

                    </div>

                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">Corporate Address *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="corporateAddress"
                        formControlName="corporateaddress"   maxlength="200"  />

                            <div *ngIf="submitted && registerForm.get('corporateaddress').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('corporateaddress').errors.required">Corporate Address is required</div>
                            </div>

                    </div>
                    <div class="marg-t-20" >
                        <span class="fsize16 clr_black font_bold_gilroy">Status * :
                        </span>
                        <span>
                            <label for="vehicle1"> Active</label>
                            <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs"
                            [(ngModel)]="is_active" formControlName="corporateStatus"
                            value="1">
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1">In Active</label>
                            <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" 
                            [(ngModel)]="is_active" formControlName="corporateStatus"
                            value="2">
                        </span>
                        <div *ngIf="submitted && registerForm.get('corporateStatus').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('corporateStatus').errors.required">Status is required</div>
                        </div>
                    </div>
                   
 


                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr" (click)="updateCorporate()" >Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                        (click)="cancelCorporate()"
                            >Cancel</button>
                    </div>

                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>