<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Coach - View Page
    </div>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Title</label>
                        <input type="text" disabled 
                            class="form-control input_bdr_clr font_text" />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Name</label>
                        <input type="text" disabled  [(ngModel)]="coachViewName" [ngModelOptions]="{standalone: true}"
                            class="form-control input_bdr_clr font_text" />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">License Number</label>
                        <input type="text" [(ngModel)]="coach_ViewLicenceNo" [ngModelOptions]="{standalone: true}" disabled class="form-control input_bdr_clr font_text" />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Area of Expertise</label>
                        <input type="text" disabled  [(ngModel)]="coach_ViewareaOfExpertise" [ngModelOptions]="{standalone: true}"
                            id="questions" class="form-control input_bdr_clr font_text " />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Types of Therapy/Methodology</label>
                        <input type="text" disabled  [(ngModel)]="coach_View_typeOfTherapy" [ngModelOptions]="{standalone: true}"
                            id="questions" class="form-control input_bdr_clr font_text" />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Years of Experience</label>
                        <input type="text" disabled  [(ngModel)]="coach_View_Experience" [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr font_text" />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Accrediting Body</label>
                        <input type="text"  disabled [(ngModel)]="coach_ViewAccredting_Body" [ngModelOptions]="{standalone: true}"
                            class="form-control input_bdr_clr font_text " />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Upload Certificate / Training Item</label>
                        <input type="text"  disabled
                            class="form-control input_bdr_clr font_text " />
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>