<div class="add_subs">
  <div class="marg-t-70 marg-l-40">
    <nav aria-label="breadcrumb ">
      <ol class="breadcrumb fsize27  " style="padding-left: 32px;">
        <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
            [routerLink]="['/subscription']">Back</a>
        </li>
        <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Add Subscription</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-2"> </div>
      <div class="col-8">
        <div class="card">
          <div class="card-body">

            <form [formGroup]="subscriptionForm">

              <div class="mb-3">
                <div>
                  <div>
                    Name:<span style="color: red;">*</span>
                  </div>

                  <div class="mt-2">
                    <input type="text" class="input_brdr" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="subscription_name" required>
                    <span class="text-danger" *ngIf="nameError">Mandatory to Fill</span>

                  </div>

                  <div>
                    Description:<span style="color: red;">*</span>
                  </div>
                  <div class="mt-2">
                    <input type="text" class="input_brdr" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="subscription_description" required>
                    <span class="text-danger" *ngIf="descriptionError">Mandatory to Fill</span>
                  </div>

                  <div class="row mt-3 mb-3">

                    <div class="col-6">
                      <div>
                        Duration:<span style="color: red;">*</span>
                      </div>
                      <div class="">
                        <div>
                          <select class="input_brdr mt-2" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="subscription_duration" required>
                            <option value="complementary">Complementary</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="yearly">Yearly</option>
                          </select>
                          <span class="text-danger" *ngIf="durationError">Mandatory to select</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div>
                        Pricing:<span style="color: red;">*</span>
                      </div>
                      <div class="mt-2">
                        <input type="text" class="input_brdr" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="pricing"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          required>
                        <span class="text-danger" *ngIf="pricingError">Mandatory to Fill</span>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="">
                        Status:<span style="color: red;">*</span>
                      </div>
                      <div class="mt-2">
                        <mat-slide-toggle [checked]="true" [(ngModel)]="showToggle"></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div style="margin-top: 40px;">
                <div class="mt-3 d-flex align-items-center justify-content-start">
                  <div class="mx-5 pl-5">Allowed Modules</div>
                  <div class="mx-1">Limit Access</div>
                </div> -->
              <div style="margin-top: 40px;">
                <div class="row mb-6">
                  <span class="col-md-2"></span>
                  <span class="col-md-4">Allowed Modules</span>
                  <span class="col-md-6 px-0">Limit Access</span>
                </div>


                <div class="mt-3">
                  <div *ngFor="let module of subscriptionModule; let i = index" class="col-12">
                    <div class="subcheckbox row align-items-center mb-2">

                      <span class="col-md-2">
                        <input class="checkSized" type="checkbox" [checked]="module.module_status"
                          (click)="ModuleSelect($event, i)">
                      </span>
                      <span class="mx-2 col-md-4">{{ module.modules}}</span>
                      <span class="mx-2 col-md-2">
                        <mat-slide-toggle (change)="ModuleLimitChange($event,i)"
                          [checked]="(module.access_limit)?true:false"></mat-slide-toggle>
                      </span>

                      <span *ngIf="module.access_limit" class="mx-0 col-md-2">
                        <input maxlength="2" (keyup)="ModuleLimitCount($event, i)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          style="height: 30px; width: 40px; border-radius: 8px; border: 1px solid #dee2e6; outline: none !important;"
                          type="text" [value]="module.limit_count">
                        <small *ngIf="module.error" class="text-danger"></small>
                      </span>

                      <span *ngIf="module.access_limit" class="col-md-2">
                        <select (change)="ModuleType($event, i)">
                          <option value="">Select</option>
                          <option [selected]="module.limit_type == 'Days' " value="Days">Days</option>
                          <option [selected]="module.limit_type == 'Emotions' " value="Emotions">Emotions</option>
                          <option [selected]="module.limit_type == 'Theraphy' " value="Theraphy">Theraphy</option>
                        </select>
                        <small *ngIf="limitTypeError" class="text-danger">Mandatory to select</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div id="error-message" class="text-danger" style="display: none;">
                At least one checkbox must be selected.
              </div>

              <!-- <span class="text-danger" *ngIf="!atLeastOneCheckboxSelected">hgcghchgc</span> -->

              <!-- <div id="error-message" class="text-danger" *ngIf="!atLeastOneChecked">
                At least one checkbox must be selected.
              </div> -->
              <!-- <div class="error-message" *ngIf="!atLeastOneChecked">At least one checkbox must be checked</div> -->


              <div class="row mt-4 mb-1">
                <div class="col-6">
                  <div>
                    <button class="submit_btn_clr" (click)="addSubscription()">Save</button>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <button class="cancel_Btn" (click)="cancel_submission()">Cancel</button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
      <div class="col-2"> </div>
    </div>

    <div class="row mt-5">
      <div class="col-3">
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-3"></div>
          <div class="col-6">
          </div>
          <div class="col-3"></div>
        </div>
      </div>
      <div class="col-3">
      </div>
    </div>
  </div>
</div>