<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/happiness-test-result']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Edit Overall results</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                <div class="">
                    <form>
                        
                        <div class="form-group">
                            <!-- <label class="fsize16 clr_black font_bold_gilroy"> Category*</label>
                            <select disabled (change)="happinessitem($event)" style="width:400px;"
                                class=" form-control input_bdr_clr ">
                                <option [selected]="range_ID == item.id" [value]="[item.id]"
                                    *ngFor="let item of gethappinesscategory">
                                    {{item.category_name}} </option>
                            </select> -->
                        </div>

                        <!---------------------------------------------------------------------------->

                        <div>
                            <table id="myTable" datatable class="row-border hover table w-100">
                                <thead>
                                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                        <th>S.No</th>
                                        <th>Interpretation</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="font_medium_gilroy" *ngFor="let item of container; let i = index">
                                        <td *ngIf="!item.delete">{{i+1}}</td>

                                        <td *ngIf="!item.delete">
                                            <input [(ngModel)]="item.description" maxlength="200"
                                                [ngModelOptions]="{standalone: true}" style="width: 300px;">
                                        </td>

                                        <td *ngIf="!item.delete">
                                            <span class="marg-l-10 cursor_pointer" data-toggle="modal"
                                                data-target="#exampleModalDelete" (click)="happinessEditDelete(item,i)">
                                                <button type="button" class="border_clr_none cursor_pointer">
                                                    <img  height="22px" width="22px" class="bgr_black"
                                                    src="assets/Delete_icon.png" alt="">
                                                </button>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- -------------------------------------------------------------------->

                        <div class="modal fade" id="exampleModalDelete" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <div class="fsize18 font_bold_gilroy "> Delete Confirmation ! </div>
                                        <div>
                                            Are you sure ! you want to delete this record ?
                                        </div>
                                    </div>
                                    <div class=" row justify-content-center  font_bold_gilroy"
                                        style="margin-top:20px; margin-bottom:20px;">
                                        <button type="button" class="btn submit_btn_clr" data-dismiss="modal"
                                            (click)="delete_submit()">Delete</button>
                                        <button type="button" class="marg-l-16 cancelBtn_clr fsize16"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!------------------------------------------------------------------------------>

                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>
                            </div>
                        </div>

                        <!------------------------------------------------------------------------------>

                        <div class="form-group" style="margin-top:100px;">
                            <button (click)="happinessUpdateSubmit()" class="btn submit_btn_clr">Save</button>
                            <button type="button" (click)="happinessBackUrl()"
                                class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
        </div>
    </div>
</div>