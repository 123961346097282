<div class="padd-0-40">
  <div class="marg-t-70">
    <div class="row">
      <div class="col-6">
        <div>
          <h4>Appointment management</h4>
        </div>
      </div>
      <div class="col-6 add_button">
        <div class="ft-rig">
          <button class="add_btn" style="cursor:pointer" (click)="add_appointment()">Add</button>
        </div>
      </div>
    </div>

  </div>
  <div class="marg-t-30">
    <div class="">
      <div class="row">
        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 mb-3">
          <div style="border-bottom:1px solid #000; " (click)="openDatePicker(picker1)">
            <mat-form-field>
              <input matInput [matDatepicker]="picker1" [(ngModel)]="fromDate" [ngModelOptions]="{standalone: true}"
                placeholder="Choose a Start  Date">
              <!-- <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle> -->
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 mb-3">
          <div style="border-bottom:1px solid #000;  " (click)="openDatePicker(picker2)">
            <mat-form-field>
              <input matInput [matDatepicker]="picker2" [(ngModel)]="toDate" [ngModelOptions]="{standalone: true}"
                placeholder="Choose a End Date">
              <!-- <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle> -->
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 mb-3">
          <div style="border-bottom:1px solid #000;">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="manualSearchQuery" [ngModelOptions]="{standalone: true}"
                (input)="handleManualSearch($event)" placeholder="search by coach,user name or email">
            </mat-form-field>
          </div>
        </div>

        <div class="searchBox">
          <div style="margin-top:8px; ">
            <Button class="submit_btn_clr w-100" style="cursor:pointer;" (click)="datapickerFun()"
              [(ngModel)]="getAppointmentId" [ngModelOptions]="{standalone: true}">Search</Button>
          </div>
        </div>

        <button class="Reset" style="cursor:pointer;" (click)="resetFilters()">Reset</button>


        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 mb-3">
          <div class="" *ngIf="getAllAppointmentListNew.length != 0">
            <button class="appointment-download w-100" (click)="exportToExcel()">Download Appointment List</button>
          </div>
        </div>
      </div>
      <div class="marg-t-20">
        <div style="background-color: #fff; border-radius: 8px; width:100%;overflow-x: auto;">
          <!-- Loader -->
          <div *ngIf="loading" class="loader-container">
            <div class="loader"></div>
          </div>

          <!-- Table -->
          <table class="font_regular_gilroy TFtable row-border hover table w-100" id="myTable"
            *ngIf="!loading && getAllAppointmentList.length > 0" style="width: 100%; background-color: #fff;">

            <!-- Table Header -->
            <thead>
              <tr style="border-bottom: 2px solid #dee2e6;">
                <th>S.No</th>
                <th>Coach Name</th>
                <th>User Name</th>
                <th>Amount</th>
                <th>Code</th>
                <th>Email</th>
                <th>Contact No</th>
                <th>Appt Date</th>
                <th>Next Session</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Booked Date</th>
                <th>Action</th>
                <th>View</th>
              </tr>
            </thead>

            <!-- Table Body -->
            <tbody>
              <tr *ngFor="let item of getAllAppointmentList; let i = index;">
                <td>{{(currentPage - 1) * entriesPerPage + i + 1}}</td>
                <td>{{item.coach_name}}</td>
                <td>{{item.user_name}}</td>
                <td>{{item.amount}}</td>
                <td>{{item.coupon_code !== null ? item.coupon_code : 'N/A'}}</td>
                <td>{{item.user_email}}</td>
                <td>{{item.user_mobile}}</td>
                <td>{{item.date}} {{item.time}}</td>
                <td style="text-align: center;">{{item.follow_up_date === '00-00-0000 12:00 AM' ? '-' :
                  item.follow_up_date}}</td>
                <td>{{item.duration}}</td>
                <td>
                  <span
                    [ngClass]="{'green-text': item.appointment_status === 'Booked', 'red-text': item.appointment_status === 'Cancelled'}">
                    {{item.appointment_status}}
                  </span>
                  <span *ngIf="item.appointment_status == 'Cancelled'">
                    <span *ngIf="item.mby == item.coach_id">-By Coach</span>
                    <span *ngIf="item.mby == item.user_id">By User</span>
                  </span>
                </td>
                <td>{{item.booked_datetime !== null ? item.booked_datetime : '--' }}</td>
                <td>
                  <!-- Export Button -->
                  <span class="download_cursor" (click)="exportToSingleExcel(item)">
                    <img class="download" style="height: 20px; width: 20px; margin-left: 10px; margin-top: 10px;"
                      src="assets/download.png">
                  </span>

                  <!-- Mark as Completed Button (always visible) -->

                  <button class="buttonNoshow" *ngIf="item.appointment_status === 'Noshow'"
                    (click)="openConfirmationModal(item)">MarkasCompleted!
                  </button>
                  <!-- View Details Button -->
                </td>
                <!-- <td>

                  <span class="download_cursor" (click)="exportToSingleExcel(item)">
                    <img class="download" style="height: 20px; width: 20px; margin-left: 10px; margin-top: 10px;"
                      src="assets/download.png">
                  </span>
                  <span>
                    <button class="buttonNoshow" *ngIf="item.appointment_status === 'Noshow'" [(ngModel)]="selectedItem"
                      [ngModelOptions]="{standalone: true}" data-toggle="modal" data-target="#exampleModal"
                      (click)="openConfirmationModal(item)">MarkasCompleted!
                    </button>
                  </span>
                </td> -->
                <td>
                  <span class="download_cursor" (click)="appointment_detail_page(item.id)">
                    <img class="download" style="height: 20px; width: 20px; margin-left: 10px; margin-top: 10px;"
                      src="assets/View_icon.png">
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="!loading && getAllAppointmentList.length > 0"
            style="display:flex;justify-content: space-between; padding-top:20px ;">
            <div *ngIf="!loading && getAllAppointmentList.length > 0" style="margin:0px 0px 0px 35px ;">
              Show
              <select [(ngModel)]="entriesPerPage" (change)="onEntriesPerPageChange()" style="margin-left: 5px;">
                <!-- <option value="5">5</option> -->
                <!-- <option value="10">10</option> -->
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              Entries
              of
              {{totalEntries}}
              total
            </div>
            <div class="pagination-controls" style="display: flex;gap: 20px;">
              <button (click)="prevPage()" [disabled]="currentPage === 1">Previous</button>
              <span>Page {{currentPage}} of {{totalPages}}</span>
              <button (click)="nextPage()" [disabled]="currentPage === totalPages">Next</button>
            </div>
          </div>
          <!-- No Appointments Found -->
          <div *ngIf="!loading && getAllAppointmentList.length == 0">
            <div class="text-center padd_10">No Appointments Found</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
  </div>
</div>