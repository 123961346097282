<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/student-portal-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">College Portal View</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">College Name *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" maxlength="150"
                            [(ngModel)]="collegeName" [ngModelOptions]="{standalone: true}" />
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">College Address *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="collegeAddress"
                            [ngModelOptions]="{standalone: true}" maxlength="150" />
                    </div>

                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>