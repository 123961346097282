<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
       Feed List
    </div>
 
 <div class="marg-t-30" >
        <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn" (click) = "feedAddlist()" >Add</button>

        </div>
        <div class="bdr_clr_table">
            <table class="w-100">
                <tr class="bgr_th font_bold_gilroy">
                    <th>S.No</th>
                    <th>Post Description</th>
                    <th>Post Date</th>
                    <th>Post No of Comments</th>
             
                    <th>Action</th>

                </tr>
                <tr class="bgr_td font_medium_gilroy" >
                    <td>1</td>
                    <td>Description</td>
                    <td> 02/02/2022</td>
                    <td>Comments</td>
                    <td>Delete | Leave</td>
                </tr>
            </table>

            </div>

    </div>
</div>