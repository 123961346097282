import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-community-topic-view',
  templateUrl: './community-topic-view.component.html',
  styleUrls: ['./community-topic-view.component.scss']
})
export class CommunityTopicViewComponent implements OnInit {
  topicsName:any;
  descriptions:any;
  base64textString: any;
  isShown: boolean = false;
  feedList_url = "/community-feedlist-table";
  userList_url = "/community-userlist-table"
  community_Ids : any;
  getCommunityDataList:any;
  community_topicPage = "/community-topic-table"
  constructor(private router: Router,private ApiService: ApiService) { }

  ngOnInit() {
    this.community_Ids  = this.ApiService.communityId_view ;
    this.getcommunityDataListApi();
  }
  feedlistPage(){
    this.router.navigate([this.feedList_url]);
  }
  userlistPage(){
    this.router.navigate([this.userList_url]);
  }

  async getcommunityDataListApi() {
    var postapi = "/get_community";
    var communityGetdata = {
      "id": this.community_Ids
    };
    (await this.ApiService.postApi(postapi, communityGetdata)).subscribe(
      res => {
        this.getCommunityDataList = res.data;

        for(let item of this.getCommunityDataList){
          this.topicsName = item.topic_name;
          this.descriptions = item.description;

        }

      });
  }

  cancelBtn(){
    this.router.navigate([this.community_topicPage]);
  }

}
