import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-expedition-module-add',
  templateUrl: './expedition-module-add.component.html',
  styleUrls: ['./expedition-module-add.component.scss']
})
export class ExpeditionModuleAddComponent implements OnInit {
  moduleItemsId:any;
  moduleTitleList = [];
  module : any;
  isShown: boolean = false;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.getModuleListApi()
  }

  moduleItem(moduleitems){
    this.moduleItemsId = moduleitems;
      }
      async getModuleListApi() {
        var getapi = "/get_expedition_title";
        (await this.ApiService.getApi(getapi)).subscribe((res) => {
          this.moduleTitleList = res.data;
        });
    
      }

}
