import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { title } from 'process';
declare var swal: any;


@Component({
  selector: 'app-happiness-test-editmanagement',
  templateUrl: './happiness-test-editmanagement.component.html',
  styleUrls: ['./happiness-test-editmanagement.component.scss']
})


export class HappinessTestEditmanagementComponent implements OnInit {


  isShown: boolean = false;
  // container: any;
  Category_Id: any;
  container: {
    question: any,
    // strongly_disagree: any,
    // disagree: any,
    // neutral: any,
    // strongly_agree: any,
    // agree: any,
    id: any,
    isactive: any,
  }[] = [{ id: "", question: "", isactive: 1 }];
  keyValue: any;
  gethappinesscategory: any;
  test_Id: any;
  category_id: any;
  category_ID: any;
  happiness_test_list: any;
  happinessQuestionEditId: any;
  happinessDeleteId: any;
  happinessBack_url = "/happiness-test-management"
  happinesstestlist: any;
  selectedDeleteIndex: number;
  showAddButton = false

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.happinessQuestionEditId = this.ApiService.happiness_managementId;
    this.happinessQuestionEditId = localStorage.getItem("category_id")
    this.getEmotionListApi();
    this.getlist_happinessList();
    console.log("sssssssssssss", this.happinessQuestionEditId)

  }


  // --------------------------------------------------------------

  async getEmotionListApi() {
    var getapi = "/listHappinesstestcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.gethappinesscategory = res.result
      console.log("asasasa", this.gethappinesscategory)
    });
  }

  // --------------------------------------------------------------


  async getlist_happinesstestApi() {
    var getapi = "/listHappinesstest";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.happinesstestlist = res.result;
      }
      console.log("aaaaaaaa", this.happinesstestlist);
    });

  }


  // ---------------------------------------------------------------


  async happinessUpdateSubmit() {
    console.log('this.container');

    this.container = this.container.map((item) => {
      console.log(item.id);

      var data = { ...item };
      if (item.id === "") {
        delete data.id;
      }
      return data;
    })

    const data_remain = this.container.filter((data) => { return !data.hasOwnProperty('delete') })

    if (data_remain.length < 4) {
      swal({
        title: 'Error!',
        text: 'Four questions are mandatory',
        type: 'error'
      });
      return;
    }

    var deletedata = this.container.every(item => "delete" in item)
    console.log("deleted_data", deletedata)

    if (deletedata) {
      swal({
        title: 'Error !',
        text: 'Any one of the data is mandatory',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return
    }

    const hasEmptyQuestion = this.container.some(item => !item.id && !item.question.trim());

    if (hasEmptyQuestion) {
      swal({
        title: 'Error!',
        text: 'Newly added rows must have a question',
        type: 'error'
      });
      return;
    }

    var putApi = "/addAndUpdateHapinessTest/" + this.happinessQuestionEditId;
    var exerciseUpdatedata = {
      "test_id": this.test_Id,
      "category_id": this.category_ID,
      "happiness_test": this.container
    };
    console.log(exerciseUpdatedata);
    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Test Question Updated Successfully",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.happinessBack_url]);
          }
        }

      },
      (error) => {
        var errMsg = '';
        if (typeof error.error.message === 'object') {
          for (var i in error.error.message) {
            errMsg += error.error.message[i] + '<br />';
          }
        } else {
          errMsg = error.error.message
        }
        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });

      }
    )
  }



  // ----------------------------------------------------------


  happinessitem(item: any) {
    this.category_ID = item.target.value;
    console.log("sd", this.category_ID)
    if (item.isNew) {
      const index = this.container.indexOf(item);
      if (index !== -1) {
        this.container.splice(index, 1);
      }
    } else {
      item.delete = true;
    }

  }


  // -----------------------------------------------------------


  async addBenefits() {

    const data_remain = this.container.filter((data) => { return !data.hasOwnProperty('delete') })

    if (data_remain.length < 4) {

      this.container.push({ id: "", question: "", isactive: 1 });
      this.container.map((item) => {
        return { question: item.question, isactive: item.isactive }
      })
    } else {
      swal({
        title: 'Error !',
        text: 'Only Four data for per category',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  }


  // ------------------------------------------------------------


  onchange(event: any, index: number) {
    this.container[index].isactive = event.checked ? 1 : 0;
    console.log(this.container[index].isactive)
  }


  // ------------------------------------------------------------


  async removeBenefits(index: any) {
    // if (this.container.length > 1) {
    //   this.container.pop();
    // }
    if (index >= 0 && index < this.container.length) {
      this.container.splice(index, 1);
    }
  }


  // -------------------------------------------------------------


  async getlist_happinessList() {
    var getapi = "/listHappinesstest/" + this.happinessQuestionEditId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.container = res.result.happiness_test;
        this.category_id = res.result.category_id;
        this.test_Id = res.result.id;
        console.log("Aadadada", this.test_Id);
        console.log("ddddddddddddd", this.category_id)
        console.log("data", this.container)
      }
      this.category_ID = this.category_id
      console.log(this.category_ID)
    });
  }


  // ------------------------------------------------------------------


  happinessEditDelete(item: any, index: number) {
    console.log(item, index)
    this.happinessDeleteId = item.id
    console.log(" e", this.happinessDeleteId)
    if (!item.id) {
      this.selectedDeleteIndex = index
    }
  }

  // ------------------------------------------------------------------

  delete_submit() {
    // this.container = this.container.map((item, index) => {
    //   if (item.id == this.happinessDeleteId) {
    //     return { ...item, delete: this.happinessDeleteId };
    //   }
    //   else {
    //     return item;
    //   }
    // })
    // console.log("The container value ", this.container)
    if (this.selectedDeleteIndex !== null && this.selectedDeleteIndex >= 0 && this.selectedDeleteIndex < this.container.length) {
      this.container.splice(this.selectedDeleteIndex, 1);
    }
    
    this.container = this.container.map(item => {
      if (item.id === this.happinessDeleteId) {
        return { ...item, delete: this.happinessDeleteId };
      }
      return item;
    });

  }

  // ------------------------------------------------------------------

  happinessBackUrl() {
    this.router.navigate([this.happinessBack_url]);
  }

}



