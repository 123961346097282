<div class="padd-0-25">
  <div class="marg-t-70 fsize27 font_bold_gilroy">

      <nav aria-label="breadcrumb ">
          <ol class="breadcrumb fsize27 padd_0 ">
              <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                      [routerLink]="['/user-table']">Back</a></li>
              <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">User Edit Page</li>
          </ol>
      </nav>
      <!-- User Edit Page -->
  </div>

  <div class="marg-t-40">

      <div class="row marg-0">
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                      <label class="fsize16 clr_black font_bold_gilroy">Name</label>
                      <input type="text" [(ngModel)]="userName" maxlength="150" formControlName="name"
                          class="form-control input_bdr_clr " />

                      <div *ngIf="submitted && registerForm.get('name').errors" class="alert alert-danger">
                          <div *ngIf="registerForm.get('name').errors.required">Name is required</div>
                      </div>

                  </div>
                  <div class="form-group">
                      <label class="fsize16 clr_black font_bold_gilroy">Mobile</label>
                      <input type="text"  [(ngModel)]="user_MobileNo" maxlength="10" formControlName="mobile"
                          class="form-control input_bdr_clr" />

                      <div *ngIf="submitted && registerForm.get('mobile').errors" class="alert alert-danger">
                          <div *ngIf="registerForm.get('mobile').errors.required">Mobile is required</div>
                      </div>

                  </div>
                  <div class="form-group">
                      <label class="fsize16 clr_black font_bold_gilroy">Email</label>
                      <input type="text" [(ngModel)]="user_Email" maxlength="150" formControlName="email"
                          class="form-control input_bdr_clr" />
                      <div *ngIf="submitted && registerForm.get('email').errors" class="alert alert-danger">
                          <div *ngIf="registerForm.get('email').errors.required">Email is required</div>
                          <div *ngIf="registerForm.get('email').errors.email">Email is invalid</div>
                      </div>

                  </div>
                  <div class="form-group">
                      <label class="fsize16 clr_black font_bold_gilroy">Happiness points</label>
                      <input type="text" [(ngModel)]="happiness_Point" formControlName="happinessPoints"
                          maxlength="150" class="form-control input_bdr_clr" />

                      <div *ngIf="submitted && registerForm.get('happinessPoints').errors" class="alert alert-danger">
                          <div *ngIf="registerForm.get('happinessPoints').errors.required">Happiness points is
                              required</div>
                      </div>

                  </div>


                  <!--  ddd-->

                  <div class="form-group">
                    <label class="fsize16 clr_black font_bold_gilroy"> Date of Birth *</label>
                    <input type="date" [(ngModel)]="thusr_dob" [value]="thusr_dob"
                     formControlName="dob"
                        maxlength="150" class="form-control input_bdr_clr" />

                    <div *ngIf="submitted && registerForm.get('dob').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('dob').errors.required"> DOB is
                            required</div>
                    </div>

                </div>

                <div class="form-group">
                  <label class="fsize16 clr_black font_bold_gilroy">Location *</label>
                  <input type="text" [(ngModel)]="thusr_location" formControlName="location"
                      maxlength="150" class="form-control input_bdr_clr" [value]="thusr_location" />

                  <div *ngIf="submitted && registerForm.get('location').errors" class="alert alert-danger">
                      <div *ngIf="registerForm.get('location').errors.required">Location is
                          required</div>
                  </div>

              </div>


                  <!-- ddcdd -->

                  <div class="form-group marg-t-40">
                      <button type="submit" class="btn  submit_btn_clr">Save</button>
                  </div>
              </form>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          </div>

      </div>
  </div>

</div>
