import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { async } from '@angular/core/testing';
// import * as $ from 'jquery';
declare const $: any;
declare var swal: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  constructor(private router: Router, private ApiService: ApiService) { }
  public table: any;
  dtOptions: DataTables.Settings = {};
  datatable: any;
  showLoaderDiv = false;
  addSubscriptionUrl = '/add_subscription';
  editSubscriptionUrl = '/edit_subscription';
  subcriptionList: [];
  editUrl: any;
  deletID: any;
  subscriptionpageurl = '/subscription'


  ngOnInit(): void {
    this.subscriptionList()

  }

  add_subscription() {
    this.router.navigate([this.addSubscriptionUrl])
  }



  edit_subscription(subscriptionId: number) {
    // Assuming you want to navigate to a URL like '/edit-subscription/{subscriptionId}'
    this.editUrl = `${this.editSubscriptionUrl}/${subscriptionId}`;
    console.log(' this.editUrl ==>', this.editUrl);


    this.router.navigate([this.editUrl]);
  }
  // edit_subscription(){
  //   this.router.navigate([this.editSubscriptionUrl])
  // }


  async subscriptionList() {
    console.log("sssss", localStorage.getItem("token"))
    var getapi = '/getSubscriptions';
    (await this.ApiService.getApi(getapi)).subscribe(
      res => {
        if (res.status == 200) {
          this.subcriptionList = res.result;
        }
        console.log('list of subscriptionlist', this.subcriptionList)
      }
    )
  }

  
  async SubscriptionId(deleteSubscription: number) {
    this.deletID = deleteSubscription;
  }

  async deleteSubscription() {
    var del = `/deleteSubscription/${this.deletID}`;

    (await this.ApiService.deleteApi(del)).subscribe(
      res => {
        if (res.status == 200) {
          this.subscriptionList()
          console.log(res)
        }
      }
    )
  }
}
