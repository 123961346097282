import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { get } from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'app-community-edit-events-list',
  templateUrl: './community-edit-events-list.component.html',
  styleUrls: ['./community-edit-events-list.component.scss']
})
export class CommunityEditEventsListComponent implements OnInit {



  showTimePicker1: boolean = false;
  selectedHour1: number;
  selectedMinute1: number;
  selectedPeriod1: string;

  showTimePicker2: boolean = false;
  selectedHour2: number;
  selectedMinute2: number;
  selectedPeriod2: string;

  hours: number[] = Array.from(Array(12).keys()).map(hour => hour + 1); // Generate array [1, 2, ..., 12]
  minutes: number[] = Array.from(Array(60).keys());




  EditCommunityEventsId: any;
  singleCommunityEventId: any;

  // reward_category_id: any;
  listCommunity: any[];

  selectedCommunityRewardId: any;
  isShown: boolean = false;
  getCommunityEventlist: any[] = [];
  getEventsinglelist: any;
  cancelEventsAdd_url = "/community-events-list";
  isactive: string = '';
  file: any;
  registerForm: FormGroup;
  submitted = false;
  periods: any;
  startTime: any;
  endTime: any;

  date: any
  today:string 

  topicID:any;
  getCommunityEventList:any[] = []

  eventimage: string | undefined;

  isNewFileUploaded: boolean = false;
  event_image_url: any;


  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder, private http: HttpClient) { 

    const current = new Date();
    this.today = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current.getDate().toString().padStart(2, '0')}`;


  }

  ngOnInit(): void {

    this.registerForm = new FormGroup({
      eventName: new FormControl('',Validators.required),
      eventDescription: new FormControl('',Validators.required),
      dateRequired: new FormControl(""),
      locationRequired: new FormControl("",Validators.required),
      startTimeRequired: new FormControl(""),
      endTimeRequired: new FormControl(""),
      amountRequired: new FormControl("",Validators.required),
      FileUpload: new FormControl(""),
      status: new FormControl(""),
      startTimeHour: new FormControl(""),
      startTimeMinutes: new FormControl(""),
      endTimeHour: new FormControl(""),
      endTimeMinutes: new FormControl(""),
      startTimePeriod: new FormControl(""),
      endTimePeriod: new FormControl(""),
      eventNameType: new FormControl("")
    })

    this.EditCommunityEventsId = this.ApiService.rewardseditID;
    this.EditCommunityEventsId = sessionStorage.getItem("id")
    console.log("EditrewardsId", this.EditCommunityEventsId)

    this.getCommunitylist()
    this.getSingleEventList()
    this.getlistrewardscategory() 
  }


  // ================================================================================


  cancelAddEvents() {
    this.router.navigate([this.cancelEventsAdd_url]);

  }

  // =================================================================================


  async getCommunitylist() {
    var getapi = "/listEvent";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.listCommunity = res.result;
      this.singleCommunityEventId = this.listCommunity[0].id;
      console.log("singleid", this.singleCommunityEventId)
      console.log("listEvents", this.listCommunity)
    })
  }

  // =================================================================================

  async getlistrewardscategory() {
    var getapi = "/list_community";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getCommunityEventList = res.data
      console.log("category list", this.getCommunityEventList)
    })
  }


  // ================================================================================

  onEventTypeChange(event: any) {
    this.topicID = event.target.value;
    console.log("this.topicID",this.topicID)
  }


  // =================================================================================

  openTimePicker1() {
    this.showTimePicker1 = true;
    this.startTime = this.selectedHour1 + ':' + this.selectedMinute1;
    console.log(" this.startTime  this.startTime ", this.startTime)
  }


  // ================================================================================


  openTimePicker2() {
    this.showTimePicker2 = true;
    this.endTime = this.selectedHour2 + ':' + this.selectedMinute2;
    this.periods = this.selectedPeriod2
    console.log(this.periods)

    console.log("this.endTimethis.endTimethis.endTime", this.endTime)
  }


  // =================================================================================

  period(event: any) {
    this.periods = event.target.value
    console.log(this.periods)
  }

  // =================================================================================

  convertTo12HourTime(time: string) {
    // Split the time into hours and minutes
    const splitTime = time.split(':');
    let hours = parseInt(splitTime[0]);
    let minutes = parseInt(splitTime[1]);

    // Determine AM or PM
    var period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle noon and midnight

    // Format the time
    const formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + period;

    return formattedTime;
  }


  //===================================================================================



  async getSingleEventList() {
    const getapi = "/listEvent/" + this.EditCommunityEventsId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.getEventsinglelist = res.result;
        console.log("Getlist", this.getEventsinglelist)
        const status = res.result.isactive
        const parts = res.result.date.split("-");                          //  converts the date string into the array of string  
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;       //  rearrange the date formate into YYYY/MM/DD string

        const startTimeParts = this.convertTo12HourTime(res.result.start_time).split(':');
        const endTimeParts = this.convertTo12HourTime(res.result.end_time).split(':');

        console.log("startTimeParts", startTimeParts)
        console.log("endTimeParts", endTimeParts)

        this.registerForm.controls['startTimeHour'].setValue(parseInt(startTimeParts[0]));         //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['startTimeMinutes'].setValue(parseInt(startTimeParts[1]));      //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['endTimeHour'].setValue(parseInt(endTimeParts[0]));             //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['endTimeMinutes'].setValue(parseInt(endTimeParts[1]));          //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['startTimePeriod'].setValue(startTimeParts[2]);                 //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['endTimePeriod'].setValue(endTimeParts[2]);                     //  time recieving in a 12 hours and 24 hours format based on Am Pm 

        this.registerForm.controls['eventNameType'].setValue(res.result.topic_id)
        this.registerForm.controls['eventName'].setValue(res.result.name)
        this.registerForm.controls['eventDescription'].setValue(res.result.description)
        this.registerForm.controls['dateRequired'].setValue(formattedDate)
        this.registerForm.controls['locationRequired'].setValue(res.result.location)
        this.registerForm.controls['amountRequired'].setValue(res.result.amount)
        this.registerForm.controls['status'].setValue(`${res.result.isactive}`)
        this.registerForm.controls['FileUpload'].setValue(res.result.image);

        this.eventimage = res.result.image;
        this.event_image_url = res.result.image;


        console.log("registeredform", this.registerForm)
        console.log("IMAGE", this.eventimage)

      }
    });
  }


  // =========================================================================================


  eventitem(event: any) {
    this.selectedCommunityRewardId = event.target.value
    console.log("selected id", this.selectedCommunityRewardId)
  }


  // ==========================================================================================


  fileupload(event: any) {

    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        swal({
          title: 'Error!',
          text: '1MB is only allowed to upload.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return;
      }
      // Read the file and set reward_image with its data URL
      const reader = new FileReader();
      reader.onload = () => {
        this.eventimage = reader.result as string;
      };
      reader.readAsDataURL(file);
      // Update the value of the FileUpload form control with the selected file
      this.registerForm.patchValue({
        FileUpload: file
      });
      this.isNewFileUploaded = true;
    }

    console.log("file selected", file)
  }


  // ===========================================================================================


  async submitAddCommunityEvent() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    var formdata = new FormData();

    // formdata.append('reward_category_id', rewardId)

    // const formattedDate = moment(this.date).format('YYYY/M/D');

    const startTime12Hour = `${this.registerForm.value.startTimeHour}:${this.registerForm.value.startTimeMinutes} ${this.registerForm.value.startTimePeriod}`;   //  converting the time in 12 hours nad 24 hours format 
    const endTime12Hour = `${this.registerForm.value.endTimeHour}:${this.registerForm.value.endTimeMinutes} ${this.registerForm.value.endTimePeriod}`;           //  converting the time in 12 hours nad 24 hours format 

    const startime24Hour = moment(startTime12Hour, 'h:mm A').format('HH:mm');  //  appending the time in 12 hours nad 24 hours format 
    const endTime24Hourend = moment(endTime12Hour, 'h:mm A').format('HH:mm');  //  appending the time in 12 hours nad 24 hours format 

    console.log("startTime24Hour", startime24Hour)
    console.log("endTime24Hourend", endTime24Hourend)

    // const rewardId = this.getrewardlist.filter(res => res.category_name == this.registerForm.value.CategoryName)[0]?.id
    formdata.append('topic_id',this.registerForm.value.eventNameType)
    formdata.append('name', this.registerForm.value.eventName);
    formdata.append('description', this.registerForm.value.eventDescription);
    formdata.append('date', this.registerForm.value.dateRequired);
    formdata.append('location', this.registerForm.value.locationRequired);
    formdata.append('start_time', startime24Hour);
    formdata.append('end_time', endTime24Hourend);
    formdata.append('amount', this.registerForm.value.amountRequired);
    formdata.append('isactive', this.registerForm.value.status);
    formdata.append('file', this.registerForm.value.FileUpload)

    if (this.isNewFileUploaded) {
      formdata.append('changed_file', this.event_image_url);
    }

    console.log("formdata", formdata)

    var postapi = "/addAndUpdateevent/" + this.EditCommunityEventsId;
    (await this.ApiService.putApi(postapi, formdata)).subscribe(
      res => {
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Events Updated successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.cancelEventsAdd_url]);
          console.log("Data added successfully", res);
        }
      });
  }
}


