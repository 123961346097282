import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var swal: any;

@Component({
  selector: 'app-community-events-list',
  templateUrl: './community-events-list.component.html',
  styleUrls: ['./community-events-list.component.scss']
})
export class CommunityEventsListComponent implements OnInit {

  communityeventsEdit_Url = "/community-edit-events-list";
  communityeventsAdd_Url = "/community-add-events-list";
  communityEventlist: any[];
  deleteId: any;
  showLoaderDiv = false;
  constructor(private ApiService: ApiService, private router: Router) { }


  ngOnInit(): void {
    this.communityEventlistApi();

  }


  // ===================================================================================

  async communityEventlistApi() {
    // this.showLoaderDiv = true;
    var getapi = "/listEvent";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.communityEventlist = res.result;
        this.showLoaderDiv = false;
        console.log("Listrewardlist", this.communityEventlist);
      }
    });
  }

  // =====================================================================================

  communityEvents_edit(editItem: any) {
    this.ApiService.communityEventsEditID = editItem.id;
    sessionStorage.setItem("id", editItem.id)
    this.router.navigate([this.communityeventsEdit_Url]);
    console.log("id", this.ApiService.communityEventsEditID)
  }

  // =====================================================================================

  communityeventAdd() {
    this.router.navigate([this.communityeventsAdd_Url]);
  }
 
  // ======================================================================================


  deletecommunityEvent(deleteItem: any) {
    this.deleteId = deleteItem.id
    console.log("deletingid", this.deleteId)
  }

  // =====================================================================================

  async deleteCommunityEventlist() {

    var deleteApi = "/deleteEvent/" + this.deleteId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {

      if (res.status === 200) {
        swal({
          title: "Success!",
          text: "Community Event Deleted successfully",
          type: "success",
        }, function () {
          alert('yo');
        });
        console.log("deleted", res)
      }
      this.communityEventlistApi()
      location.reload();
    });
  }
}
