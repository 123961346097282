<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/community-topic-view']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Community User List</li>
        </ol>
    </nav>


    <!-- <div class="marg-t-70 fsize27 font_bold_gilroy">
        User List
    </div> -->
    <div class="f-right  marg-b-20">
        <button class="add_btn" >Add</button>

    </div>
 
 <div class="marg-t-30" >
  
        <div class="marg-t-70" style=" background-color: #fff; border-radius: 8px;">
            <table class="w-100">
                <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                    <th>S.No</th>
                    <th>User Id</th>
                    <th>User Name</th>
                    <th>Date Joined</th>
             
                    <th>Current Status</th>

                </tr>
                <tr class=" font_medium_gilroy" *ngFor = "let item of getUserList ; let i = index" >
                    <td>{{i+1}}</td>
                    <td>{{item.user_id}}</td>
                    <td> {{item.thusr_name}}</td>
                    <td [innerHTML]="postUserDates" ></td>
                    <td><button style="background-color: none; border: none; background: none;outline: none; "  data-toggle="modal"
                        data-target="#exampleModaldelete" (click)="deletedItem(item)"   >Delete | Leave</button></td>
                </tr>
            </table>

            </div>

    </div>

    <div>
        <div class="modal fade" id="exampleModaldelete" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                    <div class="fsize16 marg-t-16">Are you sure you want to delete this Community User List ?
                    </div>
                </div>
                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                    (click)="getcommunityDeleteApi()"    >Delete</button>
                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                        data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
      </div>



</div>