import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-package-manager-edit',
  templateUrl: './package-manager-edit.component.html',
  styleUrls: ['./package-manager-edit.component.scss']
})
export class PackageManagerEditComponent implements OnInit {
  packageSubmit_url = "/packageManager";
  packageName: any;
  noOfSession: any;
  perDiscount: any;
  packageSingleListData:any;
  validityNumber:any;
  packageEdit_Id:any;
  selectValidityTime:any;
  statusActive:any;
  isShown: boolean = false;
  registerForm: FormGroup;
  submitted = false;
  description:any;
  sessionDuration:any;
  editDescription:any;
  constructor(private router: Router, private ApiService: ApiService , private fb: FormBuilder) { }

  ngOnInit(): void {
this.packageEdit_Id = localStorage.getItem("packageEditId")

    this.packageManagerListApi();
    this.registerForm = this.fb.group(
      {
        packageName: ["", Validators.required],
        noOfsessions: ["", Validators.required],
        discount: ["", Validators.required],
        validitys: ["", Validators.required],
        status: ["", Validators.required],
        validitysYears: ["", Validators.required],
        editDescription: ["", Validators.required],
        validitySessionDuration: ["", Validators.required],
      },
    );



  }

async packageUpdateSubmit() {

  this.submitted = true;

  if (this.registerForm.invalid) {
    return;
  }


var postapi = "/session/package/update";
  var couponAddData = {
    "id":this.packageEdit_Id,
    "package_name": this.packageName,
    "no_of_sessions": this.noOfSession,
    "discount": this.perDiscount,
    "validity": this.validityNumber,
    "validity_type": this.selectValidityTime,
    "status": this.statusActive,
    "description": this.editDescription,
    "session_duration": this.sessionDuration,
  };
  (await this.ApiService.postApi(postapi, couponAddData)).subscribe(
    res => {

      if (res.status === 200) {

        swal({
          title: "Success!",
          text: " Package Manager Updated successfully",
          type: "success",
        }, function () {
          alert('yo');
        });
        this.router.navigate([this.packageSubmit_url]);
      }
   });

}

  // ----------Get Single List Api--------------------------------------

  async packageManagerListApi() {
    var getapi = "/session/package/get/"+this.packageEdit_Id;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        console.log('res.data',res.data);
        
        this.packageSingleListData = res.data;
        this.packageName = res.data.package_name;
        this.noOfSession = res.data.no_of_sessions;
        this.perDiscount = res.data.discount;
        this.validityNumber = res.data.validity;
        this.editDescription = res.data.description;
        
        this.sessionDuration =  res.data.session_duration
        this.selectValidityTime  = res.data.validity_type
        var statusActiveOne = res.data.status;
        if(statusActiveOne == 0){
          this.statusActive = "0"
        }
        if(statusActiveOne == 1){
          this.statusActive = "1"
        }
      }

    });
  }

  selectValidity(e:any){
    this.selectValidityTime = e.target.value;
 }

 selectSessionDuration(e:any){
  this.sessionDuration = e.target.value;
 }

 packageCancel(){
  this.router.navigate([this.packageSubmit_url]);
}



}
