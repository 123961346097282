<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/happiness-test-management']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Add Test Questions</li>
        </ol>
    </nav>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                <div class="">
                    <form>
                        <div class="form-group">

                            <label class="fsize16 clr_black font_bold_gilroy"> Category*</label>

                            <select (change)="categoryitem($event)" style="width:200px;"
                                class=" form-control input_bdr_clr ">
                                <option [disabled]="true" [selected]="true">Select any category</option>
                                <option [value]="[item.id]" *ngFor="let item of getallcategory">
                                    {{item.category_name}} </option>
                            </select>
                            
                        </div>

                        <div style="background-color: #fff !important;padding: 10px;">
                            <table id="myTable" class="row-border hover table w-100">
                                <thead>
                                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                        <th>S.No</th>
                                        <th style="width:300px;">Question</th>
                                        <th>Isactive</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="font_medium_gilroy" *ngFor="let ele of container; let i = index">
                                        <td>{{i+1}}</td>

                                        <td class="para_line">
                                            <input type="text" maxlength="200" [(ngModel)]="newArray[i].question"
                                                [ngModelOptions]="{standalone: true}" style="width:280px;">
                                        </td>

                                        <td>
                                            <mat-slide-toggle [(ngModel)]="newArray[i].isactive" 
                                            (change)="onchange($event,i)"
                                               [ngModelOptions]="{standalone: true}">
                                            </mat-slide-toggle>
                                        </td>

                                        <td>
                                            <button *ngIf="keyValue>1" (click)="removeBenefits(i)"
                                                class="border_clr_none cursor_pointer"><img
                                                height="22px" width="22px" class="bgr_black"
                                                src="assets/Delete_icon.png" alt=""></button>
                                        </td>
                                        <!-- <td>
                                            <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px"
                                                    class="bgr_black" src="assets/Edit_icon.png" 
                                                    data-toggle="modal" data-target="#exampleModal" alt=""></span>
    
                                            <span class="marg-l-10 cursor_pointer" data-toggle="modal"
                                                data-target="#exampleModalDelete" 
                                                > <button type="button"
                                                    class="border_clr_none cursor_pointer"> <img height="22px" width="22px"
                                                        class="bgr_black" src="assets/Delete_icon.png" alt=""> </button>
    
                                            </span>
                                        </td> -->

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>
                            </div>
                        </div>

                        <div class="form-group" style="margin-top:100px;">
                            <button type="submit" (click)="happinesstestAddSubmit()"
                                class="btn  submit_btn_clr">Save</button>
                            <button type="button" style="cursor: pointer;" (click)="happinesstestBackUrl()"
                                class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                        </div>
                    </form>
                </div>

                <div>
                </div>

            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
        </div>

    </div>
</div>