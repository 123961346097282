import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup , FormBuilder , FormControl , Validator, Validators} from '@angular/forms';
declare var swal: any;


@Component({
  selector: 'app-add-pre-assessment',
  templateUrl: './add-pre-assessment.component.html',
  styleUrls: ['./add-pre-assessment.component.scss']
})
export class AddPreAssessmentComponent implements OnInit {

  id: any;
  isShown: boolean = false;
  question: any;
  answer_input: any;
  isactive = '1';
  keyValue: any;
  happinessBack_url = "/pre-assessment-list"

  showError: boolean = false

  registerForm:FormGroup

  answer_type: string;
  inputTypes: string[] = ['option', 'input'];

  constructor(private router: Router, private ApiService: ApiService, private http: HttpClient , private FormBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.registerForm = this.FormBuilder.group({
      questionRequired:["",Validators.required],
      inputType:["",Validators.required],
      status:["",Validators.required]
    })

  }

  // ========================================================

  onchange(event: any, index: number) {
    console.log("aaaaaaaaasasa", event);
    this.isactive = event.checked ? '1' : '0';
  }


  // ===========================================================

  inputtype(event: any) {

    this.answer_type = event.target.value;
    if (this.answer_type === 'option') {
      this.answer_type = '';
    }
  }

  // ============================================================
  

  async preassessmentAddSubmit() {

    this.isShown = true

    if (this.registerForm.invalid) {
      return;
    }

    var postapi = "/addAndUpdatePreAssessment";
    var exercisesAdd = {
      "question": this.question,
      "answer_type": this.answer_type === '' ? 'option' : this.answer_type,
      "answer_input": this.answer_input,
      "isactive": this.isactive 
    };
    console.log("aaaaaaaaaaaa", exercisesAdd);
    (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
      res => {

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Preassessment data Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.happinessBack_url]);
        }

      },

      // (error) => {
      //   var errMsg = '';
      //   if (typeof error.error.message === 'object') {
      //     for (var i in error.error.message) {
      //       errMsg += error.error.message[i] + '<br />';
      //     }
      //   } else {
      //     errMsg = error.error.message
      //   }
      //   swal({
      //     title: "error!",
      //     text: errMsg,
      //     type: "error",
      //   }, function () {
      //     alert('yo');
      //   });

      // }
    )
  }


  // ==================================================================

  preassessmentBackUrl() {
    this.router.navigate([this.happinessBack_url]);
  }

}

