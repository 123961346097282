<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
   Admin Approval Process
    </div>

    <div class="marg-t-30">
        <!-- <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn"  >Add</button>

        </div> -->
        <div class="">
            <table class="row-border hover table w-100" datatable style=" background-color: #fff; border-radius: 8px;" *ngIf="approveCoachListdata.length > 0" >
                <thead>
                <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;" >
                    <th>S.No</th>
                    <th>Name</th>
                  <th>College Name </th>
                    <th>College Department</th>
                    <th>Designation</th>
                    <th class="text-center" >Action</th>

                </tr>
            </thead>
            <tbody >
                <tr class=" font_medium_gilroy"  *ngFor = "let item of approveCoachListdata;let i=index"  >
                    <td>{{i+1}}</td>

                    <td> {{item.thusr_name}}</td>
                    <td>{{item.college_name}}</td>
                        <td>{{item.department}}</td>
                    <td>{{item.designation}}</td>

                    <td class="text-center" style="width:280px;" >
                    <button (click)="approveCoachApi(item)" class="approveBtn_clr"  > Approve</button>
                    <button style="margin-left: 20px;" (click)="cancelCoachApi(item)" class="cancelBtn_clr" >Cancel</button>

                    </td>
                </tr>
            </tbody>
            </table>


            <div *ngIf="approveCoachListdata.length == 0">
                <div class="text-center padd_10" style="background-color: #fff;">No Data Available in Table</div>
              </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                            <div style="margin-top: 8px;" >Are you sure you want to delete this Community Topic.</div>

                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                               >Delete</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue" data-dismiss="modal" >Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
