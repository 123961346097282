import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-assestment-questionadd',
  templateUrl: './assestment-questionadd.component.html',
  styleUrls: ['./assestment-questionadd.component.scss']
})
export class AssestmentQuestionaddComponent implements OnInit {
  getAllEmotion: any;
  emotionsId: any;
  isShown: boolean = false;
  container: any[] = [1];
  newArray: {
    no : string,
    question: string,
    never: string,
    fairly: string,
    almost_never: string,
    sometimes: string,
    very_often: string
  }[] = [{no:"",question: "", never: "", fairly: "", almost_never: "", sometimes: "", very_often: "" }];
  keyValue: any;
  assestmentBack_url = "/assestment_question"

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {

     //   var table;
  //   $(function () {
  //     table = $("#myTable").DataTable({
  //  "ordering": true
  //    });
  //    })
  
    this.getEmotionListApi()
  }

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data
    });
  }

  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;
  
  }
  // --------------------

  async addBenefits() {

 this.keyValue = this.container.length + 1;
    this.newArray.push({ no:"",question: "", never: "", fairly: "", almost_never: "", sometimes: "", very_often: "" });
    this.container.push(this.keyValue);
    var allTitle = this.newArray.map((item) => {
      if (item.never != undefined && item.never != '') {
    return { never: item.never, fairly_often: item.fairly, almost_never: item.almost_never, sometimes: item.sometimes, very_often: item.very_often }
      }
    })

  }

  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
      this.newArray.pop();
    }
  }


  async assestmentAddSubmit() {

    var allTitle = this.newArray.map((item) => {
      if (item.never != undefined && item.never != '') {
        return { no:item.no, question: item.question, never: item.never, fairly_often: item.fairly, almost_never: item.almost_never, sometimes: item.sometimes, very_often: item.very_often }
      }
    })

    var postapi = "/add_and_update_assessment";
    var exercisesAdd = {
      "emotion_id": this.emotionsId,
      "assessment": allTitle
    };
  

    (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
      res => {
      

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Assestment Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.assestmentBack_url]);
        }

      },

      (error) => {
        var errMsg = '';
        if(typeof error.error.message ==='object'){
          for (var i in error.error.message) {
            errMsg += error.error.message[i] + '<br />';
          }
        }else{
          errMsg=error.error.message
        }
        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });

      }
    )
  }

  assessmentBackUrl() {
    this.router.navigate([this.assestmentBack_url]);
  }


}
