import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
import { FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
@Component({
  selector: 'app-community-add-events-list',
  templateUrl: './community-add-events-list.component.html',
  styleUrls: ['./community-add-events-list.component.scss']
})
export class CommunityAddEventsListComponent implements OnInit {



  showTimePicker1: boolean = false;
  selectedHour1: number;
  selectedMinute1: number;
  selectedPeriod1: string;

  showTimePicker2: boolean = false;
  selectedHour2: number;
  selectedMinute2: number;
  selectedPeriod2: string;

  hours: number[] = Array.from(Array(12).keys()).map(hour => hour + 1); // Generate array [1, 2, ..., 12]
  minutes: number[] = Array.from(Array(60).keys());


  today: string;



  // selectedCommunityEventId: any;
  isShown: boolean = false;
  getCommunityEventList: any[] = [];
  topicID: any;
  cancelCommunityEvent_url = "/community-events-list";
  name: any;
  description: any;
  location: any;
  date: any;
  amount: any;
  isactive = '1';
  startTime: any;
  endTime: any;
  file: any;
  registerForm: FormGroup;
  submitted = false;
  periodRequiredAm: any;
  periodRequiredPm: any

  reward_image: string | undefined;
  fileToUpload: File | null = null;


  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder, private http: HttpClient) {

    const current = new Date();
    this.today = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current.getDate().toString().padStart(2, '0')}`;

  }

  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        Name: ["", Validators.required],
        Descrip: ["", Validators.required],
        fileMandatory: ["", Validators.required],
        dateRequired: ["", Validators.required],
        Amount: ["", Validators.required],
        timeRequired: ["", Validators.required],
        locationRequired: ["", Validators.required],
        eventType: ["", Validators.required]
        // statusRequired:["", Validators.required]
        // 
      },
    );

    this.getlistrewardscategory()

  }


  // ==============================================================================

  onEventTypeChange(event: any) {
    this.topicID = event.target.value;
    console.log("this.topicID", this.topicID)
  }

  // ==============================================================================


  periodAm(event: any) {
    this.periodRequiredAm = event.target.value
    console.log(this.periodRequiredAm)
  }

  // ==============================================================================

  periodPm(event: any) {
    this.periodRequiredPm = event.target.value
    console.log(this.periodRequiredPm)
  }

  // ===============================================================================

  openTimePicker1() {
    this.showTimePicker1 = true;
    this.startTime = this.selectedHour1 + ':' + this.selectedMinute1;
    this.periodRequiredAm = this.selectedPeriod1

    console.log(this.periodRequiredAm)
    console.log(" this.startTime  this.startTime ", this.startTime)
  }

  // ==============================================================================


  async getlistrewardscategory() {
    var getapi = "/list_community";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getCommunityEventList = res.data
      console.log("category list", this.getCommunityEventList)
    })
  }


  // ===============================================================================


  openTimePicker2() {
    this.showTimePicker2 = true;
    this.endTime = this.selectedHour2 + ':' + this.selectedMinute2;
    this.periodRequiredPm = this.selectedPeriod2
    console.log(this.periodRequiredPm)

    console.log("this.endTimethis.endTimethis.endTime", this.endTime)

  }


  // =================================================================================


  cancelAddrewards() {
    this.router.navigate([this.cancelCommunityEvent_url]);
  }

  // =================================================================================

  fileupload(event: any) {
    const files = event.target.files;
    const maxSize = 1 * 1024 * 1024;
    if (files.length > 0) {
      this.fileToUpload = files[0];

      if (this.fileToUpload.size > maxSize) {
        swal({
          title: 'Error!',
          text: '1MB is only allowed to upload.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
      // Optionally, if you want to display the image preview:
      const reader = new FileReader();
        reader.onload = (e: any) => {
          this.reward_image = e.target.result;
        };
      reader.readAsDataURL(this.fileToUpload);
    }
    console.log("file selected", files)
  }

  // ================================================================================

  async submitAddrewards() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var formdata = new FormData();

    const formattedDate = moment(this.date).format('YYYY/M/D');

    const startTimeformate = moment(this.startTime, 'H:mm').format('HH:mm')
    const startTime12Hour = `${startTimeformate} ${this.periodRequiredAm}`;

    const endTimeformate = moment(this.endTime, 'H:mm').format('HH:mm')
    const endTime12Hours = `${endTimeformate} ${this.periodRequiredPm}`;

    console.log("startTime12Hour", startTime12Hour)
    console.log("endTime12Hours", endTime12Hours)


    const startTime24Hour = moment(startTime12Hour, 'h:mm A').format('HH:mm');
    const endTime24Hourend = moment(endTime12Hours, 'h:mm A').format('HH:mm');


    console.log("startTime24Hour", startTime24Hour)

    // const endTimeformate = moment(this.endTime, 'H:mm').format('HH:mm')

    formdata.append('topic_id', this.topicID);
    formdata.append('name', this.name);
    formdata.append('description', this.description);
    formdata.append('location', this.location);
    formdata.append('date', formattedDate);
    formdata.append('amount', this.amount);
    formdata.append('isactive', this.isactive === '1' ? '1' : '0');
    formdata.append('start_time', startTime24Hour);
    formdata.append('end_time', endTime24Hourend);
    formdata.append('file', this.fileToUpload);

    console.log("formdata", formdata)

    var postapi = "/addAndUpdateevent";
    (await this.ApiService.postApi(postapi, formdata)).subscribe(
      res => {
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Events Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.cancelCommunityEvent_url]);
          console.log("Data added successfully", res);
        }
      });
  }
}
