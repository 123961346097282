import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutrition-databank-view',
  templateUrl: './nutrition-databank-view.component.html',
  styleUrls: ['./nutrition-databank-view.component.scss']
})
export class NutritionDatabankViewComponent implements OnInit {

  isShown: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
