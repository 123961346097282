import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-camp-edit-expedition',
  templateUrl: './camp-edit-expedition.component.html',
  styleUrls: ['./camp-edit-expedition.component.scss']
})
export class CampEditExpeditionComponent implements OnInit {
  sosQuestionEditPage = "/expedition-Camp-edit";
  expedition_add = '/expedition-add-CampDetails';
  campSiteList = [];
  isShown: boolean = false;
  campExpeditionId: any;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit() {

    this.campExpeditionId = this.ApiService.expeditionEditId;
    // setTimeout(()=>{

      this.getCampSiteListApi();
    this.adminGetExpeditionApi();

    // },300)


  }
  expedition_EditPage(items: any) {

    this.ApiService.expeditionCampId = items.id;


    this.ApiService.expeditionscampTiltes = items.camp_title
    this.router.navigate([this.sosQuestionEditPage]);
  }
  expeditionAdd() {
    this.router.navigate([this.expedition_add]);
  }
  async getCampSiteListApi() {
    var getapi = "/get_camp_site";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.campSiteList = res.data.reverse();

      // if (this.campExpeditionId == "6" ) {
      //   this.campSiteList = res.data.reverse().filter((o, i) => i < 16);
      // }
      // else if (this.campExpeditionId == "2"){
      //   this.campSiteList = res.data.reverse().filter((o, i) => i < 16);
      // }
      // else if (this.campExpeditionId == "4"){
      //   this.campSiteList = res.data.reverse().filter((o, i) => i < 15);
      // }
      // else{
      //   this.campSiteList = res.data.reverse();
      // }




    });
  }

  async adminGetExpeditionApi() {
    var postapi = "/admin_get_expedition";
    var expeditionModuleId = {
      "exp_id": "5",
    };
    (await this.ApiService.postApi(postapi, expeditionModuleId)).subscribe(
      res => {

      });
  }



}
