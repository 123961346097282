<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/happiness-test-result']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Add Overall results </li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                <div class="">
                    <form>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Score Ranges</label>
                            <select (change)="emotionItem($event)" class=" form-control input_bdr_clr "
                                style="width:200px;">
                                <option [disabled]="true" [selected]="true">Ranges</option>
                                <option [value]="[item.id]" *ngFor="let item of happinessresultitems">
                                    {{item.level}} </option>
                            </select>
                        </div>

                        <div style="background-color: #fff !important;padding: 10px;">
                            <table id="myTable" class="row-border hover table w-100">
                                <thead>
                                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                        <th>S.No</th>
                                        <th>Interpretation</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tr *ngFor="let ele of container; let i = index">
                                    <td>{{i+1}}</td>

                                    <td>
                                        <input [(ngModel)]="newArray[i].description"
                                            [ngModelOptions]="{standalone: true}" maxlength="200" style="width: 300px;">
                                    </td>

                                    <td>
                                        <button *ngIf="keyValue>1" (click)="removeBenefits(i)"
                                            class="border_clr_none cursor_pointer"><img height="22px" width="22px"
                                                class="bgr_black" src="assets/Delete_icon.png" alt=""></button>
                                    </td>
                                </tr>

                            </table>
                        </div>

                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>

                            </div>
                        </div>

                        <div class="form-group" style="margin-top:100px;">
                            <button type="submit" (click)="happinessresultSubmit()"
                                class="btn  submit_btn_clr">Save</button>
                            <button type="button" style="cursor: pointer;" class=" marg-l-16 cancelBtn_clr fsize16"
                                (click)="happinessresultCancelBtn()">Cancel</button>
                        </div>
                    </form>
                </div>
                <div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>