<div class="padd-0-40">
  <div class="marg-t-70 ">
    <div class="fsize27 font_bold_gilroy">
      Settings
    </div>
    <div class="mt-4 fsize-20 font_bold_gilroy">
      Payment Gateway
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <div class="payment-border">
          <div class="text-center fsize-24">
            <!-- Payment Gateway -->
          </div>
          <div style="    height: 90px; padding: 12px;">
            <div class="row">
              <div class="col-3">
                <div class="padd-l-12">
                  <img style="    height: 55px;" src="assets/razorpay.svg" alt="">
                </div>
              </div>
              <div class="col-6">
                <div class="text-center sub_heading" style="    padding-top: 9%;">
                  Razor Pay
                </div>
              </div>
              <div class="col-3">
                <div class="float-end padd-r-12" style="    padding-top: 21%; text-align: end;">
                  <mat-slide-toggle [(ngModel)]="isRazorpayActive" (ngModelChange)="payment_mode()"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="payment-border">
          <div class="text-center fsize-24">
            <!-- Payment Gateway -->
          </div>
          <div style="    height: 90px; padding: 12px;">
            <div class="row">
              <div class="col-3">
                <div class="padd-l-12">
                  <img style="    height: 55px;" src="assets/phone.jpeg" alt="">
                </div>
              </div>
              <div class="col-6">
                <div class="text-center sub_heading" style="    padding-top: 9%;">
                  PhonePe
                </div>
              </div>
              <div class="col-3">
                <div class="float-end padd-r-12" style="    padding-top: 21%; text-align: end;">
                  <mat-slide-toggle [(ngModel)]="isPhonepeActive" (ngModelChange)="payment_mode()" ></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
