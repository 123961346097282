import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { event } from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;
@Component({
  selector: 'app-question-expedition-edit',
  templateUrl: './question-expedition-edit.component.html',
  styleUrls: ['./question-expedition-edit.component.scss'],
})
export class QuestionExpeditionEditComponent implements OnInit {
  isShown: boolean = false;
  questions: any;
  questionOrder: any;
  status: any;
  expeditionQuestion_EditId: any;
  getExpeditionQuestion_List = [];
  collectResponse: any;
  responsFromPrevious: any;
  questionEdit_Id: any;
  expedition_ids: any;
  previousCampSite_Yes_Div = false;
  previouscampSiteName: any;
  previouscampSiteQuestion: any;
  collectResponses: any;
  previousCampsite: any;
  campName: any;
  campNameOne: any;
  camp_Title: any;
  campAllTitle: any;
  campSiteList: any;
  campTitleId: any;
  view_Beliefs = '';
  answerPlaceholder: any;
  previousCampsiteList = [];
  previousCampsiteListTwo = [];
  quextionUpate_url = '/expedition-Camp-edit';
  campAllTitleTwo: any;
  campTitleIdTwo: any;
  questionExpeditions: any;
  editExp_Question: any;
  editExp_QuestionId: any;
  questionExpeditionsTwo: any;
  editExp_QuestionIdTwo: any;
  campnew_Id: any;
  previouscampSiteQuestionTwo: any;
  prvCampId: any;
  prvCampIdTwo: any;
  prvCampQuestionId: any;
  prvCampQuestionIdTwo: any;
  questionCampId: any;
  expedition_Id: any;
  feeling_wheel: any;
  cognitive_bias: any;
  registerForm: FormGroup;
  submitted = false;
  // options_question:any;
  options_question: any = '0';
  multiPlaceholderCamp13: any = [{ id: 0, name: '' }];
  countFlag: number = 1;
  expeditionsCampid:any;
  expeditionEditAllId:any;
  constructor(
    private ApiService: ApiService,
    private router: Router,
    private fb: FormBuilder,

  ) {}

  ngOnInit() {
    this.expeditionsCampid = this.ApiService.expeditionCampId;
    this.expeditionEditAllId = this.ApiService.expeditionEditId;
    this.questionEdit_Id = this.ApiService.questionExpeditionEditId;
    this.expedition_ids = this.ApiService.questionExpedition_ids;
    this.questionCampId = this.ApiService.questionCampIds;

    this.getCampSiteListApi();

    this.getQuestionByIdApi();
    this.getCampSitePreviousQuestion();
    this.getCampSitePreviousQuestionTwo();

    this.registerForm = this.fb.group({
      questions: ['', Validators.required],
      questionsorder: ['', Validators.required],

      // answerplaceholders: this.collectResponse
      //   ? ['', Validators.required]
      //   : null,
      viewBeliefs: ['', Validators.required],
      showfeeling: ['', Validators.required],
      showCognitive: ['', Validators.required],
      collectResponses: ['', Validators.required],
    });
    console.log('=====', this.options_question)
  }

  async getQuestionByIdApi() {
    var postapi = '/get_single_question';
    var getQuestiondata = {
      question_id: this.questionEdit_Id,
    };



    (await this.ApiService.postApi(postapi, getQuestiondata)).subscribe(
      (res) => {
        if (res) {
          if (res.status === 200) {
            this.getExpeditionQuestion_List = res.data;
            for (let item of this.getExpeditionQuestion_List) {
              this.questions = item.question;
              this.questionOrder = item.question_order;
              this.collectResponse = item.collect_res_user;
              this.responsFromPrevious = item.display_res_previous_camp;
              this.status = item.status;
              this.answerPlaceholder = (item.options_question == 1) ? item.answer_placeholder.map((item:any, index:any) => Object.keys(item)[0] ).join(','):item.answer_placeholder;
              // this.multiPlaceholderCamp13 = item.answer_placeholder;
              this.options_question = (item.options_question)?item.options_question:'0';
              this.multiPlaceholderCamp13 = (item.options_question == 1) ? item.answer_placeholder.map((item:any, index:any) => {
                this.countFlag++;
                return { id: index, name: Object.keys(item)[0] };
              }) : [{ id: 0, name: '' }];
              console.log(
                'this.multiPlaceholderCamp13===',
                this.multiPlaceholderCamp13,
                this.options_question,
                this.answerPlaceholder
              );
              // this.campName = item.camp_title + "," + item.camp_id;
              this.campName =
                item.prvs_camp_id + ',' + item.camp_title + ',' + item.camp_id;
              this.campnew_Id = item.camp_id;
              console.log(this.campnew_Id, 'campnew_Id');

              this.prvCampId = item.prvs_camp_id;
              this.prvCampIdTwo = item.prvs_camp_id_2;
              this.prvCampQuestionId = item.prvs_question_id;
              this.prvCampQuestionIdTwo = item.prvs_question_id_2;
              this.campNameOne = item.camp_title + ',' + item.prvs_camp_id_2;
              this.previouscampSiteQuestion = item.camp_site_question;
              this.view_Beliefs = item.view_beliefs;
              this.expedition_Id = item.expedition_id;
              this.feeling_wheel = item.feeling_wheels;
              this.cognitive_bias = item.cognitive_bias;
            }
          }
        }
        this.getCampSiteListApi();
        this.responsePreviousCampSiteYes();
        this.getCampSitePreviousQuestion();
        this.getCampSitePreviousQuestionTwo();
      }
    );
  }

  camplistTitleFun(e) {
    this.prvCampId = e.target.value;
    // this.camp_Title = campitems;

    // var str = this.camp_Title;
    // var myarray = str.split(',');
    // for (var i = 0; i < myarray.length; i++) {
    //   this.campAllTitle = myarray[0];
    //   this.campTitleId = myarray[1];

    // }
    this.getCampSitePreviousQuestion();
  }

  camplistTitleFunTwo(e) {
    this.prvCampIdTwo = e.target.value;
    // this.camp_Title = campitems;

    // var str = this.camp_Title;
    // var myarray = str.split(',');
    // for (var i = 0; i < myarray.length; i++) {
    //   this.campAllTitleTwo = myarray[0];
    //   this.campTitleIdTwo = myarray[1];

    // }
    this.getCampSitePreviousQuestionTwo();
  }

  editCampSiteQuestion(event) {
    this.prvCampQuestionId = event.target.value;

    // var str = this.questionExpeditions;
    // var myarray = str.split(',');
    // for (var i = 0; i < myarray.length; i++) {
    //   this.editExp_Question = myarray[0];
    //   this.editExp_QuestionId = myarray[1];

    // }
    // this.getCampSitePreviousQuestion();
  }

  editCampSiteQuestionTwo(e) {
    this.prvCampQuestionIdTwo = e.target.value;

    // this.questionExpeditionsTwo = itemQuestionOne;
    // var str = this.questionExpeditionsTwo;
    // var myarray = str.split(',');
    // for (var i = 0; i < myarray.length; i++) {
    //   this.editExp_Question = myarray[0];
    //   this.editExp_QuestionIdTwo = myarray[1];

    // }
    // this.getCampSitePreviousQuestionTwo();
  }

  addmultiPlaceholderCamp13() {
    this.multiPlaceholderCamp13.unshift({ id: this.countFlag++, name: '' });

  }
  removemultiPlaceholderCamp13(item:any){
    if(this.multiPlaceholderCamp13.length > 1){
      const data = this.multiPlaceholderCamp13.filter((row:any) => row.id != item.id)
      this.multiPlaceholderCamp13 = data;
    }
  }

  changeMultiPlaceholderCamp13(e: any, item: any) {
    this.multiPlaceholderCamp13 = this.multiPlaceholderCamp13.map(
      (row: any) => {
        if (row.id == item.id) {
          return { id: item.id, name: e.target.value };
        } else {
          return row;
        }
      }
    );
    console.log(this.multiPlaceholderCamp13);
    console.log('options_question', this.options_question);
  }

  async expeditionQuestionUpdate(e: any) {
    e.preventDefault();
    if (document.querySelector('.err_msg') ) {
      document.querySelector('.err_msg').innerHTML = ``;
    }
    console.log('aaa ', this.answerPlaceholder);

    if (
      (<HTMLInputElement>(
        document.querySelector('[name=collectResponses]:checked')
      )).value == '1' &&
      !this.answerPlaceholder && document.querySelector('.err_msg')
    ) {
      document.querySelector('.err_msg').innerHTML = `
      <div class="alert alert-danger">
        <div >Answer Placeholder is required</div>
      </div>`;
      return;
    }
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    // if (this.previouscampSiteQuestion == "" || this.previouscampSiteQuestion != undefined || this.previouscampSiteQuestion != null) {
    // var prevquestion = this.previouscampSiteQuestion
    // }
    console.log(this.responsFromPrevious, 'responsFromPrevious...>>>');
    if (this.responsFromPrevious == '1') {
      console.log('preve 1');

      var postapi = '/edit_expedition_questions';
      var questionUpdatedata = {
        id: this.questionEdit_Id,
        expedition_id: this.expedition_ids,
        question: this.questions,
        question_order: this.questionOrder,
        collect_res_user: this.collectResponse,
        display_res_previous_camp: this.responsFromPrevious ?? 0,
        camp_id: this.campnew_Id,
        camp_site_question: this.previouscampSiteQuestion ?? '',
        answer_placeholder: (this.options_question == 1) ?  this.multiPlaceholderCamp13.map((item:any) => { if(item.name != ''){ return item.name} }).filter(Boolean).join(','):this.answerPlaceholder,
        view_beliefs: this.view_Beliefs,
        options_question:this.options_question,
        feeling_wheels: this.feeling_wheel,
        cognitive_bias: this.cognitive_bias,
        prvs_camp_id: this.prvCampId,
        prvs_camp_id_2: this.prvCampIdTwo,
        prvs_question_id: this.prvCampQuestionId,
        prvs_question_id_2: this.prvCampQuestionIdTwo,
      };

      if(!questionUpdatedata.answer_placeholder || questionUpdatedata.answer_placeholder == ''){
        swal(
          {
            icon: 'error',
            title: 'Oops...',
            text: "Answer placeholder is required",
            type: 'error',
          },
          function () {
            alert('yo');
          }
        );
        return
      }else{
        if(this.multiPlaceholderCamp13.find((item:any) => item.name == '')){
          swal(
            {
              icon: 'error',
              title: 'Oops...',
              text: "Answer placeholder is required",
              type: 'error',
            },
            function () {
              alert('yo');
            }
          );
          return
        }
      }

      // if(questionUpdatedata.answer_placeholder == '' || questionUpdatedata.answer_placeholder == ',' )

      (await this.ApiService.postApi(postapi, questionUpdatedata)).subscribe(
        (res) => {
          if (res) {
            if (res.status === 200) {
              swal(
                {
                  title: 'Success!',
                  text: 'Saved Successfully ',
                  type: 'success',
                },
                function () {
                  alert('yo');
                }
              );
              this.router.navigate([this.quextionUpate_url]);
            }
          }
        },
        ({ error }) => {
          swal(
            {
              icon: 'error',
              title: '',
              text: error[0].msg,
              type: 'error',
            },
            function () {
              alert('yo');
            }
          );
        },
        () => {}
      );

      // }
    } else if (this.responsFromPrevious == '0') {
      console.log('preve 0');

      var postapi = '/edit_expedition_questions';
      var questionUpdatedata = {
        id: this.questionEdit_Id,
        expedition_id: this.expedition_ids,
        question: this.questions,
        question_order: this.questionOrder,
        collect_res_user: this.collectResponse,
        display_res_previous_camp: this.responsFromPrevious ?? 0,
        camp_id: this.campnew_Id,
        camp_site_question: this.previouscampSiteQuestion ?? '',
        answer_placeholder: (this.options_question == 1) ?  this.multiPlaceholderCamp13.map((item:any) => { if(item.name != ''){ return item.name} }).filter(Boolean).join(','):this.answerPlaceholder,
        view_beliefs: this.view_Beliefs,
        options_question:this.options_question,

        feeling_wheels: this.feeling_wheel,
        cognitive_bias: this.cognitive_bias,
        prvs_camp_id: this.prvCampId ?? 0,
        prvs_camp_id_2: this.prvCampIdTwo ?? 0,
        prvs_question_id: this.prvCampQuestionId ?? 0,
        prvs_question_id_2: this.prvCampQuestionIdTwo ?? 0,
      };

      if(!questionUpdatedata.answer_placeholder || questionUpdatedata.answer_placeholder == ''){
        swal(
          {
            icon: 'error',
            title: 'Oops...',
            text: "Answer placeholder is required",
            type: 'error',
          },
          function () {
            alert('yo');
          }
        );
        return
      }else{
        if(this.multiPlaceholderCamp13.find((item:any) => item.name == '')){
          swal(
            {
              icon: 'error',
              title: 'Oops...',
              text: "Answer placeholder is required",
              type: 'error',
            },
            function () {
              alert('yo');
            }
          );
          return
        }
      }

      (await this.ApiService.postApi(postapi, questionUpdatedata)).subscribe(
        (res) => {
          if (res) {
            if (res.status === 200) {
              swal(
                {
                  title: 'Success!',
                  text: 'Saved Successfully ',
                  type: 'success',
                },
                function () {
                  alert('yo');
                }
              );
              this.router.navigate([this.quextionUpate_url]);
            }
          }
        },
        ({ error }) => {
          swal(
            {
              icon: 'error',
              title: 'Oops...',
              text: error[0].msg,
              type: 'error',
            },
            function () {
              alert('yo');
            }
          );
        },
        () => {}
      );
    } else if (this.campnew_Id == '1') {
      console.log('CampId');

      var postapi = '/edit_expedition_questions';
      var questionUpdatedata = {
        id: this.questionEdit_Id,
        expedition_id: this.expedition_ids,
        question: this.questions,
        question_order: this.questionOrder,
        collect_res_user: this.collectResponse,
        display_res_previous_camp: this.responsFromPrevious ?? 0,
        camp_id: this.campnew_Id,

        camp_site_question: this.previouscampSiteQuestion ?? '',
        answer_placeholder: (this.options_question == 1) ?  this.multiPlaceholderCamp13.map((item:any) => { if(item.name != ''){ return item.name} }).filter(Boolean).join(','):this.answerPlaceholder,
        view_beliefs: this.view_Beliefs,
        options_question:this.options_question,

        feeling_wheels: this.feeling_wheel,
        cognitive_bias: this.cognitive_bias,
        prvs_camp_id: this.prvCampId ?? 0,
        prvs_camp_id_2: this.prvCampIdTwo ?? 0,
        prvs_question_id: this.prvCampQuestionId ?? 0,
        prvs_question_id_2: this.prvCampQuestionIdTwo ?? 0,
      };


      if(!questionUpdatedata.answer_placeholder || questionUpdatedata.answer_placeholder == ''){
        swal(
          {
            icon: 'error',
            title: 'Oops...',
            text: "Answer placeholder is required",
            type: 'error',
          },
          function () {
            alert('yo');
          }
        );
        return
      }
      else{
        if(this.multiPlaceholderCamp13.find((item:any) => item.name == '')){
          swal(
            {
              icon: 'error',
              title: 'Oops...',
              text: "Answer placeholder is required",
              type: 'error',
            },
            function () {
              alert('yo');
            }
          );
          return
        }
      }

      (await this.ApiService.postApi(postapi, questionUpdatedata)).subscribe(
        (res) => {
          if (res) {
            if (res.status === 200) {
              swal(
                {
                  title: 'Success!',
                  // text: 'Expedition Question Updated Successfully ',
                  text: 'Saved Successfully ',
                  type: 'success',
                },
                function () {
                  alert('yo');
                }
              );
              this.router.navigate([this.quextionUpate_url]);
            }
          }
        },
        ({ error }) => {
          swal(
            {
              icon: 'error',
              title: 'Oops...',
              text: error[0].msg,
              type: 'error',
            },
            function () {
              alert('yo');
            }
          );
        },
        () => {}
      );
    }
  }

  responsePreviousCampSiteYes() {
    var campsiteYes = this.responsFromPrevious;
    if (campsiteYes == '1') {
      this.previousCampSite_Yes_Div = true;
    } else if (campsiteYes == '0') {
      this.previousCampSite_Yes_Div = false;
    }
  }

  async getCampSiteListApi() {
    var getapi = '/get_camp_site';
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      // var campSite_list = res.data.reverse();
      var campSite_list = res.data;
      this.campSiteList = campSite_list.filter(
        (x) => x.id < this.questionCampId
      );

      this.getCampSitePreviousQuestion();
    });
  }

  async getCampSitePreviousQuestion() {
    var postapi = '/get_camp_question';

    if (this.prvCampId != '' || this.prvCampId != undefined) {
      var campQuestionNewId = this.prvCampId;
    } else if (
      (this.prvCampId == '' || this.prvCampId == undefined) &&
      this.prvCampId != null
    ) {
      var campQuestionNewId = this.campTitleId;
    }

    var campsiteQuestiondata = {
      camp_id: this.prvCampId,
      expedition_id: this.expedition_Id,
    };

    (await this.ApiService.postApi(postapi, campsiteQuestiondata)).subscribe(
      (res) => {
        if (res) {
          if (res.status === 200) {
            this.previousCampsiteList = campQuestionNewId ? res.data : [];
          }
        }
      }
    );
  }
  isExist(prvCampId) {
    return !this.campSiteList.some((o) => o.id == prvCampId);
  }
  async getCampSitePreviousQuestionTwo() {
    var postapi = '/get_camp_question';

    if (this.prvCampIdTwo != '' || this.prvCampIdTwo != undefined) {
      var campQuestionNewIdTwo = this.prvCampIdTwo;
    } else if (
      (this.prvCampIdTwo == '' || this.prvCampIdTwo == undefined) &&
      this.prvCampIdTwo != null
    ) {
      var campQuestionNewIdTwo = this.campTitleIdTwo;
    }
    var campsiteQuestiondata = {
      camp_id: this.prvCampIdTwo,
      expedition_id: this.expedition_Id,
    };

    (await this.ApiService.postApi(postapi, campsiteQuestiondata)).subscribe(
      (res) => {
        if (res) {
          if (res.status === 200) {
            this.previousCampsiteListTwo = campQuestionNewIdTwo ? res.data : [];
          }
        }
      }
    );
  }

  editCancel() {
    this.router.navigate([this.quextionUpate_url]);
  }
}
