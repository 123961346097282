<div class="marg-t-70">
    <div class="row">
        <div class="col-6">
            <div class="fsize27 font_bold_gilroy">
                <h3>Review Management</h3>
            </div>
        </div>
        <div class="col-6">
            <div style="float: right;">
            </div>
        </div>
    </div>
    <div class="col-lg-12" style="margin-top:40px;">
        <div class="" style="background-color: #fff; border-radius: 8px;">

            <table datatable id="myTable"  class="row-border hover table w-100" *ngIf="review_list.length > 0">
                <thead>
                    <tr class="font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Coach Name</th>
                        <th>Review</th>
                        <th>Rating</th>
                        <th>User</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of review_list; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.coach_name}}</td>
                        <td maxlength="500">{{item.review_comment}}</td>
                        <td>
                            <ng-container>
                                <span class="fa fa-star" [ngClass]="{'checked': 0  < item.review}"></span>
                                <span class="fa fa-star" [ngClass]="{'checked': 1  < item.review}"></span>
                                <span class="fa fa-star" [ngClass]="{'checked': 2  < item.review}"></span>
                                <span class="fa fa-star" [ngClass]="{'checked': 3  < item.review}"></span>
                                <span class="fa fa-star" [ngClass]="{'checked': 4  < item.review}"></span>
                            </ng-container>
                        </td>
                        
                        <td>{{item.user_name}}</td>
                        <td>
                            {{ item.status === 2 ? 'Pending Approval' : (item.status === 1 ? 'Approved' : 'Rejected') }}
                        </td>
                        <td>
                            <span class="Approve"><button class="approve_button" [(ngModel)]="items"
                                    [ngModelOptions]="{standalone: true}"  *ngIf="item.status === 2 && !item.buttonsHidden" (click)="handleButtonClick(item,1)"
                                    style="border-radius: 5px;">Approve</button>
                            </span>
                            <span class="Reject"><button class="reject_button" [(ngModel)]="items"
                                    [ngModelOptions]="{standalone: true}"  *ngIf="item.status === 2 && !item.buttonsHidden" (click)="handleButtonClick(item,0)"
                                    style="border-radius: 5px; margin-left: 5px;">Reject</button>
                            </span>

                            <span class="Delete"><button type="button"
                                    style="outline: none; border: none; background: none; " data-toggle="modal"
                                    [(ngModel)]="id" [ngModelOptions]="{standalone: true}" data-target="#exampleModal"
                                    (click)="delete_id(item.id)"><img height="22px" width="22px"
                                        style="outline:none; border: none; padding-left: 3px; size: 50px;"
                                        class="bgr_black" src="assets/Delete_icon.png" alt=""></button></span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                            <div>
                                Do you want to delete ? </div>
                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy" style="margin-block:10px !important">
                            <button type="button" class="btn_submit_confirm bgr_clr_red" data-dismiss="modal"
                                (click)="deleteReview()">Confirm</button>
                            <button type="button" data-dismiss="modal"
                                class="btn_submit_cancel marg-l-10 bgr_clr_blue">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
