import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-appointment-edit-page',
  templateUrl: './appointment-edit-page.component.html',
  styleUrls: ['./appointment-edit-page.component.scss']
})
export class AppointmentEditPageComponent implements OnInit {
  // registerForm: FormGroup;
  // submitted = false;
  getAppointmentListByIdData = [];
  appointmentNo: any;
  appointment_Id: any;
  coachName: any;
  userName: any;
  appointmentDate: any;
  appointmentTime: any;
  appointmentStatus: any;

  constructor(private router: Router, private ApiService: ApiService) { }

  async ngOnInit() {
    this.appointment_Id = this.ApiService.appointmentEditId
    var postapi = "/admin_view_appointment";
    var appintmentlistidData = {
      "app_id": "4"
    };
    (await this.ApiService.postApi(postapi, appintmentlistidData)).subscribe(
      res => {
        this.getAppointmentListByIdData = res.data;
        for (let item of this.getAppointmentListByIdData) {
          this.appointmentNo = item.id;
          this.coachName = item.thusr_name;
          this.appointmentDate = item.date;
          this.appointmentTime = item.time;
          this.appointmentStatus = item.appointment_status;
        }
      });
    // ------------------------------------------------------
    var getapi = "/admin_appointment_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
   
      var get_AppointmentList = res.data;
      for (let item of get_AppointmentList) {
        this.appointmentNo = item.id;
        if (this.appointment_Id == this.appointmentNo) {
          this.coachName = item.thusr_name;
          this.appointmentDate = item.date;
          this.appointmentTime = item.time;
          this.appointmentStatus = item.appointment_status;
        }
      }

    });

  }

  async appointmentReshedule() {
    var postapi = "/reshedule_appointment";
    var appintmentlistidData = {
      "app_id": "1",
      "coach_id": "1",
      "slot_id": "1",
      "date": this.appointmentDate,
      "time": this.appointmentTime,
      "appointment_type": "Online",
      "appointment_status": "booked",
      "message": "test"
    };
    (await this.ApiService.postApi(postapi, appintmentlistidData)).subscribe(
      res => {

      });

  }

  //  async getlistapi() {

  //  }






}
