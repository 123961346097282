<div class="add-appoint marg-t-70 marg-l-40">
  <!-- <div class="fsize27 font_bold_gilroy mb-3"> Add Appointment</div> -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb fsize27 padd_0 " style="margin-left: 30px;">
      <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
          [routerLink]="['/appointment']">Back</a>
      </li>
      <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Add Appointment</li>
    </ol>
  </nav>
  <div class="row mb-3">
    <!-- <div class="col-2"></div> -->
    <div class="col-12">
      <div class="card brd-rad-20">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="myForm">
                <div class="row mb-3" mt-2>
                  <!-- <div class="col-3">
                  </div> -->
                  <div class="col-6">
                    <!-- <div>
  <form class="example-form">
    <mat-form-field class="example-full-width">
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div> -->
                    <div class="form-group">
                      <label class="font_regular_gilroy heading-one ">User *</label>
                      <!-- <select style="outline: none !important;" [(ngModel)]="appointment_userId" #selectTxnSubCategory
                        class="w-100 input-all selectpicker" data-live-search="true" id="txnSubCategory"
                        data-width="100%" formControlName="txnSubCategory" title="Select User">
                        <option data-tokens="Abcd" *ngFor="let user of userListAll" [value]="user.thusr_id">
                          {{user.thusr_name}} <span>
                            {{user.thusr_email}}>
                          </span> </option>
                      </select> -->

                      <input placeholder="Select User" type="text" class="search-input w-100 input-all" (keyup)="userSearch($event)"
  [value]="(userDataSelect && userDataSelect.thusr_name) ? userDataSelect.thusr_name + ' (' + userDataSelect.thusr_email + ')' : ''" />


                      <!-- <input placeholder="Select User" type="text" class="search-input w-100 input-all " (keyup)="userSearch($event)"
                        [value]="(userDataSelect && userDataSelect.thusr_name)?userDataSelect.thusr_name:''" /> -->
                        <div class="card">
                          <!-- <ul class="search-result-data" style="display: none;">
                            <li class="search-result-items" *ngFor="let user of userListAllData"
                              (click)="chooseField(user)">{{user.thusr_name}}</li>
                          </ul> -->
                          <div class="search-result-data" style="display: none;">
                            <div class="search-result-items" *ngFor="let user of userListAllData"
                            (click)="chooseField(user)" style="font-size: 16px; padding: 4px;">{{user.thusr_name}} <span><{{user.thusr_email}}></span>

                            </div>
                          </div>
                        </div>

                    </div>
                  </div>
                  <div class="col-6">
                    <div class="">
                      <label class="font_regular_gilroy heading-one">Coach *</label>
                      <!-- form-control selectpicker -->
                      <!-- <input placeholder="Select Coach" type="text" class="search-input-coach w-100 input-all" (keyup)="coachSearch($event)"
                        [value]="(coachDataSelect && coachDataSelect.thusr_name)?coachDataSelect.thusr_name:''" /> -->

                        <input placeholder="Select Coach" type="text" class="search-input-coach w-100 input-all" (keyup)="coachSearch($event)"
       [value]="(coachDataSelect && coachDataSelect.thusr_name) ? coachDataSelect.thusr_name + ' (' + coachDataSelect.thusr_email + ')' : ''" />



                        <div class="card">

                          <div class="search-result-data-coach" style="display: none;">
                            <div class="search-result-items-coach" *ngFor="let coach of coachListAllData"
                            (click)="chooseCoachField(coach)" style="font-size: 16px; padding: 4px;">{{coach.thusr_name}} <span><{{coach.thusr_email}}></span>

                            </div>
                          </div>
                        </div>




                    </div>
                  </div>
                  <!-- <div class="col-3"></div> -->
                </div>
                <label class="font_regular_gilroy heading-one mb-3">Session Duration *</label>
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button style="cursor: pointer;" class="nav-link active side-bttn" id="pills-half-tab" data-toggle="pill"
                      data-target="#pills-half" type="button" role="tab" aria-controls="pills-half"
                      aria-selected="true">30 mins</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button style="cursor: pointer;" class="nav-link side-bttn mx-4" id="pills-full-tab" data-toggle="pill"
                      data-target="#pills-full" type="button" role="tab" aria-controls="pills-full"
                      aria-selected="false">60 mins</button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-half" role="tabpanel"
                    aria-labelledby="pills-half-tab">
                    <div class="row mt-5">
                      <div class="col-12">
                        <div class="row" *ngIf="halfHourSlotList.length <= 7">
                          <!-- <div class="col-10"> -->
                          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item mx-2 mb-3" role="presentation" *ngFor="let item of halfHourSlotList">
                              <button style="cursor: pointer;" class="nav-link  w-150 side-bttn"
                                (click)="filterSlotDateTIme(item.slot_start_date)" id="pills-home-tab"
                                data-toggle="pill" data-target="#pills-home" type="button" role="tab"
                                aria-controls="pills-home" aria-selected="true">

                                <div>{{item.slot_start_date}}</div>
                              </button>
                            </li>
                          </ul>
                          <!-- </div> -->
                        </div>

                        <!-- <div class="row" *ngIf="halfHourSlotList.length > 7">
                          <div class="col-1">
                            <button (click)="nextPrevAction('prev')">previous</button>
                          </div>
                          <div class="col-10">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" >
                              <li class="nav-item mx-2 mb-3" role="presentation" *ngFor="let item of prevNexthalfHourSlotList">
                                <button class="nav-link  w-150 side-bttn" (click)="filterSlotDateTIme(item.slot_start_date)"
                                  id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab"
                                  aria-controls="pills-home" aria-selected="true">

                                  <div>{{item.slot_start_date}}</div>
                                </button>
                              </li>
                              </ul>
                          </div>
                          <div class="col-1">
                            <button (click)="nextPrevAction('next')">next</button>
                          </div>

                        </div> -->


                        <div class="row" *ngIf="halfHourSlotList.length > 7">
                          <span class="marg-l-16">
                            <button (click)="nextPrevAction('prev')" class="slider_type">
                              <img src="assets/chevron-left.svg" alt="">
                            </button>
                          </span>
                          <span class="">
                            <ul class="nav nav-pills mb-3 marg-l-20" id="pills-tab" role="tablist">
                              <li class="nav-item mx-2 mb-3" role="presentation"
                                *ngFor="let item of prevNexthalfHourSlotList">
                                <button class="nav-link  w-150 side-bttn"
                                  (click)="filterSlotDateTIme(item.slot_start_date)" id="pills-home-tab"
                                  data-toggle="pill" data-target="#pills-home" type="button" role="tab"
                                  aria-controls="pills-home" aria-selected="true">

                                  <div>{{item.slot_start_date}}</div>
                                </button>
                              </li>
                            </ul>
                          </span>
                          <span class="col-1">
                            <button (click)="nextPrevAction('next')" class="slider_type">
                              <img src="assets/chevron-right.svg" alt="">
                            </button>
                          </span>

                        </div>



                        <div class="mt-4">
                          <label class="font_regular_gilroy heading-one"> Availability for * :</label>
                        </div>
                        <div class="tab-content mt-3" id="pills-tabContent">
                          <!-- active -->
                          <div class="tab-pane fade show " id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <div>
                              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item mx-2" role="presentation" *ngFor="let slot of halfHourSlotTimeList">
                                  <button style="cursor: pointer;" class="nav-link mb-3  side-bttn" id="pills-slot-tab" data-toggle="pill"
                                    data-target="#pills-slot" type="button" role="tab" aria-controls="pills-slot"
                                    aria-selected="true" (click)="slotSelection(slot)">{{slot.slot_start_time}}</button>
                                </li>
                              </ul>
                            </div>
                            <div *ngIf="halfHourSlotTimeList.length == 0" class="text-center f_s_24">
                              <h5>Slot not found</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-full" role="tabpanel" aria-labelledby="pills-full-tab">
                    <div class="row mt-5">
                      <div class="col-12">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                          <li class="nav-item marg-l-12" role="presentation" *ngFor="let item of oneHourSlotList">
                            <button  style="cursor: pointer;" (click)="filterSlotDateTImeOne(item.slot_start_date)"
                              class="nav-link w-150 side-bttn" id="pills-sun-tab" data-toggle="pill"
                              data-target="#pills-sun" type="button" role="tab" aria-controls="pills-sun"
                              aria-selected="false">
                              <div style="cursor: pointer;">{{item.slot_start_date}}</div>
                            </button>
                          </li>
                        </ul>
                        <div class="mt-4">
                          <label class="font_regular_gilroy heading-one"> Availability for * :</label>
                        </div>
                        <div class="tab-content mt-3" id="pills-tabContent">
                          <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <div>
                              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item mx-2" role="presentation" *ngFor="let slot of oneHourSlotTimeList">
                                  <button  style="cursor: pointer;" class="nav-link active side-bttn" id="pills-slot-tab" data-toggle="pill"
                                    data-target="#pills-slot" type="button" role="tab" aria-controls="pills-slot"
                                    aria-selected="true">{{slot.slot_start_time}}</button>
                                </li>
                              </ul>
                            </div>
                            <div *ngIf="oneHourSlotTimeList.length == 0" class="text-center f_s_24">
                              <h5>Slot not found</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 mb-3">
                  <button style="cursor: pointer;" class="creates-btn" (click)="createSlot()">Book Appointment</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
