<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/exercisesdatabank']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Exercise Databank View</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>
                    <!-- <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Exercise Image *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" />
                    </div> -->

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Exercise Type *</label>
                        <select class=" form-control input_bdr_clr"
                        [(ngModel)]="questionType"
                      [ngModelOptions]="{standalone: true}"
                        (change)="questionOne($event)">
                        <option value="image">Image</option>
                        <option value="video">Video</option>

                    </select>
                    </div>


                    <div class="form-group" *ngIf="showVideoDiv">
                        <label class="fsize16 clr_black font_bold_gilroy">Video url *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" maxlength="150" />
                    </div>

                    <div class="form-group" *ngIf="showImageDiv">
                        <label class="fsize16 clr_black font_bold_gilroy">Exercise Image *</label>
                        <input type="file" class="form-control input_bdr_clr font_text" maxlength="150" />
                    </div>



                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Benefits for exercis*</label>

                        <ckeditor name="myckeditor" debounce="500"
                        [(ngModel)]="exerciseBenefit"
                        [ngModelOptions]="{standalone: true}"
                        >
                        </ckeditor>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>