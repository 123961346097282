import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-assestmentaddresult',
  templateUrl: './assestmentaddresult.component.html',
  styleUrls: ['./assestmentaddresult.component.scss']
})
export class AssestmentaddresultComponent implements OnInit {
  isShown: boolean = false;
  container: any[] = [1];
  newArray: {
      no:string,
    description: string,
  }[] = [{no:"",description: "" }];
  keyValue: any;
  back_url = "/assestment_results";
  getAllEmotion = [];
  emotionsId:any;
  resultId:any;
  getResultAll:any;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.getEmotionListApi();
    this.getResultApi();
  }
  assestmentCancelBtn() {
    this.router.navigate([this.back_url]);
  }

  async addBenefits() {
    this.keyValue = this.container.length + 1;
    this.newArray.push({no:"",description: ""  });
    this.container.push(this.keyValue);
    var allTitle = this.newArray.map((item,index) => {
      if (item.description != undefined && item.description != '') {
        return {no: item.no,description: item.description }
      }
    })
  }

  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }
  }
async assestmentSubmit(){

  
  var allTitle = this.newArray.map((item,index) => {
    if (item.description != undefined && item.description != '') {
      return { description: '<div style = "overflow:scroll">' + item.description + '<div>' , no: item.no }
    }
  })
 var orderArr=false;
 for(var item of allTitle){
  if(!item){
    orderArr=true;
    break;
  }
  if(item.description == '' || item.no == ''){
    orderArr=true;
    break;
  }
 }

 console.log('orderArr',orderArr);
 
console.log(allTitle,'allTitle...')

  if(this.emotionsId && this.resultId && !orderArr ){
console.log('assesment submit1');

   var postapi = "/add_and_update_assessment_result";
  var exercisesAdd = {
    "emotion_id":  this.emotionsId,
    "result_id": this.resultId,
    "slides": allTitle
  };
  (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
    res => {
   

      if (res.status === 200) {
        swal({
          title: "Success!",
          text: "Assestment Added successfully",
          type: "success",
        }, function () {
          alert('yo');
        });
        this.router.navigate([this.back_url]);
      }

    },
    (error) => {
      // if (error.error.message.emotion_id != undefined || error.error.message.result_id != undefined) {
        var errMsg = '';
        if(typeof error.error.message ==='object'){
          for (var i in error.error.message) {
            errMsg += error.error.message[i] + '<br />';
          }
        }else{
          errMsg=error.error.message
        }
      

        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });

      // }
      // else {
      //   swal({
      //     title: "error!",
      //     text: error.error.message,
      //     type: "error",
      //   }, function () {
      //     alert('yo');
      //   });
      // }
    }
    
    
    )
  }

  else{

    swal({
      title: "error!",
      text: "Please Fill The All Details",
      type: "error",
    }, function () {
      alert('yo');
    });
    
  }

 }

 async getEmotionListApi() {
  var getapi = "/master/emotions";
  (await this.ApiService.getApi(getapi)).subscribe((res) => {
  
    this.getAllEmotion = res.data
  });
}

  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;

  }
 // --------------------

    resultItem(e: any) {
      this.resultId = e.target.value;
    }
  // ----------------Result List Api---------------------------

  async getResultApi() {
    var getapi = "/list_range";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getResultAll = res.result
    });
  }

 
}
