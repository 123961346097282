<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/rewardslist']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Edit Rewards</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form [formGroup]="registerForm">

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward Type</label>
                        <select (change)="rewarditem($event)" formControlName="CategoryName"
                            class=" form-control input_bdr_clr ">
                            <option [disabled]="true" [selected]="true">Select any type</option>
                            <option [value]="[item.category_name]" *ngFor="let item of getrewardlist">
                                {{item.category_name}} </option>
                            <input type="text" class="form-control input_bdr_clr font_text" maxlength="100">
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward Name *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" formControlName="RewardName"
                            maxlength="50">
                        <div *ngIf="submitted && registerForm.get('RewardName').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('RewardName').errors.required">Reward Name is
                                required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward Description *</label>
                        <input type="text" class="form-control input_bdr_clr font_text"
                            formControlName="RewardDescription" maxlength="100">
                        <div *ngIf="submitted && registerForm.get('RewardDescription').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('RewardDescription').errors.required">Reward Description is
                                required</div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="registerForm.controls['CategoryName'].value === 'Lifestyle'">
                        <label class="fsize16 clr_black font_bold_gilroy">External Link *</label>
                        <input type="url" class="form-control input_bdr_clr font_text" formControlName="ExternalLink"
                            maxlength="100">
                        <div
                            *ngIf="(submitted || registerForm.get('ExternalLink').touched) && registerForm.get('ExternalLink').errors">
                            <div class="alert alert-danger">
                                <span *ngIf="registerForm.get('ExternalLink').errors.required">
                                    External Link is required.
                                </span>
                                <span *ngIf="registerForm.get('ExternalLink').errors.invalidUrl">
                                    Please enter a valid URL.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Happiness points *</label>
                        <input type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            maxlength="6" class="form-control input_bdr_clr font_text"
                            formControlName="HappinessPoints">
                        <div *ngIf="submitted && registerForm.get('HappinessPoints').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('HappinessPoints').errors.required">Happiness Points is
                                required</div>
                        </div>
                    </div>

                    <div class="form-group"
                        *ngIf="registerForm.controls['CategoryName'].value === 'Lifestyle' || registerForm.controls['CategoryName'].value === 'Therapy Session'">
                        <label class="fsize16 clr_black font_bold_gilroy">Reedem Code</label>
                        <input type="text" oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '');"
                            class="form-control input_bdr_clr font_text" formControlName="RedeemCode" maxlength="10">
                        <!-- <div *ngIf="submitted && registerForm.get('RedeemCode').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('RedeemCode').errors.required">Reedem Code is required</div>
                        </div> -->
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Valid From Date *</label>
                        <input type="date" class="form-control input_bdr_clr font_text"
                            formControlName="startdateRequired" [min]="today" />
                        <div class="time-picker" style="margin-top: 10px;">

                            <select style="height: 30px; width: 50px;" formControlName="startTimeHour"
                                [(ngModel)]="selectedHour1" (change)="openTimePicker1()">
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;"
                                formControlName="startTimeMinutes" (change)="openTimePicker1()"
                                [(ngModel)]="selectedMinute1">
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select>

                            <select style="margin-left: 2px;height: 30px; width: 50px;"
                                formControlName="startTimePeriod" [(ngModel)]="selectedPeriod1"
                                (change)="period($event)">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>

                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Valid From Date *</label>
                        <input type="date" class="form-control input_bdr_clr font_text"
                            formControlName="enddateRequired" [min]="today" />
                        <div class="time-picker" style="margin-top: 10px;">

                            <select style="height: 30px; width: 50px;" formControlName="endTimeHour"
                                (change)="openTimePicker2()" [(ngModel)]="selectedHour2">
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;" formControlName="endTimeMinutes"
                                (change)="openTimePicker2()" [(ngModel)]="selectedMinute2">
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select>

                            <select style="margin-left: 2px;height: 30px; width: 50px;" formControlName="endTimePeriod"
                                [(ngModel)]="selectedPeriod2" (change)="period($event)">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>

                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Status</label>
                        <div>
                            <span>
                                <input type="radio" id="active" formControlName="status" name="status" value="1">
                                <label for="active" class="marg-l-8"> Active </label>
                            </span>

                            <span>
                                <input class="marg-l-8" type="radio" id="inactive" formControlName="status"
                                    name="status" value="0">
                                <label class="marg-l-8"> Inactive</label>
                            </span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward File</label>
                        <div>
                            <input type="file" class="file-upload" accept=".png, .jpg, .jpeg"
                                (change)="fileupload($event)" />
                            <img *ngIf="rewardImage" height="100px" width="100px" [src]="rewardImage"
                                alt="Reward Image">
                        </div>
                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" style="cursor: pointer;" class="btn  submit_btn_clr"
                            (click)="submitAddReward()">Save</button>
                        <button type="button" style="cursor: pointer;" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="cancelAddreward()">Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>