import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FormsModule } from '@angular/forms';

// import { HttpHeaders } from '@angular/common/http';

// import { log } from 'console';

@Component({
  selector: 'app-editsubscription',
  templateUrl: './editsubscription.component.html',
  styleUrls: ['./editsubscription.component.scss']
})
export class EditsubscriptionComponent implements OnInit {
  isShown: boolean = false;
  showInputField = false; // Initialize as hidden
  // showInputFieldOne = false;
  // showInputFieldTwo = false;
  // showInputFieldThree = false;
  // showInputFieldFour = false;
  // showInputFieldFive = false;
  // showInputFieldSix = false;
  // showInputFieldSeven = false;
  // showInputFieldEight = false;

  subscriptionForm: FormGroup;


  subscription_name: any;
  subscription_description: any;
  subscription_duration: any;
  getduration: any;
  pricing: any;
  status: any;
  modules: any[] = [];
  subscriptions: any[] = [];


  showToggle = true;
  isactive: boolean;

  toppings: any;
  paramMap: any;
  getSingleElement: any;
  subscriptionId: any;
  dataFetched: boolean;

  nameError = false;
  descriptionError = false;
  durationError = false;
  pricingError = false;
  selectedSubscriptionDuration: string;

  selectedOption: string;
  subscriptionModule: any[] = [];
  module_status: boolean = false;
  limit_type_error: boolean = false;
  atLeastOneChecked: boolean = false;


  // subscription = '/subscritionurl'




  constructor(private router: Router, private route: ActivatedRoute, private ApiService: ApiService, private formBuilder: FormBuilder) {

    this.subscriptionForm = this.formBuilder.group({
      subscription_name: ['', Validators.required],
      subscription_description: ['', Validators.required],
      subscription_duration: ['', Validators.required],
      pricing: ['', Validators.required],
    });


  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.subscriptionId = +params.get('subscriptionId'); // '+' converts to a number
      // Now you can use the subscriptionId in the EditsubscriptionComponent
    });
    this.getSingleList();
    this.selectedSubscriptionDuration = '';
    const subscription_duration = this.subscription_duration

    console.log('AAAAAAAAAA', subscription_duration)

  }

  ModuleSelect(event: any, module: any, ind: any) {
    // this.modules = this.modules.map((item, index) => {
    if (module.module_status = event.target.checked) {
      // return module.module_status = event.target.checked
      return module.module_status == 1
    } else {
      return module.module_status == 0
    }
    // })
  }


  onchange() {
    // this.showToggle = !this.showToggle;
    this.isactive = !this.isactive;

    console.log('1', this.showToggle)
    console.log('0', !this.showToggle)
  }

  ModuleLimitChange(event: any, module: any, ind: any) {
    // this.modules = this.modules.map((item, index) => {
    console.log("checked", event.checked, module)
    if (event.checked == true) {
      module.limit_type = "Days";
      return module.access_limit = 1
    } else {
      return module.access_limit = 0
    }
    // })
  }

  ModuleLimitCount(event: any, module: any) {
    // this.modules = this.modules.map((item, index) => {
    if (module) {
      return module.limit_count = Number(event.target.value)
    } else {
      return module
    }
    // })
  }


  ModuleType(event: any, module: any, ind: any) {
    // this.modules = this.modules.map((item, index) => {
    if (module) {
      return module.limit_type = event.target.value
    } else {
      return module
    }
    // })
    console.log(this.subscriptionModule)
  }


  // isToggleDisabled() {
  //   console.log(this.subscription_duration, '11111')
  //   // Your custom condition here
  //   if (this.subscription_duration == 'complementary') {
  //     return false; // Disabled
  //   } else {
  //     return true; // Not disabled
  //   }
  // }


  // async getSingleLIst(){
  //   var getapi = '/getSubscriptions/'+ this.subscriptionId;
  //   (await this.ApiService.getApi(getapi)).subscribe(
  //     res => {
  //       if(res.status == 200) {
  //         this.getSingleElement = res.result;
  //       }
  //     }
  //   )
  // }


  async getSingleList() {
    var getapi = '/getSubscriptions/' + this.subscriptionId;
    (await this.ApiService.getApi(getapi)).subscribe(
      res => {
        if (res.status === 200) {
          this.getSingleElement = res.result;
          // Bind the fetched data to form fields

          console.log('data fetched', res)

          this.subscription_name = this.getSingleElement.subscription_name;
          this.subscription_description = this.getSingleElement.subscription_description;
          this.subscription_duration = this.getSingleElement.subscription_duration;
          this.pricing = this.getSingleElement.pricing;
          this.isactive = this.getSingleElement.isactive === 1;


          console.log('cuteeeee', this.isactive)

          this.showToggle = this.isactive;
          this.dataFetched = true;


          this.modules = this.getSingleElement.modules.map(module => {
            return {
              ...module,
              access_limit: module.access_limit, // Convert to boolean
              limit_count: module.limit_count || null,
              module_status: module.module_status,
              limit_type: module.limit_type,
            };
          });

          console.log('Sucesssssssss', this.modules)

          this.dataFetched = true;  // Set the flag to true when data is fetched

          console.log('Fetched data:', this.getSingleElement);
        }
      }
    );
  }


  async updateData() {


    if (!this.subscription_name) {
      this.nameError = true;
    } else {
      this.nameError = false;
    }

    if (!this.subscription_description) {
      this.descriptionError = true;
    } else {
      this.descriptionError = false;
    }

    if (!this.subscription_duration) {
      this.durationError = true;
    } else {
      this.durationError = false;
    }

    if (!this.pricing) {
      this.pricingError = true;
    } else {
      this.pricingError = false;
    }

    const atLeastOneChecked = this.modules.some(item => item.module_status == 1);
    const errorMessage = document.getElementById('error-message');

    if (atLeastOneChecked == false) {
      // return true;
      errorMessage.style.display = 'block';

    } else {
      errorMessage.style.display = 'none';



      const module = this.modules.map(module => {
        return {
          ...module,
          limit_type: module.limit_type,
          limit_count: module.limit_count,
          // access_limit: module.access_limit ? 1 : 0,   
          access_limit: module.access_limit === 1 ? 1 : 0,

          module_status: module.module_status ? 1 : 0,

        };
      })

      console.log('xxxxxx', module);

      const requestBody = {
        // subscriptionId: this.subscriptionId,
        "subscription_name": this.subscription_name,
        "subscription_description": this.subscription_description,
        "subscription_duration": this.subscription_duration,
        "pricing": this.pricing,
        // "isactive": this.showToggle,/
        "isactive": this.isactive,
        "modules": module
      };

      console.log('Module Success', this.showToggle)

      // Perform the API call to update the subscription
      const postApi = '/addAndUpdateSubscriptionPlan/' + this.subscriptionId;

      (await this.ApiService.postApi(postApi, requestBody)).subscribe(
        res => {
          if (res.status === 200) {
            console.log('Subscription updated successfully', res);
            this.router.navigate(['/subscription']);
          }
        },
        error => {
          console.error('Error updating subscription:', error);
        }
      );

    }

  }
  cancel_submission() {
    this.router.navigate(['/subscription']);
  }

}
