import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  isPhonepeActive: boolean = false;
  isRazorpayActive: boolean = false;
  getPaymentTypeList:any;
  // isSaveButtonEnabled: boolean = false; //-----


  constructor(private ApiService: ApiService) { 
    // this.updateSaveButtonState();

  }


  // payment_mode() {
  //   // Update the state of the payment options
  //   this.updateSaveButtonState();
  // }

  // updateSaveButtonState() {
  //   // Check if at least one payment option is active
  //   this.isSaveButtonEnabled = this.isRazorpayActive || this.isPhonepeActive;
  // }


  ngOnInit(): void {
    this.getPaymentList();
  }

  async payment_mode(){
    var payment = '/updatePaymentSettings';
    var payment_type = {
      "Phonepe": this.isPhonepeActive ? "1" : "0",
      "Razorpay": this.isRazorpayActive ? "1" : " 0"

    };
    (await this.ApiService.postApi(payment,payment_type)).subscribe(
      res => {
        if(res.status == 200) {

        }

      }
    )
  }


 

  async getPaymentList(){
    var getapi = '/getPaymentSettings';
    (await this.ApiService.getApi(getapi)).subscribe(
      res => {
        if(res.status == 200) {
          this.getPaymentTypeList = res.data;
          console.log('this.getPaymentTypeList',this.getPaymentTypeList);


          res.data.forEach(item => {
            if (item.module_item == 'Razorpay') {
              this.isRazorpayActive = item.status == 1;
            } else if (item.module_item == 'Phonepe') {
              this.isPhonepeActive = item.status == 1;
            }
          });


        }

      }
    )
  }

}
