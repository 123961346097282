<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Work Out Management
    </div>

    <div class="marg-t-30">
        <div class="bdr_clr_table">

            <table class="w-100">
                <tr class="bgr_th font_bold_gilroy">
                    <th>S.No</th>
                    <th>Mood</th>
                    <th>Level</th>
                    <th>Question</th>
                    <th>Action</th>
                    <!-- <th>User Name</th>
                    <th>No.of.trainees</th>
                    <th>Action</th> -->
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>1</td>
                    <td>Happy</td>
                    <td>First</td>
                    <td>Question</td>
               <td><span (click)="edit_table()"  ><img height="26px" width="26px" class="bgr_black" src="assets/Edit_icon.png" alt=""></span> <span class="marg-l-20"><img height="26px" width="26px" class="bgr_black" src="assets/Delete_icon.png" alt=""></span>  </td> 
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>2</td>
                    <td>Happy</td>
                    <td>First</td>
                    <td>Question</td>
                   <td><span ><img height="26px" width="26px" class="bgr_black" src="assets/Edit_icon.png" alt=""></span> <span class="marg-l-20"><img height="26px" width="26px" class="bgr_black" src="assets/Delete_icon.png" alt=""></span>  </td> 
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>3</td>
                    <td>Happy</td>
                    <td>First</td>
                    <td>Question</td>
                   <td><span><img height="26px" width="26px" class="bgr_black" src="assets/Edit_icon.png" alt=""></span> <span class="marg-l-20"><img height="26px" width="26px" class="bgr_black" src="assets/Delete_icon.png" alt=""></span>  </td> 
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>4</td>
                    <td>Happy</td>
                    <td>Second</td>
                    <td>Question</td>
                   <td><span><img height="26px" width="26px" class="bgr_black" src="assets/Edit_icon.png" alt=""></span> <span class="marg-l-20"><img height="26px" width="26px" class="bgr_black" src="assets/Delete_icon.png" alt=""></span>  </td> 
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>5</td>
                    <td>Happy</td>
                    <td>Second</td>
                    <td>Question</td>
                   <td><span><img height="26px" width="26px" class="bgr_black" src="assets/Edit_icon.png" alt=""></span> <span class="marg-l-20"><img height="26px" width="26px" class="bgr_black" src="assets/Delete_icon.png" alt=""></span>  </td> 
                </tr>

            </table>

        </div>

    </div>

</div>