import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
declare var swal: any;

@Component({
  selector: 'app-happiness-edit-test-result',
  templateUrl: './happiness-edit-test-result.component.html',
  styleUrls: ['./happiness-edit-test-result.component.scss']
})
export class HappinessEditTestResultComponent implements OnInit {

  isShown: boolean = false;
  container: {
    description: string,
    id: string,
  }[] = [{ id: "", description: "" }];
  keyValue: any;
  gethappinessresultcategory: any;
  rangeid: any;
  range_ID: any;
  happiness_test_list: any;
  happinessResultEditId: any;
  happinessresultDeleteId: any;
  happinessresultBack_url = "/happiness-test-result"
  selectedDeleteIndex: number;


  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.happinessResultEditId = this.ApiService.happinessresultEditId;
    this.happinessResultEditId = localStorage.getItem("range_id")
    this.gethappiness_resultListApi();
    this.getlist_happinessresultList();
    console.log("sssssssssssss", this.range_ID)
    console.log("Range_id", this.happinessResultEditId)
  }


  // ======================================================================== //

  async gethappiness_resultListApi() {
    var getapi = "/listHappinesstestrange";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.gethappinessresultcategory = res.result
      console.log("asasasa", this.gethappinessresultcategory)
    });
  }

  // ========================================================================= //


  async happinessUpdateSubmit() {
    console.log('this.container');

    this.container = this.container.map((item) => {
      console.log(item.id);

      var data = { ...item };
      if (item.id === "") {
        delete data.id;
      }
      return data;
    })

    // const data_remain = this.container.filter((data) => { return !data.hasOwnProperty('delete') })

    // if (data_remain.length < 6) {
    //   swal({
    //     title: 'Error!',
    //     text: 'Six data are mandatory',
    //     type: 'error'
    //   });
    //   return;
    // }

    var deletedata = this.container.every(item => "delete" in item)
    console.log("deleted_data", deletedata)

    if (deletedata) {
      swal({
        title: 'Error !',
        text: 'Any one of the data is mandatory',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return
    }

    const hasEmptyQuestion = this.container.some(item => !item.id && !item.description.trim());

    if (hasEmptyQuestion) {
      swal({
        title: 'Error!',
        text: 'Newly added rows must have a question',
        type: 'error'
      });
      return;
    }


    console.log('this.container', this.container);


    var putApi = "/addAndUpdateHapinessTestResult/" + this.happinessResultEditId;
    var exerciseUpdatedata = {
      "range_id": this.range_ID,
      "content": this.container
    };
    console.log(exerciseUpdatedata);
    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Result Updated Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.happinessresultBack_url]);
          }
          else if (res.status === 401) {

          }
        }
      });
  }


  // ====================================================================== //


  happinessitem(e: any) {
    this.range_ID = e.target.value;
    console.log("sd", this.range_ID)

  }


  // ======================================================================= //


  async addBenefits() {

    const data_remain = this.container.filter((data) => { return !data.hasOwnProperty('delete') })


      this.container.push({ id: "", description: "" });
      this.container.map((item) => {
        return { description: item.description }
      })
    
      
    // else {
    //   swal({
    //     title: 'Error !',
    //     text: 'Only Six datas for per category',
    //     icon: 'error',
    //     confirmButtonText: 'OK'
    //   });
    // }

    // this.container.push({ id: "", description: "" });
    // this.container.map((item) => {
    //   return { question: item.description }
    // })
  }


  // ========================================================================= //


  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }
  }


  // ========================================================================== //


  async getlist_happinessresultList() {
    var getapi = "/listHappinesstestresult/" + this.happinessResultEditId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.container = res.result.content;
        this.rangeid = res.result.range_id;
        console.log("ddddddddddddd", this.rangeid)
        console.log("data", this.container)
      }
      this.range_ID = this.rangeid
      console.log(this.range_ID)
    });
  }


  // =========================================================================== //


  happinessEditDelete(item: any,index:number) {
    this.happinessresultDeleteId = item.id
    if (!item.id) {
      this.selectedDeleteIndex = index
    }
    console.log("Id for delete", this.happinessresultDeleteId)
  }

  // =========================================================================== //

  delete_submit() {
    // this.container = this.container.map((item) => {
    //   if (item.id == this.happinessresultDeleteId) {
    //     return { ...item, delete: this.happinessresultDeleteId };
    //   }

    //   else {
    //     return item;
    //   }
    // })

    if (this.selectedDeleteIndex !== null && this.selectedDeleteIndex >= 0 && this.selectedDeleteIndex < this.container.length) {
      this.container.splice(this.selectedDeleteIndex, 1);
    }
    
    this.container = this.container.map(item => {
      if (item.id === this.happinessresultDeleteId) {
        return { ...item, delete: this.happinessresultDeleteId };
      }
      return item;
    });
    console.log("The container value ", this.container)
  }

  // =========================================================================== //

  happinessBackUrl() {
    this.router.navigate([this.happinessresultBack_url]);
  }

}



