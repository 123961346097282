<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/nutrition_Databank']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Nutrition Recommendation Add</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <!-- <div style="float: right;">
                    <span style="margin-bottom: 30px;"> <button data-toggle="modal" data-target="#addExerciseModel"
                            class="submit_btn_clr">Add Question</button></span>
                </div> -->


                <div class="">
                    <form>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Emotion *</label>

                            <select (change)="emotionItem($event)" class=" form-control input_bdr_clr">
                                <option>Select an Emotion</option>
                                <option [value]="[item.themotion_id]" *ngFor="let item of getAllEmotion">
                                    {{item.themotion_name}} </option>
                            </select>
                        </div>

                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Result *</label>
                            <select (change)="resultItem($event)" class=" form-control input_bdr_clr">
                                <option>Select an Result</option>
                                <option [value]="[item.id]" *ngFor="let item of getResultAll">{{item.result}}
                                </option>

                            </select>

                        </div>

                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Category *</label>
                            <select (change)="categoryItem($event)" class=" form-control input_bdr_clr">
                                <option [disabled]="true" [selected]="true">Select an Category</option>
                                <option [value]="[item.id]" *ngFor="let item of categoryAllList">
                                    {{item.category}} </option>
                            </select>
                        </div>


                        <!-- 
                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Diet Type *</label>
                            <div class="row m-0">
                                <div>
                                    <input [(ngModel)]="dietTypes" [ngModelOptions]="{standalone: true}" type="radio"
                                        id="html" name="dietTypes" value="veg">
                                    <label class="marg-l-8">Veg</label>
                                </div>

                                <div class="marg-l-8">
                                    <input [(ngModel)]="dietTypes" [ngModelOptions]="{standalone: true}" type="radio"
                                        id="css" name="dietTypes" value="nonveg">
                                    <label class="marg-l-8">Non-Veg</label>
                                </div>
                            </div>

                        </div> -->

                        <div>
                            <div *ngFor="let ele of container; let i = index" style="margin-top:50px;">

                                <div class="form-group ">
                                    <label class="fsize16 clr_black font_bold_gilroy">Diet Type *</label>
                                    <div class="row m-0">
                                        <div>
                                            <input [(ngModel)]="newArray[i].diet_type" [ngModelOptions]="{standalone: true}"
                                                type="radio" id="html" name="dietTypes_{{i}}" value="veg">
                                            <label class="marg-l-8">Veg</label>
                                        </div>

                                        <div class="marg-l-8">
                                            <input [(ngModel)]="newArray[i].diet_type" [ngModelOptions]="{standalone: true}"
                                                type="radio" id="css" name="dietTypes_{{i}}" value="nonveg">
                                            <label class="marg-l-8">Non-Veg</label>
                                        </div>
                                    </div>

                                </div>


                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Display Order*</label>
                                    <input type="text" placeholder="Please Enter Title"
                                        class="form-control input_bdr_clr" [(ngModel)]="newArray[i].no"
                                        [ngModelOptions]="{standalone: true}" />

                                </div>



                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Title*</label>
                                    <input type="text" placeholder="Please Enter Title" id="questions"
                                        class="form-control input_bdr_clr " [(ngModel)]="newArray[i].title"
                                        [ngModelOptions]="{standalone: true}" />
                                </div>

                                <div class="form-group marg-t-20">
                                    <div>
                                        <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                                    </div>
                                    <ckeditor name="myckeditor" [(ngModel)]="newArray[i].description"
                                        [ngModelOptions]="{standalone: true}" debounce="500">
                                    </ckeditor>
                                </div>


                            </div>

                            <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                                <div class="form-group">
                                    <button style="margin-right:20px;" class="btn btn-primary"
                                        (click)="addBenefits()">Add</button>

                                    <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                        class="btn btn-warning">remove</button>
                                </div>
                            </div>
                        </div>



                        <div class="form-group" style="margin-top:100px;">
                            <button type="submit" class="btn  submit_btn_clr"
                                (click)="submitAddNutrition()">Submit</button>
                            <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                                (click)="nutritionBackBtn()">Cancel</button>
                        </div>
                    </form>
                </div>


                <div>



                </div>


            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>





    </div>

</div>