import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;
declare var $: any;
declare var window: any;
@Component({
  selector: 'app-nutrition-databank-edit',
  templateUrl: './nutrition-databank-edit.component.html',
  styleUrls: ['./nutrition-databank-edit.component.scss']
})
export class NutritionDatabankEditComponent implements OnInit {
  isShown: boolean = false;
  nutritionBack_url = "/nutrition_Databank";
  getAllEmotion: any;
  nutrition_id: any;
  emotionsId = "";
  getResultAll: any;
  resultId: any;
  nutritionList: any;
  container: any[] = [1];
  newArray: {
    no: string,
    title: string,
    description: string,
    diet_type: string
  }[] = [{ no: "", title: "", description: "", diet_type: "" }];
  keyValue: any;
  nutritionTitle: any;
  nutritionDescription: any;
  nutritionEditIds: any;
  delete_Id: any;
  titleAdd: any;
  descriptionAdd: any;
  order_no: any;
  diet_type: any;
  orderNoAdd: any;
  dietTypes: any;
  category_ids: any;
  categoryAllList: any;
  category_NewId: any;
  registerForm: FormGroup;
  submitted = false;
  editRegForm: FormGroup;
  editSubmitted = false;
  formModal: any;
  formModalEdit: any;

  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.nutrition_id = this.ApiService.nutritionEditId;
    this.nutrition_id = localStorage.getItem("nutritionId");
    this.getEmotionListApi();
    this.getResultApi();
    this.getlist_nutrition();
    this.categoryListApi();

    this.registerForm = this.fb.group(
      {
        orderno: ["", Validators.required],
        title: ["", Validators.required],
        description: ["", Validators.required],
        diettype: ["", Validators.required],

      },
    );

    // --------------------------------------------------------------

    this.editRegForm = this.fb.group(
      {
        editorderno: ["", Validators.required],
        edittitle: ["", Validators.required],
        editdescription: ["", Validators.required],
        dietTypes: ["", Validators.required],

      },
    );

    this.formModal = new window.bootstrap.Modal(
      document.getElementById('exampleModalAdd')
    );

    this.formModalEdit = new window.bootstrap.Modal(

      document.getElementById('exampleModal')

    );


  }

  nutritionBackBtn() {
    this.router.navigate([this.nutritionBack_url]);
  }

  // ----------------Emotion List Api---------------------------

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data
    });
  }
  // --------------------
  // ----------------Result List Api---------------------------

  async getResultApi() {
    var getapi = "/list_range";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getResultAll = res.result
    });
  }
  // --------------------

  resultItem(e: any) {
    this.resultId = e.target.value;

  }


  emotionItem(e: any) {

    this.emotionsId = e.target.value;

  }
  // ---------------------------------------

  async addBenefits() {
    this.keyValue = this.container.length + 1;
    this.newArray.push({ no: "", title: "", description: "", diet_type: "" });
    this.container.push(this.keyValue);
    var allTitle = this.newArray.map((item) => {
      if (item.title != undefined && item.title != '') {
        return { title: item.title, description: item.description, diet_type: item.diet_type }
      }
    })

  }
  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }
  }

  async getlist_nutrition() {
    var getapi = "/list_nutrition/" + this.nutrition_id;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.nutritionList = res.result;
        this.emotionsId = res.result.emotion;
        this.resultId = res.result.result;
        this.category_ids = res.result.category_id;
        this.dietTypes = res.result.diet_type;



        // this.exercise_benefit = res.result.exercise_benefits;
      }
    });

  }

  async nutritionUpdate() {

    // this.nutritionList.description = this.nutritionList.description.map((item, index) => {
    //   if (item.id == this.nutritionEditIds) {
    //     item.title = this.nutritionTitle;
    //     item.description = this.nutritionDescription;
    //     item.no = this.order_no;
    //    }
    //   else {
    //     return item
    //   }
    // })



    var putApi = "/add_and_update_nutrition/" + this.nutrition_id;
    var exerciseUpdatedata = {
      "emotion_id": this.emotionsId,
      "result_id": this.resultId,
      "slides": this.nutritionList.description,
      "category_id": this.category_ids,
      // "diet_type":this.dietTypes,
    };

    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Nutrition Updated Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.nutritionBack_url]);
          }
          else if (res.status === 401) {

          }
        }
      });

  }


  edit_update(item: any) {

    this.formModalEdit.show();
    this.nutritionEditIds = item.id;
    this.nutritionTitle = item.title;
    this.order_no = item.no;
    this.nutritionDescription = item.description;
    this.diet_type = item.diet_type;

  }
  submitUpdate() {

    this.editSubmitted = true;

    if (this.editRegForm.invalid) {
      return;
    }

  this.nutritionList.description.forEach((item, index) => {
      if (item.id == this.nutritionEditIds) {
        item.title = this.nutritionTitle;
        item.description = this.nutritionDescription;

        item.no = this.order_no;
        item.diet_type = this.diet_type
      }
    })

    $("#exampleModal .close").click()
    this.formModalEdit.hide();
  }

  nutritionEditDelete(item: any) {
    this.delete_Id = item.id;


  }

  add_submit() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }




    if (this.order_no != undefined && this.nutritionTitle != undefined && this.nutritionDescription != undefined && this.diet_type != undefined) {
      console.log('add submit');
      this.nutritionList.description.push({ no: this.order_no, title: this.nutritionTitle, description: this.nutritionDescription, diet_type: this.diet_type })
      console.log(this.nutritionList.description);


      $("#exampleModalAdd .close").click()
      this.formModal.hide();

      swal({
        title: "success!",
        text: "Nutrition Added Successfully ",
        type: "success"
      }, function () {
        alert('yo');
      });


    }
    else {

      swal({
        title: "error!",
        text: "Please fill all the details ",
        type: "error"
      }, function () {
        alert('yo');
      });

    }



  }


  delete_submit() {
    this.nutritionList.description = this.nutritionList.description.map((item, index) => {
      if (item.id == this.delete_Id) {
        return { ...item, delete: this.delete_Id };
      }
      else {
        return item;
      }
    })

  }

  categoryItem(e: any) {
    this.category_ids = e.target.value;
    console.log(this.category_ids, 'this.category_ids');
  }

  async categoryListApi() {
    var getapi = "/category_list/2";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.categoryAllList = res.result;
      }
    });
  }

  // -----------------------------------

  addPopupShow() {
    this.formModal.show();
  }

}
