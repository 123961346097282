import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Moment } from 'Moment';
import * as moment from 'moment';
declare var swal: any;
@Component({
  selector: 'app-coupon-edit',
  templateUrl: './coupon-edit.component.html',
  styleUrls: ['./coupon-edit.component.scss']
})
export class CouponEditComponent implements OnInit {
  isShown: boolean = false;
  couponTitle: any;
  couponDescription: any;
  couponCode: any;
  coupenValue: any;
  coupenType: any;
  coupenExpiry: any;
  coupen_Id: any;
  couponUsers:any;
  getCouponData = [];
  couponAlldata = [];
  registerForm: FormGroup;
  submitted = false;
  cancelEdit_url = "/coupon-Table"
  constructor(private ApiService: ApiService, private router: Router,private fb: FormBuilder ) { }

  ngOnInit() {
    this.coupen_Id = this.ApiService.couponEditId;
    this.coupen_Id = localStorage.getItem("couponEditIds");
    // 

    this.registerForm = this.fb.group(
      {
        couponTitle: ["", Validators.required],
        coupon_Descrip: ["", Validators.required],
        couponPromocode: ["", Validators.required],
        couponValues: ["", Validators.required],
        couponexpiry: ["", Validators.required],
        // 
     
      },
    );


    this.getViewCouponApi();
  }
  cancelEditCoupon() {
    this.router.navigate([this.cancelEdit_url]);
  }

  async submitEditCoupon() {
    this.submitted = true;

 if (this.registerForm.invalid) {
      return;
    }
    var postapi = "/edit_coupon";
    var couponEditData = {
      "id": this.coupen_Id,
      "title": this.couponTitle,
      "description": this.couponDescription,
      "code": this.couponCode,
      "value": this.coupenValue,
      "type": this.coupenType,
      "expiry": this.coupenExpiry
    };
    (await this.ApiService.postApi(postapi, couponEditData)).subscribe(
      res => {
        if (res.status === 200) {

          swal({
            title: "Success!",
            text: "Coupon updated successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.cancelEdit_url]);
        }

      });
  }

  async getViewCouponApi() {

    var postapi = "/view_coupon";
    var couponEditId = {
      "id": this.coupen_Id
    };
    (await this.ApiService.postApi(postapi, couponEditId)).subscribe(
      res => {
        this.getCouponData = res.data;
        for (let item of this.getCouponData) {
          this.couponTitle = item.title;
          this.couponDescription = item.description;
          this.couponCode = item.code;
          this.coupenValue = item.value;
          this.coupenType = item.type;
          var coupenExpiryDate = item.expiry;
          this.coupenExpiry = coupenExpiryDate.slice(0, 10);

          var goalsDate = moment(coupenExpiryDate).format('DD-MM-YYYY');

          this.couponAlldata.push({ ...item, expiry: goalsDate });
      
        }

      });

  }

}
