import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import * as XLSX from 'xlsx';
// import * as $ from 'jquery';
declare const $: any;
declare var swal: any;
@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit {
  datas: any;
  constructor(private router: Router, private ApiService: ApiService) { }
  userEdit_url = '/user-edit-table';
  availabiltyView_url = '/user-view-page';
  deleteUserId: any;
  public table: any;
  dtOptions: DataTables.Settings = {
    paging: false,
    info: false,
    searching: false
  };
  datatable: any;
  showLoaderDiv = false;
  loading: boolean = false;
  currentPage: number = 1;
  entriesPerPage: number = 50;
  searchValue: string = '';
  totalPages: number = 0;
  totalEntries: number = 0;
  ngOnInit() {
    // this.datas = [];
    // console.log(this.searchValue)
    this.getUserListApi();
  }
  async getUserListApi() {
    // this.showLoaderDiv = true;
    this.loading = true;
    const page = this.currentPage;
    const entries = this.entriesPerPage;
    // console.log(this.searchValue, "search")
    var getapi = `/getusers?page=${page}&limit=${entries}&search=${this.searchValue}`;
    var table;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.datas = res.result;
      // this.showLoaderDiv = false;
      this.totalPages = res.pageCount;
      this.totalEntries = res.totalCount;

      this.loading = false;

      // $(function () {
      //   table = $("#myTable").DataTable();
      // })
      // $("#myTable").DataTable({
      //   language: {
      //    emptyTable: "No data available in table0000", //
      //    loadingRecords: "Please wait .. ",
      //    zeroRecords: "No matching records found"
      //   }
      // })
    },
      (error) => {
        console.error('Error fetching appointment list', error);
        this.loading = false;
      }
    );
  }


  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getUserListApi();
    }
  }
  onEntriesPerPageChange() {
    this.currentPage = 1;
    this.getUserListApi();
  }
  searchTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue
    this.getUserListApi()

  }
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.getUserListApi();
    }
  }
  edit_user(editItem: any) {
    this.ApiService.adminUser_id = editItem.thusr_id;
    localStorage.setItem("userEdit_Ids", editItem.thusr_id)
    this.router.navigate([this.userEdit_url]);
    // localStorage.setItem('userEditId', JSON.stringify(editItem.thusr_id));
  }
  edit_view(editItem: any) {

    this.router.navigate([this.availabiltyView_url]);
    localStorage.setItem('userviewId', JSON.stringify(editItem.thusr_id));

  }
  delete_User(deleteItems: any) {
    this.deleteUserId = deleteItems.thusr_id;
    console.log(this.deleteUserId, 'tttt')
  }
  async deleteUserApi() {
    var postapi = "/admin_delete_user";
    var adminUserdata = {
      "id": this.deleteUserId
    };
    (await this.ApiService.postApi(postapi, adminUserdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              icon: 'Success!',
              text: "User Deleted Succcessfully",
              type: "success"
            }, function () {
              alert('yo');
            });
            window.location.reload();
            // this.getUserListApi();
          }
        }
      });
  }

  //  ---------------------------------------------



  exportToExcel(): void {
    console.log(this.datas);
    const downloadArr = this.datas.map(
      (item: any, index: any) => {
        return {
          'User Name': item.thusr_name,
          'User Mobile': item.thusr_mobile,
          'User Email': item.thusr_email,
          'Registered Date': item.registered_date,
          // 'Reschedule DateTime': item.reschedule_datetime,
          'DOB': item.thusr_dob,
          'Location': item.thusr_location,

        };
      }
    );
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(downloadArr);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'User_List');
    // XLSX.writeFile(wb, 'Thap_Appointment_List.xlsx');

    // Generate a timestamp
    const timestamp = new Date()
      .toISOString()
      .slice(0, 19)
      .replace(/[:T]/g, '-');

    // Append the timestamp to the filename
    const filename = `Thap_User_List-${timestamp}.xlsx`;

    // Save the file with the timestamped filename
    XLSX.writeFile(wb, filename);
  }





  // -----------------download--------------------------------------

  tableToCSV() {
    console.log('raa');

    var csv_data = [];

    var rows = this.datas;
    document.getElementById('myTable');
    var d = document.getElementById('myTable');


    for (var i = 0; i < rows.length; i++) {


      var cols = ["thusr_name", "thusr_email", "thusr_mobile"]

      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {

        csvrow.push(rows[i][cols[j]]);
      }
      csv_data.push(csvrow.join(","));
    }
    var csv_da: string = csv_data.join('\n');
    this.downloadCSVFile(csv_da);

  }


  downloadCSVFile(csv_data) {
    var CSVFile: any;
    CSVFile = new Blob([csv_data], {
      type: "text/csv"
    });
    console.log(CSVFile, 'cdsfile')
    var temp_link = document.createElement('a');
    temp_link.download = "User_Thap_Timestamp.XLS";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
  }

}
