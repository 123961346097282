import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-master-view',
  templateUrl: './blog-master-view.component.html',
  styleUrls: ['./blog-master-view.component.scss']
})
export class BlogMasterViewComponent implements OnInit {
  isShown: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
