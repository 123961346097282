import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-coach-view-page',
  templateUrl: './coach-view-page.component.html',
  styleUrls: ['./coach-view-page.component.scss']
})
export class CoachViewPageComponent implements OnInit {
  coachViewName = "" ;
  coach_ViewLicenceNo = "" ;
  coach_ViewareaOfExpertise = "";
  coach_View_typeOfTherapy = "";
  coach_View_Experience = "";
  coach_ViewAccredting_Body  = "";
  constructor(private router: Router, private ApiService: ApiService) { }

  async ngOnInit() {
    var idCoach_View = JSON.parse(localStorage.getItem('coach_view'));
   
    var getapi = "/getcoaches";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var coach_AllViewData = res.result;
      for (var i = coach_AllViewData.length; i--;) {
        var item = coach_AllViewData[i];
        var ids = item.thcoach_id
        if (ids == idCoach_View) {
          this.coachViewName = item.thusr_name;
           this.coach_ViewLicenceNo = item.thcoach_license ;
           this.coach_ViewareaOfExpertise = item.thcoach_area_expertise ;
           this.coach_View_typeOfTherapy = item.thcoach_types_of_therapy;
           this.coach_View_Experience = item.thcoach_experience;
           this.coach_ViewAccredting_Body = item.thcoach_accredting_body;
        }
      }

    });
  }

}
